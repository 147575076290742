import React, { FC } from 'react';
import { useLazyLoad } from '../../../util/useLazyLoad';
import { Wrapper, VideoContainer, Caption } from './VideoScriptBlock.styles';

export interface VideoScriptProps {
  url: string;
  caption?: string;
}

const VideoScript: FC<VideoScriptProps> = ({ caption, url }) => {
  const { ref, readyToLoad } = useLazyLoad();

  return (
    <Wrapper>
      <VideoContainer ref={ref}>
        {readyToLoad && (
          <span
            dangerouslySetInnerHTML={{
              __html: `<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
            }}
          />
        )}
      </VideoContainer>
      {caption && <Caption>{caption}</Caption>}
    </Wrapper>
  );
};

export default VideoScript;
