import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { typeScale } from '../../../styles/newTypography';

export const Container = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.space56};

  @media ${breakpointUp.tablet} {
    column-gap: ${spacing.space72};
    justify-content: center;
    flex-direction: row;
  }

  @media ${breakpointUp.desktop} {
    justify-content: space-between;
    grid-column: 2 / -2;
    column-gap: 0;
  }
`;

export const Stat = styled.div`
  text-align: center;

  @media ${breakpointUp.desktop} {
    text-align: left;
  }
`;

export const Value = styled.div`
  ${typeScale.mobile.body.sixxl};
  padding-bottom: ${spacing.space4};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.sixxl};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.sixxl};
  }
`;

export const Name = styled.div`
  ${typeScale.mobile.body.xs};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xs};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xs};
  }
`;
