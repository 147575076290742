import React, { FC, ReactNode } from 'react';
import SectionWrapper, { Colors } from '../../components/SectionWrapper';
import {
  BlockContentOne,
  BlockContentTwo,
  CTACol,
  Heading,
} from './TextModule.styles';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';

export interface SharedProps {
  tagline: string;
  title: string;
  titleLayout: 'standard' | 'offset' | '50-50';
  layout: 'standard' | 'offset' | '50-50';
}

export type TextModuleProps = SharedProps &
  Colors<string> & {
    blockContent1: ReactNode;
    blockContent2: ReactNode;
    blockContent3: ReactNode;
  };

const TextModule: FC<TextModuleProps> = ({
  tagline,
  title,
  layout,
  titleLayout,
  blockContent1,
  blockContent2,
  blockContent3,
  ...colors
}) => {
  return (
    <SectionWrapper {...colors}>
      <Layout variant="smallWidth">
        <Grid>
          <Heading layout={titleLayout}>
            <dl>
              <dt>{tagline}</dt>
              <dd>{title}</dd>
            </dl>
          </Heading>
          <BlockContentOne layout={layout}>{blockContent1}</BlockContentOne>
          <BlockContentTwo layout={layout}>{blockContent2}</BlockContentTwo>
          <CTACol>{blockContent3}</CTACol>
        </Grid>
      </Layout>
    </SectionWrapper>
  );
};

export default TextModule;
