import styled, { css } from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import Blockquote from '../../../old/components/style-components/Blockquote';
import SectionWrapper, { Colors } from '../../components/SectionWrapper';
import { Grid } from '../../layout/Grid';

export const StyledSectionWrapper = styled(SectionWrapper)<Colors<string>>`
  padding: 4rem 0;

  @media ${breakpointUp.sm} {
    padding: 5rem 0;
  }

  @media ${breakpointUp.lg} {
    padding: 10rem 0;
  }
`;

export const StyledGrid = styled(Grid)``;

export const StyledBlockquote = styled(Blockquote)<
  {
    layout: 'center' | 'offset-right';
    statement: boolean;
  } & Colors<string>
>`
  grid-column: 1 / -1;
  letter-spacing: -0.03rem;
  ${({ layout, statement }) => css`
    ${layout === 'offset-right' &&
    css`
      text-align: left;

      @media ${breakpointUp.lg} {
        grid-column: 6 / span 6;
      }
    `}

    ${statement &&
    css`
      p::before,
      p::after {
        display: none;
      }
    `}

    @media ${breakpointUp.sm} {
      ${layout !== 'offset-right' &&
      css`
        grid-column: 2 / span 10;
      `}

      letter-spacing: -0.06rem;
    }

    @media ${breakpointUp.md} {
      padding: 7.5rem 0;
    }
  `}
`;

export const CiteName = styled.span`
  color: var(--fg1, ${({ theme }) => theme.fgFaded});
  display: block;
`;
