import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import TextModule, { SharedProps } from '.';
import { Colors } from '../../components/SectionWrapper';
import type { StoryblokComponentType } from 'gatsby-source-storyblok';

type TextModuleBlokSchema = SharedProps &
  Colors<StoryblokColor> & {
    blockContent1: StoryblokComponentType<string>[];
    blockContent2: StoryblokComponentType<string>[];
    blockContent3: StoryblokComponentType<string>[];
  };

const TextModuleBlok: BlokComponent<TextModuleBlokSchema> = ({
  blok,
  Dynamic,
}) => {
  const blockContent1 = blok.blockContent1 && blok.blockContent1[0] && (
    <Dynamic blok={blok.blockContent1[0]} key={blok._uid} />
  );
  const blockContent2 = blok.blockContent2 && blok.blockContent2[0] && (
    <Dynamic blok={blok.blockContent2[0]} key={blok._uid} />
  );
  const blockContent3 = blok.blockContent3 && blok.blockContent3[0] && (
    <Dynamic blok={blok.blockContent3[0]} key={blok._uid} />
  );

  const props = {
    tagline: blok.tagline,
    title: blok.title,
    layout: blok.layout,
    titleLayout: blok.titleLayout,
    blockContent1,
    blockContent2,
    blockContent3,
    bg1: blok.bg1?.color,
    fg1: blok.fg1?.color,
    fg2: blok.fg2?.color,
    fg3: blok.fg3?.color,
  };

  return (
    <SbEditable content={blok}>
      <TextModule {...props} />
    </SbEditable>
  );
};

export default TextModuleBlok;
