import React, { FC } from 'react';
import InlineRenderer, {
  InlineType,
  ParagraphRenderer,
} from './InlineRenderer';
import type { StoryblokComponentType } from 'gatsby-source-storyblok';
import { convertTextToId } from '../../../old/storyblok/util/getArticleLinks';

export type Paragraph = {
  type: 'paragraph';
  content: InlineType[];
};

export type Heading = {
  type: 'heading';
  attrs: { level: number };
  content: InlineType[];
};

export type ListItem = {
  type: 'list_item';
  content: ContentBit[];
};

export type BulletList = {
  type: 'bullet_list';
  content: ListItem[];
};

export type OrderedList = {
  type: 'ordered_list';
  content: ListItem[];
};

export type Blockquote = {
  type: 'blockquote';
  content: ContentBit[];
};

export type HorizontalRule = {
  type: 'horizontal_rule';
};

export type Blok = {
  type: 'blok';
  attrs: {
    body: StoryblokComponentType<string>[];
    id: string;
  };
};

export type CodeBlock = {
  type: 'code_block';
  attrs: { class: string };
  content: InlineType[];
};

export type ContentBit =
  | Heading
  | Paragraph
  | BulletList
  | OrderedList
  | Blockquote
  | HorizontalRule
  | Blok
  | CodeBlock;

const BlockRenderer: FC<{
  bit: ContentBit | ContentBit[];
  Dynamic: FC<{ blok: StoryblokComponentType<string> }>;
}> = ({ bit, Dynamic }) => {
  if (bit instanceof Array) {
    return (
      <>
        {bit.map((b, i) => (
          <BlockRenderer key={i} bit={b} Dynamic={Dynamic} />
        ))}
      </>
    );
  }
  switch (bit.type) {
    case 'paragraph':
      return <ParagraphRenderer content={bit.content} />;
    case 'heading':
      switch (bit.attrs.level) {
        case 1:
          return (
            <h1>
              <InlineRenderer content={bit.content} />
            </h1>
          );
        case 2:
          return (
            <h2
              {...(bit.content && bit.content[0].type === 'text'
                ? {
                    id: convertTextToId(bit.content[0].text),
                  }
                : {})}
            >
              <InlineRenderer content={bit.content} />
            </h2>
          );
        case 3:
          return (
            <h3>
              <InlineRenderer content={bit.content} />
            </h3>
          );
        case 4:
          return (
            <h4>
              <InlineRenderer content={bit.content} />
            </h4>
          );
        case 5:
          return (
            <h5>
              <InlineRenderer content={bit.content} />
            </h5>
          );
        default:
          return (
            <h6>
              <InlineRenderer content={bit.content} />
            </h6>
          );
      }
    case 'bullet_list':
      return (
        <ul>
          {bit.content.map((listItem, i) => (
            <li key={i}>
              <BlockRenderer bit={listItem.content} Dynamic={Dynamic} />
            </li>
          ))}
        </ul>
      );
    case 'ordered_list':
      return (
        <ol>
          {bit.content.map((listItem, i) => (
            <li key={i}>
              <BlockRenderer bit={listItem.content} Dynamic={Dynamic} />
            </li>
          ))}
        </ol>
      );
    case 'blockquote':
      return (
        <blockquote>
          <BlockRenderer bit={bit.content} Dynamic={Dynamic} />
        </blockquote>
      );
    case 'blok':
      return (
        <p>
          {bit.attrs.body.map((blok, i) => (
            <Dynamic key={i} blok={blok} />
          ))}
        </p>
      );
    case 'horizontal_rule':
      return <hr />;
    case 'code_block':
      return (
        <pre>
          <code>
            <InlineRenderer content={bit.content} />
          </code>
        </pre>
      );
    default:
      return <></>;
  }
};

export default BlockRenderer;
