import { FormEvent, useState } from 'react';
import {
  ContactFormDataType,
  useContactFormData,
} from '../../../util/useContactFormData';
import * as z from 'zod';
import postStoryblokForm from '../../../util/postStoryblokForm';
import trackHubspot from '../../../old/components/Forms/trackHubspot';

type ContactFormBlockHookProps = {
  formKey: string;
};

type FormState = {
  fullName: string;
  jobTitle: string;
  companyName: string;
  email: string;
  message?: string;
};

const formSchema = z.object({
  fullName: z.string(),
  jobTitle: z.string(),
  companyName: z.string(),
  email: z.string().email(),
  message: z.string(),
});

export const useContactForm = ({ formKey }: ContactFormBlockHookProps) => {
  const contactFormData = useContactFormData(formKey) as ContactFormDataType;

  const [formData, setFormData] = useState<FormState>({
    fullName: '',
    jobTitle: '',
    companyName: '',
    email: '',
    message: '',
  });

  const [requestStatus, setRequestStatus] = useState({
    isSuccess: false,
    isError: false,
  });

  const [formErrors, setFormErrors] = useState<FormState>({
    fullName: '',
    jobTitle: '',
    companyName: '',
    email: '',
    message: '',
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Handle saving validation
    const isValid = formSchema.safeParse(formData);

    if (!isValid.success) {
      const errors: FormState = {
        fullName: '',
        jobTitle: '',
        companyName: '',
        email: '',
        message: '',
      };

      if (!!isValid.error?.formErrors?.fieldErrors?.fullName) {
        errors.fullName = 'This field cannot be empty';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.jobTitle) {
        errors.jobTitle = 'This field cannot be empty';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.companyName) {
        errors.companyName = 'This field cannot be empty';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.email) {
        errors.email = 'Please enter a valid email address';
      }

      setFormErrors(errors);
      return;
    }

    if (isValid.success) {
      setFormErrors({
        fullName: '',
        jobTitle: '',
        companyName: '',
        email: '',
        message: '',
      });
      try {
        await postStoryblokForm(contactFormData, 'contact', {
          source: 'contact',
          fullName: formData.fullName,
          jobTitle: formData.jobTitle,
          email: formData.email,
          message: formData.message,
        });
        trackHubspot(formData.email);
      } catch (err) {
        console.log('error');

        setRequestStatus({
          isSuccess: false,
          isError: true,
        });
      }
      setRequestStatus({
        isSuccess: true,
        isError: false,
      });
    }
  };

  return {
    requestStatus,
    formData,
    updateFormData: setFormData,
    handleSubmit,
    formErrors,
  };
};
