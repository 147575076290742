import React, { FC } from 'react';
import { StyledButton, ToggleArrow } from './DropdownButton.styles';

export interface DropdownButtonProps {
  toggleList?: () => unknown;
  listOpen?: boolean;
  placeHolder?: string;
}

const DropdownButton: FC<DropdownButtonProps> = ({
  toggleList,
  placeHolder,
  listOpen,
}) => {
  return (
    <div>
      <StyledButton type="button" onClick={toggleList}>
        <span>{placeHolder}</span>
        <ToggleArrow listOpen={listOpen} />
      </StyledButton>
    </div>
  );
};

export default DropdownButton;
