import React from 'react';
import { SpacerElement } from './Spacer.styles';

export interface SpacerProps {
  className?: string;
}

const Spacer: React.FC<SpacerProps> = (props) => {
  return <SpacerElement {...props} />;
};

export default Spacer;
