import React, { FC, useCallback, useContext, useMemo } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';

const StoryblokImageContext = React.createContext(
  new Map<string, IGatsbyImageData | string>()
);

type Props = {
  imageData: readonly (GatsbyTypes.StoryblokAssetFragment | undefined)[];
};

const StoryblokImageProvider: FC<Props> = ({ imageData, children }) => {
  const imageMap = new Map<string, IGatsbyImageData | string>();
  imageData.forEach((data) => {
    if (!data) {
      return;
    }
    imageMap.set(
      data.url || '',
      data.childImageSharp?.gatsbyImageData || data.publicURL || ''
    );
  });

  return (
    <StoryblokImageContext.Provider value={imageMap}>
      {children}
    </StoryblokImageContext.Provider>
  );
};

/**
 * This hook either provides the optimised image or just the provided path.
 *
 * See `entry.tsx` to see the context being initialised with the images.
 * See `gatsby-node.js` to see the images being downloaded and made available to GraphQL
 *
 * @param imageName
 */

// Hook for fetching single image
export const useStoryblokImage = (
  imageName: string
): IGatsbyImageData | string => {
  const storyblokImageMap = useContext(StoryblokImageContext);
  return useMemo(() => {
    return storyblokImageMap.get(imageName) || imageName;
  }, [storyblokImageMap, imageName]);
};

// Hook for fetching an array of images

export const useStoryblokArrayImage = (
  imageNames: string[]
): (IGatsbyImageData | string)[] => {
  const storyblokImageMap = useContext(StoryblokImageContext);
  return useMemo(() => {
    return imageNames.map((name) => storyblokImageMap.get(name) || name);
  }, [storyblokImageMap, imageNames]);
};

export const useStoryblokImageGetter = (): ((
  imageName: string
) => IGatsbyImageData | string) => {
  const storyblokImageMap = useContext(StoryblokImageContext);
  return useCallback(
    (imageName: string) => {
      return storyblokImageMap.get(imageName) || imageName;
    },
    [storyblokImageMap]
  );
};

export const useStoryblokImageMap = (): Map<
  string,
  IGatsbyImageData | string
> => {
  return useContext(StoryblokImageContext);
};

export default StoryblokImageProvider;
