import React, { FC } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { StyledImage } from './BlogHeroImage.styles';

export type SharedProps = {
  imageAlt: string;
};
export type BlogHeroImageProps = SharedProps & {
  imageSm: string | IGatsbyImageData;
  imageLg: string | IGatsbyImageData;
};

const BlogHeroImageModule: FC<BlogHeroImageProps> = ({
  imageSm,
  imageLg,
  imageAlt,
}) => {
  return (
    <StyledImage
      alt={imageAlt}
      mobile={imageSm}
      tablet={imageLg}
      desktop={imageLg}
    />
  );
};

export default BlogHeroImageModule;
