import styled, { css } from 'styled-components';
import themeVars from '../../../styles/themeVariables';
import { breakpointUp } from '../../../styles/mediaUtils';

function makeDimensionsWrapper() {
  return css`
    &::after {
      content: '';
      width: 100%;
      display: inline-block;
      z-index: -100;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  `;
}

function addNewDimensions(dimensions: [number, number]) {
  const style = css`
    width: ${dimensions[0]}px;
    max-width: ${dimensions[0]}px;

    &::after {
      padding-bottom: ${(dimensions[1] / dimensions[0]) * 100}%;
    }
  `;
  return style;
}

const StyledPicture = styled.picture<{
  reveal?: boolean;
  removeBottomRoundCornersOnDesktop?: boolean;
  placeholderColor?: string;
  fallbackDimensions?: [number, number];
  sourceDimensions?: Array<{
    media?: string;
    srcSet: string;
    dimensions?: [number, number];
  }>;
}>`
  position: relative;
  display: inline-block;
  font-size: 0; /* to prevent font spacing between images and psuedo elements */

  img {
    display: inline-block;

    /* fade in image when reveal prop is true: */
    will-change: opacity;
    transition: opacity ${themeVars.revealTransition};
    opacity: ${({ reveal }) => (reveal ? 1 : 0)};
    position: relative;
    border-radius: 0.1875rem;

    @media ${breakpointUp.md} {
      border-radius: ${({ removeBottomRoundCornersOnDesktop }) =>
        removeBottomRoundCornersOnDesktop
          ? '0.1875rem 0.1875rem 0 0'
          : '0.1875rem'};
    }
  }

  &::before {
    /* Image placeholder that will show if image is still being loaded */
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    ${({ placeholderColor }) =>
      placeholderColor
        ? css`
            background-color: ${placeholderColor};
          `
        : css`
            background-color: ${({ theme }) => theme.greyWhiteFg};
            opacity: 0.075;
          `};
  }

  /* add dimensions styling if fallbackDimensions is given: */
  ${({ fallbackDimensions }) =>
    fallbackDimensions &&
    css`
      ${makeDimensionsWrapper()};
      ${addNewDimensions(fallbackDimensions)};
    `};

  /* then for each new source that has it's own dimensions, add styling for that too: */
  @supports not (-ms-high-contrast: none) {
    /* ^ Non-IE hack - picture element not supported in ie so only need the fallback dimensions */
    ${({ sourceDimensions }) =>
      sourceDimensions &&
      sourceDimensions.map(
        (source, index) => css`
          @media ${source.media} {
            ${index === 0 ? makeDimensionsWrapper() : ''};
            ${source.dimensions && addNewDimensions(source.dimensions)};
          }
        `
      )};
  }
`;

export default StyledPicture;
