import styled from 'styled-components';
import { makeArrow } from '../../../../styles/miscUtils';
import { breakpointUp } from '../../../../styles/mediaUtils';
import { colors } from '../../../../styles/themeVariables';
import { Grid } from '../../../layout/Grid';
import { spacing } from '../../../../styles/newThemeVariables.styles';
import { CallToActionLinkButton } from '../../../components/CallToActionLink';
import CMSLink from '../../../components/CMSLink';

export const StyledGrid = styled(Grid)`
  margin-bottom: ${spacing.space36};
`;

export const StyledToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-column: 1 / -1;
  align-items: center;
`;

export const StyledButton = styled(CallToActionLinkButton)``;

export const FilterWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const LabelHolder = styled.span``;

export const ToggleArrow = styled.span<{
  listOpen?: boolean;
}>`
  transform-origin: center;
  transform: rotateX(${({ listOpen }) => (listOpen ? '0' : '180deg')});
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;

  &::after {
    ${() => makeArrow('up', colors.black, '0.40625rem', '0.09375rem')};
    left: 0.34375rem;
    top: 0.375rem;
    position: absolute;
  }

  @media ${breakpointUp.lg} {
    &::after {
      ${() => makeArrow('up', colors.black, '0.45rem', '0.09375rem')};
    }
  }
`;

export const StyledResetFilterButton = styled(CMSLink)``;
