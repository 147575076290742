import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { typeScale } from '../../../styles/newTypography';

export const Header = styled.header`
  grid-column: span 2;

  @media ${breakpointUp.tablet} {
    grid-column: span 8;
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 6;
  }

  @media ${breakpointUp.desktopLarge} {
    grid-column: span 6;
  }

  &.xl {
    grid-column: span 2;

    @media ${breakpointUp.tablet} {
      grid-column: span 9;
      max-width: 41.25rem;
    }

    @media ${breakpointUp.desktop} {
      grid-column: span 10;
      max-width: 47.5rem;
    }

    @media ${breakpointUp.desktopLarge} {
      grid-column: span 7;
      max-width: 60rem;
    }
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  text-wrap: balance;
  margin: 0;
  ${typeScale.mobile.body.lg};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xxl};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxxl};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxxl};
  }

  .xl & {
    ${typeScale.mobile.body.xl};

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xxxl};
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxxl};
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.fivexl};
    }
  }
`;
