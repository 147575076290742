import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { BlokComponent, NewStoryblokMetaData } from '../../types/blok.model';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../util/useThemeMode';
import Spacer from '../components/Spacer';

export interface ContactPageSchema extends NewStoryblokMetaData {
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  bodySpacing?: string;
  bodyLowerSpacing?: string;
}

const ContactPage: BlokComponent<ContactPageSchema> = ({ blok, Dynamic }) => {
  return (
    <SbEditable content={blok}>
      <ThemeModeContextProvider themeMode={'light'}>
        <ThemeProvider theme={defaultTheme}>
          <RenderAfterPageTransition>
            {blok.body?.map((insideBlok, index) => (
              <Spacer
                key={insideBlok._uid}
                className={`item-${index} ${
                  insideBlok.spacing ?? blok.bodySpacing ?? 'default'
                } `}
              >
                <Dynamic blok={insideBlok} />
              </Spacer>
            ))}
          </RenderAfterPageTransition>
        </ThemeProvider>
      </ThemeModeContextProvider>
      <ThemeModeContextProvider themeMode={'dark'}>
        <ThemeProvider theme={darkTheme(defaultTheme)}>
          <RenderAfterPageTransition>
            {blok.bodyLower?.map((insideBlok, index) => (
              <Spacer
                key={insideBlok._uid}
                className={`item-${index} ${
                  insideBlok.spacing ?? blok.bodyLowerSpacing ?? 'default'
                } `}
              >
                <Dynamic blok={insideBlok} />
              </Spacer>
            ))}
          </RenderAfterPageTransition>
        </ThemeProvider>
      </ThemeModeContextProvider>
    </SbEditable>
  );
};

export default React.memo(ContactPage);
