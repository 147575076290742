import React from 'react';
import SlideShowBlock, { SlideShowProps } from '../../blocks/SlideShowBlock';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import Eyebrow from '../../components/Eyebrow';
import { QuoteSlideShow } from './QuoteSlideShowModule.styles';

export interface QuoteSlideShowProps extends SlideShowProps {
  eyebrow?: string;
}

const QuoteSlideShowModule: React.FC<QuoteSlideShowProps> = ({
  children,
  eyebrow,
  ...rest
}) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <QuoteSlideShow>
            <Eyebrow text={eyebrow} />
            <SlideShowBlock {...rest}>{children}</SlideShowBlock>
          </QuoteSlideShow>
        </Grid>
      </Layout>
    </Section>
  );
};

export default QuoteSlideShowModule;
