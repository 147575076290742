import React, { FC } from 'react';
import { Colors } from '../../components/SectionWrapper';
import {
  StyledBlockquote,
  CiteName,
  StyledGrid,
  StyledSectionWrapper,
} from './QuoteModule.styles';
import { Layout } from '../../layout/Layout';

export interface SharedProps {
  quote: string;
  author?: string;
  authorCredentials?: string;
  layout: 'center' | 'offset-right';
  statement: boolean;
}

export type CaseStudyQuoteProps = SharedProps & Colors<string>;

const QuoteModule: FC<CaseStudyQuoteProps> = ({
  quote,
  author,
  authorCredentials,
  layout = 'center',
  statement = false,
  bg1,
  fg1,
  fg2,
}) => {
  return (
    <StyledSectionWrapper bg1={bg1} fg1={fg1} fg2={fg2}>
      <Layout variant="smallWidth">
        <StyledGrid>
          <StyledBlockquote
            fg1={fg1}
            fg2={fg2}
            layout={layout}
            statement={statement}
          >
            <p>{quote}</p>
            {author && (
              <cite>
                <CiteName>{author}</CiteName>
                {authorCredentials}
              </cite>
            )}
          </StyledBlockquote>
        </StyledGrid>
      </Layout>
    </StyledSectionWrapper>
  );
};

export default QuoteModule;
