import styled from 'styled-components';
import { Nav, Slide } from '../../blocks/SlideShowBlock/SlideShowBlock.styles';
import { breakpointUp } from '../../../styles/mediaUtils';

export const VideoSlideShow = styled.div`
  grid-column: 1 / -1;
  margin: 0 -1rem;

  @media ${breakpointUp.tablet} {
    margin: 0;
  }

  ${Nav} {
    display: none;
  }

  ${Slide} {
    video {
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: top;
      position: static;
    }

    video[poster] {
      object-fit: cover;
    }
  }
`;
