import styled from 'styled-components';
import { typeScale } from '../../../../styles/newTypography';
import { spacing } from '../../../../styles/newThemeVariables.styles';
import CMSLink from '../../../components/CMSLink';
import { breakpointUp } from '../../../../styles/mediaUtils';

export const Separator = styled.div`
  position: relative;
  color: ${({ theme }) => theme.grey200};
  top: ${spacing.space4};
`;

export const Item = styled.div`
  display: flex;
  column-gap: ${spacing.space10};
`;

export const List = styled.div`
  ${typeScale.desktop.body.xxl};
  display: flex;
  grid-column: span 9;
  column-gap: ${spacing.space10};
  row-gap: calc(${spacing.space10});
  flex-wrap: wrap;
  flex-direction: row;
  align-content: end;

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxl};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxl};
  }

  ${Item}:last-child ${Separator} {
    display: none;
  }
`;

export const Link = styled(CMSLink)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.textPrimary : theme.text500};
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.textPrimary};
  }
`;
