import React, { FC } from 'react';
import { LazyLoadProps, useLazyLoad } from '../../../util/useLazyLoad';
import type { Props as PictureProps } from './Picture';
import PictureComponent from './Picture';

export type Props = PictureProps & {
  lazyLoad?: boolean;
};

const Picture: FC<Props> = ({ lazyLoad = false, ...props }) => {
  let lazyLoadProps: LazyLoadProps | Record<string, unknown> =
    useLazyLoad(true);
  lazyLoadProps = lazyLoad ? lazyLoadProps : {};

  return <PictureComponent {...props} {...lazyLoadProps} />;
};

export default Picture;
