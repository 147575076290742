import React, { FC } from 'react';
import { useWhitePaperForm } from './WhitePaperFormModule.hook';
import styled, { ThemeProvider } from 'styled-components';
import { StylContentWrapper, StylTextBox } from './WhitePaperFormModule.styles';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { FormInput } from '../../components/FormInput';
import Button from '../../components/Button';
import { breakpointUp } from '../../../styles/mediaUtils';
import TickIcon from '../../../icons/TickIcon';
import { isProduction } from '../../../util/isProduction';
import { Layout } from '../../layout/Layout';

export interface WhitePaperFormModuleProps {
  form_data: string;
  light_theme: boolean;
  tagline: string;
  title: string;
  subtitle: string;
  background_color: { color: string };
}

const StylForm = styled.form`
  margin-top: -0.5rem;

  @media ${breakpointUp.md} {
    margin-top: -1.5rem;
  }

  & > button {
    margin-top: 1.5rem;

    @media ${breakpointUp.md} {
      margin-top: 2rem;
    }
  }
`;

const StyledFormWrapper = styled.div`
  grid-column: span 12;

  @media ${breakpointUp.lg} {
    grid-column: 8 / span 4;
  }
`;

export const WhitePaperFormModule: FC<WhitePaperFormModuleProps> = ({
  form_data,
  light_theme,
  tagline,
  title,
  subtitle,
  background_color,
}) => {
  const { handleSubmit, updateFormData, formErrors, requestStatus } =
    useWhitePaperForm({
      formKey: form_data,
    });

  return (
    <ThemeProvider theme={light_theme ? defaultTheme : darkTheme}>
      <div
        style={{ backgroundColor: background_color.color }}
        id="white-paper-form-module"
      >
        <Layout>
          <StylContentWrapper>
            <StylTextBox>
              <h3>{tagline}</h3>
              <h2>{title}</h2>
              <p>{subtitle}</p>
            </StylTextBox>
            <StyledFormWrapper>
              <StylForm onSubmit={handleSubmit}>
                <FormInput
                  placeholder="Full name*"
                  isError={!!formErrors.fullName}
                  errorMessage={formErrors.fullName}
                  onChange={(event) =>
                    updateFormData((prevState) => ({
                      ...prevState,
                      fullName: event.target.value,
                    }))
                  }
                />
                <FormInput
                  placeholder="Job title*"
                  isError={!!formErrors.jobTitle}
                  errorMessage={formErrors.jobTitle}
                  onChange={(event) =>
                    updateFormData((prevState) => ({
                      ...prevState,
                      jobTitle: event.target.value,
                    }))
                  }
                />
                <FormInput
                  placeholder="Company name*"
                  isError={!!formErrors.companyName}
                  errorMessage={formErrors.companyName}
                  onChange={(event) =>
                    updateFormData((prevState) => ({
                      ...prevState,
                      companyName: event.target.value,
                    }))
                  }
                />
                <FormInput
                  placeholder="Email address*"
                  isError={!!formErrors.email}
                  errorMessage={formErrors.email}
                  onChange={(event) =>
                    updateFormData((prevState) => ({
                      ...prevState,
                      email: event.target.value,
                    }))
                  }
                />
                <Button
                  type="submit"
                  btnsize="md"
                  variant="block"
                  dark
                  disabled={!isProduction || requestStatus.isSuccess}
                  data-id={
                    requestStatus.isSuccess
                      ? 'deck-form-sent'
                      : 'deck-form-submit'
                  }
                >
                  {requestStatus.isSuccess ? (
                    <>
                      <TickIcon /> Submitted
                    </>
                  ) : (
                    'Download PDF'
                  )}
                </Button>
              </StylForm>
            </StyledFormWrapper>
          </StylContentWrapper>
        </Layout>
      </div>
    </ThemeProvider>
  );
};

export default WhitePaperFormModule;
