import React from 'react';
import { createContext, ReactNode, useContext } from 'react';

export type WhitePaperFormDataType = {
  id: string;
  name: string;
  formKey: string;
  template: string;
  reportName: string;
  deckLink: string;
};

interface WhitePaperFormDataContextShape
  extends Array<WhitePaperFormDataType> {}

interface WhitePaperFormDataContextProviderProps {
  children: ReactNode;
  data?: WhitePaperFormDataType[];
}

const WhitePaperFormDataContext = createContext<
  WhitePaperFormDataContextShape | undefined
>(undefined);

export const WhitePaperFormDataContextProvider = ({
  children,
  data,
}: WhitePaperFormDataContextProviderProps): JSX.Element => {
  return (
    <WhitePaperFormDataContext.Provider value={data}>
      {children}
    </WhitePaperFormDataContext.Provider>
  );
};

export const useWhitePaperFormData = (formKey?: string) => {
  const context = useContext(WhitePaperFormDataContext);

  if (context === undefined) {
    console.error('Missing passed white paper form data from pageContext');
    return;
  }

  if (formKey) {
    return context.find((data) => data.name === formKey);
  }

  return context;
};
