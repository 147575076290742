import React from 'react';
import SbEditable from 'storyblok-react';
import QuoteSlideBlock, { QuoteSlideProps } from '.';
import { BlokComponent } from '../../../types/blok.model';

const QuoteSlideBlok: BlokComponent<QuoteSlideProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <QuoteSlideBlock {...blok} />
    </SbEditable>
  );
};

export default QuoteSlideBlok;
