import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import { textSizes } from '../../../../styles/typography';

export const StyledLists = styled.ul<{ className?: string }>`
  list-style: none;
  margin: 0;
  padding: 1.5rem 0 2rem 0;
  background-color: var(--bg1, ${({ theme }) => theme.snow});
  max-height: 80vh;
  overflow-y: auto;

  @media ${breakpointUp.md} {
    padding: 2rem 0;
    background-color: var(--bg1, ${({ theme }) => theme.white});
  }

  li:last-of-type > a {
    margin: 0 1rem 0 0;
  }
`;

export const StyledLink = styled.a`
  ${textSizes.xs};
  color: var(--fg2, ${({ theme }) => theme.grey});
  display: block;
  border-left: solid 0.3125rem transparent;
  padding-left: 1.25rem;
  box-decoration-break: clone;
  margin: 0 1rem 1rem 0;

  &:hover,
  &:active {
    border-left: solid 0.3125rem;
    color: var(--fg3, ${({ theme }) => theme.black});
  }

  @media ${breakpointUp.lg} {
    color: var(--fg2, ${({ theme }) => theme.greyMedium});
  }
`;
