import React, { FC, createContext, useContext } from 'react';

type ThemeMode = 'light' | 'dark';
type ContextShape = { themeMode: ThemeMode };

const ThemeModeContext = createContext<ContextShape>({ themeMode: 'light' });

/**
 * Custom Provider for setting boundaries of light and dark mode.
 * @prop themeMode - the mode to use for this boundary.
 * @returns The themeMode context provider
 */
const ThemeModeContextProvider: FC<ContextShape> = ({
  themeMode,
  ...props
}) => {
  return <ThemeModeContext.Provider value={{ themeMode }} {...props} />;
};

/**
 * Custom hook that allows checking the context of
 * which themeMode the component is in (set by the context provider).
 * @returns An object containing:
 * - 'themeMode': The mode the component is currently a child of.
 */
const useThemeMode = () => useContext(ThemeModeContext);

export { ThemeModeContextProvider, useThemeMode };
