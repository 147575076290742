import React, { FC } from 'react';
import BlogPostThumbnail from '.';
import { TagType } from '../../../old/storyblok/tags-context';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type ThumbnailSchema = {
  thumbnail_sm: string | IGatsbyImageData;
  thumbnail_lg: string | IGatsbyImageData;
  thumbnail_alt: string;
  link: string;
  title: string;
  description: string;
  tags: TagType[];
  categories: CategoryType[];
  featured?: boolean;
};
const BlogPostThumbnailBlok: FC<ThumbnailSchema> = ({
  thumbnail_sm,
  thumbnail_lg,
  featured,
  ...props
}) => {
  return (
    <BlogPostThumbnail
      {...props}
      thumbnail_sm={thumbnail_sm}
      thumbnail_lg={thumbnail_lg}
      featured={featured}
    />
  );
};

export default BlogPostThumbnailBlok;
