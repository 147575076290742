import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import {
  GallerySectionWrapper,
  GallerySection,
  GalleryWrapper,
  ImageType,
  DuoLayout,
} from '../Gallery.styles';
import StoryblokAssetImage from '../../../components/AssetImage';
import { Layout } from '../../../layout/Layout';

export type SharedProps = {
  type: ImageType;
  bottomPadding: boolean;
  topPadding: boolean;
  layout: DuoLayout;
  image1Alt: string;
  image2Alt: string;
};

export type GalleryModuleDuoProps = SharedProps & {
  bg: string;
  image1: string | IGatsbyImageData;
  image2: string | IGatsbyImageData;
};

const DuoGallery = styled(GalleryWrapper)<{
  layout: DuoLayout;
  type: ImageType;
}>`
  display: flex;
  flex-direction: column;

  @media ${breakpointUp.sm()} {
    display: grid;

    div {
      position: relative;
    }

    grid-template-columns: ${({ layout }) =>
      ({
        offsetRight: '4fr 8fr',
        offsetLeft: '8fr 4fr',
        equal: '6fr 6fr',
      }[layout])};
  }
`;

export const StyledDuoImage = styled(StoryblokAssetImage)`
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const GalleryModuleTrio: FC<GalleryModuleDuoProps> = ({
  bg,
  type,
  image1,
  image2,
  image1Alt,
  image2Alt,
  layout,
  bottomPadding,
  topPadding,
}) => {
  return (
    <GallerySectionWrapper bg1={bg}>
      <Layout variant={type !== 'fullBleed' ? 'smallWidth' : undefined}>
        <GallerySection
          type={type}
          bg={bg}
          bottomPadding={bottomPadding}
          topPadding={topPadding}
        >
          <DuoGallery type={type} layout={layout}>
            <StyledDuoImage alt={image1Alt} image={image1} />
            <StyledDuoImage alt={image2Alt} image={image2} />
          </DuoGallery>
        </GallerySection>
      </Layout>
    </GallerySectionWrapper>
  );
};

export default GalleryModuleTrio;
