import React, { FC, ReactNode } from 'react';
import { Colors } from '../../components/SectionWrapper';
import { Content, StyledSectionWrapper, Title } from './SummaryModule.styles';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { breakpointUp } from '../../../styles/mediaUtils';
import styled from 'styled-components';

export interface SharedProps {
  title: string;
}

export type SummaryModuleProps = SharedProps &
  Colors<string> & {
    textContent: ReactNode;
    blockContent: ReactNode;
  };

const StyledGridItem = styled.div`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: 1 / 7;
  }

  @media ${breakpointUp.lg} {
    grid-column: 1 / span 7;
  }
`;

const StyledBlockContent = styled.div`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: 8 / -1;
  }

  @media ${breakpointUp.lg} {
    grid-column: 9 / 12;
  }
`;

const SummaryModule: FC<SummaryModuleProps> = ({
  title,
  textContent,
  blockContent,
  bg1,
  bg2,
  fg1,
  fg2,
  fg3,
}) => {
  return (
    <StyledSectionWrapper bg1={bg1} bg2={bg2} fg1={fg1} fg2={fg2} fg3={fg3}>
      <Layout variant="smallWidth">
        <Grid>
          <StyledGridItem>
            <Title>{title}</Title>
            <Content>{textContent}</Content>
          </StyledGridItem>
          <StyledBlockContent>{blockContent}</StyledBlockContent>
        </Grid>
      </Layout>
    </StyledSectionWrapper>
  );
};

export default SummaryModule;
