import React from 'react';
import TextFeatureBlock, {
  TextFeatureBlockProps,
} from '../../blocks/TextFeatureBlock';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { Module } from './TextFeatureModule.styles';

export interface TextFeatureModuleProps extends TextFeatureBlockProps {
  rightAlign?: boolean;
}

const TextFeature: React.FC<TextFeatureModuleProps> = ({
  rightAlign = false,
  variant = 'highlight',
  ...rest
}) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <Module rightAlign={rightAlign} className={variant}>
            <TextFeatureBlock {...rest} variant={variant} />
          </Module>
        </Grid>
      </Layout>
    </Section>
  );
};

export default TextFeature;
