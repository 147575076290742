import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokRelatedPosts,
} from '../../types/blok.model';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';

import InlineFilterModule from '../modules/InlineFilterModule';
import CardList from '../modules/CardList';
import CardReportContent from '../blocks/CardReportContent';
import { Layout } from '../layout/Layout';
import { Grid } from '../layout/Grid';
import { Section } from '../layout/Section';
import { Pagination } from '../components/Pagination/Pagination';
import Spacer from '../components/Spacer';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useReportsThumbnails } from '../../util/useReportsThumbnails';
import { useReports } from '../../old/storyblok/reports-context';
import { useViewport } from '../../util/useViewport';
import transformCollection from '../../util/transformCollection';

interface ReportCollectionPageSchema extends NewStoryblokMetaData {
  headerTitle: string;
  featured_posts: StoryblokRelatedPosts;
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  bodySpacing?: string;
  bodyLowerSpacing?: string;
}

const ListContainer = styled.div`
  margin-bottom: ${spacing.space112};
`;

const ReportsCollectionPage: BlokComponent<ReportCollectionPageSchema> = ({
  blok,
  Dynamic,
}) => {
  const { pagination, posts } = useReports();
  const postUuids = posts?.map((post) => post.uuid);
  const convertedReports = useReportsThumbnails(postUuids);
  const transformedPosts =
    convertedReports &&
    transformCollection(convertedReports, blok.featured_posts);
  const { isMobile } = useViewport();

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={defaultTheme}>
        <RenderAfterPageTransition>
          <InlineFilterModule
            headerTitle={blok.headerTitle}
            resetLabel="All reports"
            resetFilterUrl="reports/"
          />

          {transformedPosts && (
            <>
              <ListContainer>
                <CardList
                  scrollMobile={false}
                  mobile={{
                    rowGap: spacing.space112,
                    variant: 'one-column',
                  }}
                  tablet={{
                    rowGap: spacing.space112,
                    variant: 'one-column',
                  }}
                  desktop={{
                    rowGap: spacing.space112,
                    variant: 'two-column-highlight',
                  }}
                  desktopLarge={{
                    rowGap: spacing.space112,
                    variant: 'two-column-highlight',
                  }}
                >
                  {transformedPosts.map((report) => (
                    <CardReportContent
                      key={report.id}
                      title={report.title}
                      link={report.link}
                      coverImage={report.cover_image}
                      coverImageAlt={report.cover_alt}
                      mobile={{
                        aspect: '3 / 4',
                        thumbnail: report.thumbnail_3x4,
                      }}
                      tablet={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      desktop={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      desktopLarge={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      authors={report.authors}
                      thumbnailAlt={report.thumbnail_alt}
                      description={report.description}
                      tags={[...report.tags, ...report.categories]}
                    />
                  ))}
                </CardList>
              </ListContainer>

              {pagination?.currentPage && pagination?.numPages > 1 && (
                <Section>
                  <Layout>
                    <Grid>
                      <Pagination
                        siblings={{
                          left: isMobile ? 1 : 2,
                          right: isMobile ? 1 : 1,
                        }}
                        currentPage={pagination?.currentPage}
                        totalNumberOfPages={pagination?.numPages}
                        hrefBuilder={(page) => {
                          if (page === 1) {
                            return '/reports/';
                          }

                          return `/reports/${page}`;
                        }}
                      />
                    </Grid>
                  </Layout>
                </Section>
              )}
            </>
          )}

          {blok.body?.map((insideBlok, index) => (
            <Spacer
              key={insideBlok._uid}
              className={`item-${index} ${
                insideBlok.spacing ?? blok.bodySpacing ?? 'default'
              } `}
            >
              <Dynamic blok={insideBlok} />
            </Spacer>
          ))}
        </RenderAfterPageTransition>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme(defaultTheme)}>
        <RenderAfterPageTransition>
          {blok.bodyLower?.map((insideBlok, index) => (
            <Spacer
              key={insideBlok._uid}
              className={`item-${index} ${
                insideBlok.spacing ?? blok.bodyLowerSpacing ?? 'default'
              } `}
            >
              <Dynamic blok={insideBlok} />
            </Spacer>
          ))}
        </RenderAfterPageTransition>
      </ThemeProvider>
    </SbEditable>
  );
};

export default React.memo(ReportsCollectionPage);
