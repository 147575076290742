import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { StoryblokBlogPost } from '../../types/blok.model';
import { Pagination } from '../../types';

type BlogPostsContextShape = {
  posts: StoryblokBlogPost[] | undefined;
  allPosts: StoryblokBlogPost[] | undefined;
  pagination?: Pagination;
};

interface BlogPostsContextProviderProps {
  children: ReactNode;
  data?: {
    posts: StoryblokBlogPost[] | undefined;
    allPosts: StoryblokBlogPost[] | undefined;
  };
  pagination?: Pagination;
}

const BlogPostsContext = createContext<BlogPostsContextShape | undefined>(
  undefined
);

export const BlogPostsContextProvider = ({
  children,
  data,
  pagination,
}: BlogPostsContextProviderProps): JSX.Element => {
  return (
    <BlogPostsContext.Provider
      value={{
        posts: data?.posts,
        allPosts: data?.allPosts,
        pagination,
      }}
    >
      {children}
    </BlogPostsContext.Provider>
  );
};

export const useBlogPosts = () => {
  const context = useContext(BlogPostsContext);
  if (context === undefined) {
    throw new Error('Missing passed allBlogPosts from pageContext');
  }

  return context;
};
