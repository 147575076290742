import React from 'react';
import SbEditable from 'storyblok-react';
import WhitePaperFormModule, { WhitePaperFormModuleProps } from '.';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import { Colors } from '../../components/SectionWrapper';

type WhitepaperFormModuleBlokSchema = WhitePaperFormModuleProps &
  Colors<StoryblokColor>;

const WhitePaperFormModuleBlok: BlokComponent<
  WhitepaperFormModuleBlokSchema
> = ({ blok }) => {
  const props = {
    ...blok,
  } as WhitePaperFormModuleProps;

  return (
    <SbEditable content={blok}>
      <WhitePaperFormModule {...props} />
    </SbEditable>
  );
};

export default WhitePaperFormModuleBlok;
