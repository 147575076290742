import React, { FC } from 'react';
import CallToActionLink from '../../components/CallToActionLink';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { Title, Header, List } from './CardList.styles';

export type VariantTypes =
  | 'one-column'
  | 'two-column'
  | 'three-column'
  | 'four-column'
  | 'one-column-highlight'
  | 'two-column-highlight'
  | 'three-column-highlight'
  | 'four-column-highlight';

export type CardListProps = {
  title?: string;
  ctaLink?: string;
  ctaText?: string;
  scrollMobile?: boolean;
  mobile?: {
    rowGap?: string;
    variant?: VariantTypes;
  };
  tablet?: {
    rowGap?: string;
    variant?: VariantTypes;
  };
  desktop?: {
    rowGap?: string;
    variant?: VariantTypes;
  };
  desktopLarge?: {
    rowGap?: string;
    variant?: VariantTypes;
  };
};

const CardList: FC<CardListProps> = ({
  title = '',
  ctaLink,
  ctaText,
  mobile = { rowGap: spacing.space48, variant: 'one-column' },
  tablet = { rowGap: spacing.space48, variant: 'one-column' },
  desktop = { rowGap: spacing.space56, variant: 'three-column' },
  desktopLarge = { rowGap: spacing.space56, variant: 'three-column' },
  scrollMobile = true,
  children,
}) => {
  return (
    <Section>
      <Layout>
        <Grid>
          {(title || ctaText) && (
            <Header>
              {title && <Title>{title}</Title>}
              {ctaText && (
                <CallToActionLink size={'md'} to={ctaLink}>
                  {ctaText}
                </CallToActionLink>
              )}
            </Header>
          )}
          <List
            scrollMobile={scrollMobile}
            className={`
              mobile-${mobile?.variant} 
              tablet-${tablet?.variant} 
              desktop-${desktop?.variant}
              desktop-large-${desktopLarge?.variant}
            `}
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            desktopLarge={desktopLarge}
          >
            {children}
          </List>
        </Grid>
      </Layout>
    </Section>
  );
};

export default CardList;
