import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';

export const StyledRow = styled(Grid)`
  row-gap: 2rem;
  padding-top: 2rem;

  @media ${breakpointUp.sm} {
    row-gap: 3rem;
    padding-top: 3rem;
  }

  @media ${breakpointUp.lg} {
    row-gap: 4rem;
    padding-top: 4rem;
  }
`;
