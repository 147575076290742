import React, { FC, ReactNode } from 'react';
import { LazyLoadProps, useLazyLoad } from '../../util/useLazyLoad';

const LazyRevealWrapper: FC<{
  children: (
    ref: (node?: Element | null | undefined) => void,
    readyToLoad: boolean
  ) => ReactNode;
}> = ({ children }) => {
  const { ref, readyToLoad }: LazyLoadProps = useLazyLoad(
    false,
    '0px 150% 0px 150%'
  );

  return <>{children(ref, readyToLoad)}</>;
};
export default LazyRevealWrapper;
