import React from 'react';
import SbEditable from 'storyblok-react';

import GalleryModuleTrio, { SharedProps } from '.';
import { useStoryblokImage } from '../../../../old/storyblok/asset-context';
import {
  StoryblokAsset,
  BlokComponent,
  StoryblokColor,
} from '../../../../types/blok.model';

type GalleryModuleTrioBlokSchema = SharedProps & {
  bg1: StoryblokColor;
  image1: StoryblokAsset;
  image2: StoryblokAsset;
  image3: StoryblokAsset;
};

const GalleryModuleTrioBlok: BlokComponent<GalleryModuleTrioBlokSchema> = ({
  blok,
}) => {
  const image1 = useStoryblokImage(blok.image1.filename);
  const image2 = useStoryblokImage(blok.image2.filename);
  const image3 = useStoryblokImage(blok.image3.filename);

  const props = {
    ...blok,
    bg: blok.bg1?.color || '',
    image1,
    image2,
    image3,
  };

  return (
    <SbEditable content={blok}>
      <GalleryModuleTrio {...props} />
    </SbEditable>
  );
};

export default GalleryModuleTrioBlok;
