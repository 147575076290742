import React, { useEffect, useState } from 'react';
import SbEditable from 'storyblok-react';
import {
  BlokComponent as PillarPageComponent,
  NewStoryblokMetaData,
  StoryblokAsset,
  StoryblokAuthor,
  StoryblokCategories,
  StoryblokTags,
} from '../../types/blok.model';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import { useNavTheme } from '../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import styled from 'styled-components';
import { breakpointUp } from '../../styles/mediaUtils';
import {
  getArticleLinks,
  ArticleLinksSchema,
} from '../../old/storyblok/util/getArticleLinks';
import { ArticleLinks } from '../blocks/ArticleLinksBlock';
import ArticleLinksMobile from '../blocks/ArticleLinksBlock/ArticleLinksMobile/ArticleLinksMobile';
import { RichTextDocument } from '../components/RichText/StoryblokRichText';
import HeaderPillarBlok from '../modules/PillarHeaderModule/PillarHeader.blok';
import ArticleContentModuleBlok from '../modules/ArticleContentModule/ArticleContentModule.blok';
import { Layout } from '../layout/Layout';
import { Grid } from '../layout/Grid';
import ContactFormModule from '../modules/ContactFormModule';

interface PillarPageSchema extends NewStoryblokMetaData {
  light_header: boolean;
  title: string;
  author: StoryblokAuthor;
  category: StoryblokCategories;
  tags: StoryblokTags;
  hero_image: StoryblokAsset;
  content: RichTextDocument;
  hide_hero_image: boolean;
}

const Sticky = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;

  @media ${breakpointUp.md} {
    display: none;
  }
`;

const TopArea = styled.div`
  position: relative;
`;

const StickyLane = styled.div`
  display: none;
  position: absolute;
  top: 11.75rem; /* Upper boundary to the page */
  bottom: 1.5rem; /*  Distance to the footer */
  max-width: 28rem;
  right: 0;

  @media ${breakpointUp.md} {
    display: block;

    & > ${Layout} {
      position: sticky;
      top: 6rem;
      z-index: 2;
      margin-top: 3rem;
    }
  }
`;

const BodyContent = styled.div`
  background-color: ${({ theme }) => theme.snow};
`;

const StyledArticleWrapper = styled.div`
  grid-column: span 12;

  @media ${breakpointUp.md} {
    grid-column: span 7;
    padding-top: 3.5rem;
  }
`;

const StyledArticleLinksWrapper = styled.div`
  grid-column: span 12;
`;

const PillarPageNew: PillarPageComponent<PillarPageSchema> = ({
  blok,
  Dynamic,
}) => {
  useNavTheme(blok.light_header ? defaultTheme : darkTheme(defaultTheme));

  const [articleLinks, setArticleLinks] = useState<ArticleLinksSchema>();

  useEffect(() => {
    if (blok.content) {
      const content = blok.content.content;
      setArticleLinks(getArticleLinks(content));
    }
  }, [blok.content]);

  return (
    <SbEditable content={blok}>
      <TopArea>
        {articleLinks?.length ? (
          <StickyLane>
            <Layout>
              <Grid>
                <StyledArticleLinksWrapper>
                  <ArticleLinks links={articleLinks} />
                </StyledArticleLinksWrapper>
              </Grid>
            </Layout>
          </StickyLane>
        ) : (
          <></>
        )}
        <HeaderPillarBlok
          blok={{
            title: blok.title,
            tags: blok.tags,
            categories: blok.category,
            bgImage: blok.hero_image,
            author: blok.author,
            _uid: '',
            component: '',
          }}
          Dynamic={Dynamic}
        />

        <RenderAfterPageTransition>
          <BodyContent>
            <Layout>
              <Grid>
                <StyledArticleWrapper>
                  <ArticleContentModuleBlok
                    blok={{
                      _uid: '',
                      component: '',
                      content: blok.content,
                    }}
                    Dynamic={Dynamic}
                  />
                </StyledArticleWrapper>
              </Grid>
            </Layout>
          </BodyContent>
          {!!articleLinks?.length ? (
            <Sticky>
              <ArticleLinksMobile links={articleLinks} placeHolder="Contents" />
            </Sticky>
          ) : (
            <></>
          )}
        </RenderAfterPageTransition>
      </TopArea>
      <ContactFormModule
        form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
        tagline={'READY TO GET STARTED?'}
        title={
          'Get in touch today and accelerate how your most important work gets done.'
        }
        subtitle={
          'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
        }
        light_theme={false}
      />
    </SbEditable>
  );
};

export default React.memo(PillarPageNew);
