import React from 'react';
import SbEditable from 'storyblok-react';
import ReelModule, { ReelModuleProps } from '.';
import { BlokComponent, StoryblokAsset } from '../../../types/blok.model';
import { useStoryblokArrayImage } from '../../../old/storyblok/asset-context';

type ReelModuleBlokSchema = Omit<ReelModuleProps, 'logos'> & {
  logos: StoryblokAsset[];
};

const DEFAULT_DELAY = 3000;

const ReelModuleBlok: BlokComponent<ReelModuleBlokSchema> = ({ blok }) => {
  const logos = useStoryblokArrayImage(
    blok.logos.map((asset: StoryblokAsset) => asset.filename)
  );
  const metadata = blok.logos.map((logo) => ({
    alt: logo.alt,
    title: logo.title,
  }));
  const delayInMilliseconds = blok.delay ? blok.delay * 1000 : DEFAULT_DELAY;

  return (
    <SbEditable content={blok}>
      <ReelModule
        logos={logos}
        title={blok.title}
        metadata={metadata}
        delay={delayInMilliseconds}
      />
    </SbEditable>
  );
};

export default ReelModuleBlok;
