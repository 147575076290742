import React from 'react';
import SbEditable from 'storyblok-react';
import BlogRow, { BlogRowProps } from '.';

import { BlokComponent } from '../../../types/blok.model';

type BlogCollectionRowSchema = BlogRowProps;

const BlogRowModule: BlokComponent<BlogCollectionRowSchema> = ({ blok }) => {
  const props = {
    posts: blok.posts,
  };
  return (
    <SbEditable content={blok}>
      <BlogRow {...props} />
    </SbEditable>
  );
};

export default BlogRowModule;
