import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { typeScale } from '../../styles/newTypography';
import { breakpointUp } from '../../styles/mediaUtils';
import { spacing } from '../../styles/newThemeVariables.styles';

interface FormInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
  errorMessage?: string;
}

const TextArea = styled.textarea<{ error?: boolean }>`
  ${typeScale.mobile.mono.md}
  color: ${({ theme, error }) => (error ? theme.textError : theme.textPrimary)};
  resize: none;
  border: none;
  display: block;
  width: 100%;
  min-height: ${spacing.space144};
  padding: ${spacing.space16};
  min-width: 100%;
  cursor: text;
  outline: none;
  box-shadow: none;
  background-color: ${({ theme }) => theme.bgTranslucent};
  margin-bottom: ${spacing.space24};
  border-radius: 0;

  &::placeholder {
    color: ${({ theme, error }) => (error ? theme.textError : theme.text400)};
  }

  &:hover::placeholder {
    color: ${({ theme, error }) => (error ? theme.textError : theme.text200)};
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.sm}

    &::placeholder {
      color: ${({ theme, error }) => (error ? theme.textError : theme.text300)};
    }
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.textError};
  ${typeScale.mobile.mono.md}

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

export const FormTextArea = (props: FormInputProps) => {
  return (
    <>
      {props.isError && props.errorMessage && (
        <Error>{props.errorMessage}</Error>
      )}
      <TextArea
        id={props.placeholder}
        aria-label={props.placeholder}
        {...props}
        error={props.isError}
      />
    </>
  );
};
