import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import styled from 'styled-components';
import { ButtonSize, ButtonType } from '../../components/Button';
import CallToAction from './index';
import { colors } from '../../../styles/themeVariables';

type CTABlokSchema = {
  bgColor: StoryblokColor;
  textColor: StoryblokColor;
  variant: ButtonType;
  size: ButtonSize;
  text: string;
  target: string;
};

const StyledCTA = styled(CallToAction)`
  margin-top: 2.75rem;
`;

const CallToActionBlock: BlokComponent<CTABlokSchema> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StyledCTA
        btnsize={blok.size}
        variant={blok.variant}
        link={`${blok.target}`}
        bgColor={blok.bgColor?.color ?? colors.blue}
        textColor={blok.textColor?.color ?? colors.white}
        title={blok.text}
      />
    </SbEditable>
  );
};

export default CallToActionBlock;
