import React from 'react';
import SbEditable from 'storyblok-react';
import TextFeatureModule, { TextFeatureModuleProps } from '.';
import { BlokComponent, StoryblokLink } from '../../../types/blok.model';
import { ensureTrailingSlash } from '../../../util/ensureTranilingSlash';
import { Paragraph } from '../../components/RichText/BlockRenderer';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

interface TextFeatureModuleBlokProps
  extends Omit<TextFeatureModuleProps, 'ctaLink'> {
  ctaLink: StoryblokLink;
  body: RichTextDocument;
}

const TextFeatureModuleBlok: BlokComponent<TextFeatureModuleBlokProps> = ({
  blok,
  Dynamic,
}) => {
  const link = ensureTrailingSlash(
    blok.ctaLink?.cached_url ?? blok.ctaLink?.url
  );
  const paragraph = blok.body.content[0] as Paragraph;
  const body = paragraph.content && (
    <StoryblokRichText content={blok.body} Dynamic={Dynamic} />
  );

  return (
    <SbEditable content={blok}>
      <TextFeatureModule {...blok} ctaLink={link} body={body} />
    </SbEditable>
  );
};

export default TextFeatureModuleBlok;
