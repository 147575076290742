import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import {
  BackgroundImage,
  ColorOverrides,
  Header,
  HeaderFooter,
  HeaderTitle,
  StyledRow,
  StyledSectorsList,
} from './CaseStudyHeader.styles';

import { WindowLocation } from '@gatsbyjs/reach-router';
import { ThemeProvider } from 'styled-components';
import withRouter from '../../../util/withRouter';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';

export interface SharedProps {
  title: string;
  tags: TagType[];
  categories: CategoryType[];
  hasDarkTheme: boolean;
  logoSize: 'sm' | 'md';
}

export type HeaderModuleProps = SharedProps &
  ColorOverrides<string> & {
    bgImage: string | IGatsbyImageData;
    hero_img_lg: string | IGatsbyImageData;
    logoImage: string | IGatsbyImageData;
    location: WindowLocation;
    hero_alt: string;
    logo_alt: string;
  };

const HeaderModule: FC<HeaderModuleProps> = ({
  title,
  tags,
  bgImage,
  hero_img_lg,
  location,
  logoImage,
  categories,
  hasDarkTheme,
  logoSize,
  hero_alt,
  logo_alt,
  ...colorOverrides
}) => {
  return (
    <ThemeProvider theme={hasDarkTheme ? defaultTheme : darkTheme}>
      <Header>
        {bgImage && (
          <BackgroundImage
            alt={hero_alt}
            mobile={bgImage}
            tablet={hero_img_lg ? hero_img_lg : bgImage}
            desktop={hero_img_lg ? hero_img_lg : bgImage}
            {...colorOverrides}
          />
        )}
        <Layout variant="smallWidth">
          <Grid>
            <StyledRow>
              <HeaderTitle>{title}</HeaderTitle>
            </StyledRow>
            <HeaderFooter>
              <StyledSectorsList tags={tags} categories={categories} asLinks />
            </HeaderFooter>
          </Grid>
        </Layout>
      </Header>
    </ThemeProvider>
  );
};

export default withRouter(HeaderModule);
