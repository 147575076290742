import React, { ReactNode, useContext } from 'react';

export type ContactFormDataType = {
  formKey: string;
  name: string;
  id: string;
};

interface ContactFormDataContextProviderProps {
  children: ReactNode;
  data?: ContactFormDataType[];
}

const ContactFormDataContext = React.createContext<
  ContactFormDataType[] | undefined
>(undefined);

export const ContactFormDataContextProvider = ({
  children,
  data,
}: ContactFormDataContextProviderProps): JSX.Element => {
  return (
    <ContactFormDataContext.Provider value={data}>
      {children}
    </ContactFormDataContext.Provider>
  );
};

export const useContactFormData = (formKey: string) => {
  const context = useContext(ContactFormDataContext);

  if (context === undefined) {
    console.error('Missing passed contact data form data from pageContext');
    return;
  }

  if (formKey) {
    return context.find((item) => item.name === formKey);
  }

  return context;
};
