import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { colors } from '../../../styles/themeVariables';
import { textSizes } from '../../../styles/typography';
import { Grid } from '../../layout/Grid';

export const Header = styled.header`
  position: relative;
  padding-top: 18.5rem;
  background-color: ${colors.snow};

  @media ${breakpointUp.sm} {
    padding-top: 19rem;
  }

  @media ${breakpointUp.md} {
    padding-top: 14.5rem;
  }

  @media ${breakpointUp.lg} {
    padding-top: 19.25rem;
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 1.5rem 0;

  @media ${breakpointUp.sm} {
    padding: 2rem 0;
  }

  @media ${breakpointUp.md} {
    padding: 3rem 0;
  }
`;

export const Title = styled.h1`
  ${textSizes.lg}
  margin: 0 0 1rem 0;
  letter-spacing: -0.02em;
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    ${textSizes.xl};
    grid-column: span 10;
  }

  @media ${breakpointUp.md} {
    ${textSizes.xxl};
    grid-column: 1 / span 5;
  }
`;

export const Description = styled.p`
  ${textSizes.sm};
  grid-column: span 2;
  margin-bottom: 0;

  @media ${breakpointUp.sm} {
    ${textSizes.md}
    grid-column: span 10;
  }

  @media ${breakpointUp.md} {
    ${textSizes.lg};
    grid-column: 1 / span 5;
  }
`;
