import { useStoryblokImageGetter } from '../old/storyblok/asset-context';
import { useReports } from '../old/storyblok/reports-context';
import { useCategories } from '../old/storyblok/categories-context';
import { useTags } from '../old/storyblok/tags-context';
import { AuthorType, useAuthors } from '../old/storyblok/authors-context';

export const useReportsThumbnails = (filterPosts?: string[]) => {
  const getStoryblokImage = useStoryblokImageGetter();

  const reportData = useReports();
  const allAuthors = useAuthors();
  const allTags = useTags();
  const allCategories = useCategories();

  return reportData.allPosts
    ?.filter((post) => {
      if (filterPosts) {
        return filterPosts.includes(post.uuid);
      }

      return true;
    })
    ?.map((post) => {
      const tags = allTags.filter((tag) => post.tags?.includes(tag.uuid));
      const categories = allCategories.filter((category) =>
        post.categories?.includes(category.uuid)
      );

      const authors: AuthorType[] | undefined = post.authors?.map(
        (uuid) => allAuthors.find((a) => a.uuid === uuid) as AuthorType
      );

      return {
        thumbnail_bg_sm: getStoryblokImage(post?.thumbnail_bg_sm?.filename),
        thumbnail_bg_lg: getStoryblokImage(post?.thumbnail_bg_lg?.filename),
        thumbnail_3x4: getStoryblokImage(post?.thumbnail_bg_sm?.filename),
        thumbnail_4x3: getStoryblokImage(post?.thumbnail_bg_lg?.filename),
        thumbnail_alt:
          post?.thumbnail_bg_sm?.alt || `Thumbnail image for ${post.title}`,
        cover_image: getStoryblokImage(post.cover_image?.filename),
        cover_alt: post.cover_image?.alt || `Cover image for ${post.title}`,
        authors,
        page_number: post.page_number,
        tags,
        categories,
        link: post.full_slug,
        title: post.title,
        description: post.description,
        id: post.uuid,
      };
    });
};
