import React from 'react';
import { createContext, ReactNode, useContext } from 'react';

export type AuthorType = {
  slug: string;
  name: string;
  description: string;
  avatar: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  email?: string;
  uuid?: string;
};

interface AuthorsContextShape extends Array<AuthorType> {}

interface AuthorsContextProviderProps {
  children: ReactNode;
  data?: AuthorType[];
}

const AuthorsContext = createContext<AuthorsContextShape | undefined>(
  undefined
);

export const AuthorsContextProvider = ({
  children,
  data,
}: AuthorsContextProviderProps): JSX.Element => {
  return (
    <AuthorsContext.Provider value={data}>{children}</AuthorsContext.Provider>
  );
};

export const useAuthors = () => {
  const authorsContext = useContext(AuthorsContext);
  if (authorsContext === undefined) {
    throw new Error('Missing passed allAuthors from pageContext');
  }

  return authorsContext;
};
