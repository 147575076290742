import { useStoryblokImageGetter } from '../old/storyblok/asset-context';
import { useCaseStudies } from '../old/storyblok/caseStudies-context';
import { useCategories } from '../old/storyblok/categories-context';
import { useTags } from '../old/storyblok/tags-context';

export const useCaseStudyThumbnails = (filterPosts?: string[]) => {
  const getStoryblokImage = useStoryblokImageGetter();

  const caseStudyData = useCaseStudies();
  const allTags = useTags();
  const allCategories = useCategories();

  return caseStudyData.allPosts
    ?.filter((post) => {
      if (filterPosts) {
        return filterPosts.includes(post.uuid);
      }

      return true;
    })
    ?.map((post) => {
      const tags = allTags.filter((tag) => post.tags?.includes(tag.uuid));
      const categories = allCategories.filter((category) =>
        post.categories?.includes(category.uuid)
      );

      return {
        client: post.client_name,
        logo: getStoryblokImage(post.client_logo?.filename),
        logoDark: getStoryblokImage(post.client_logo_dark?.filename),
        thumbnail_sm: getStoryblokImage(post.thumbnail_sm?.filename),
        thumbnail_lg: getStoryblokImage(post.thumbnail_lg?.filename),
        thumbnail_3x4: getStoryblokImage(post.thumbnail_3x4?.filename),
        thumbnail_4x3: getStoryblokImage(post.thumbnail_4x3?.filename),
        thumbnail_16x9: getStoryblokImage(post.thumbnail_16x9?.filename),
        thumbnail_alt:
          post.thumbnail_sm?.alt || `Thumbnail image for ${post.title}`,
        categories,
        tags,
        link: post.full_slug,
        title: post.title,
        id: post.uuid,
      };
    });
};
