import styled from 'styled-components';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';

export const Container = styled.div<{ variant: string }>`
  display: ${({ variant }) => (variant === 'masonry' ? 'block' : 'grid')};
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${spacing.space24};
  row-gap: ${spacing.space24};
  grid-column: 1 / -1;
  column-count: 1;
  margin: 0 -1rem;

  @media ${breakpointUp.tablet} {
    margin: 0;
    column-count: 2;
  }

  @media ${breakpointUp.desktop} {
    column-count: 3;
  }
`;

const Item = styled.div`
  video {
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: top;
    position: static;
  }

  video[poster] {
    object-fit: cover;
  }
`;

export const ItemSingle = styled(Item)`
  grid-column: 1 / -1;
`;

export const ItemDuo = styled(Item)`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: span 6;
  }
`;

export const ItemDuoOffsetRight = styled(Item)`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: span 8;

    &:first-child {
      grid-column: span 4;
    }
  }
`;

export const ItemDuoOffsetLeft = styled(Item)`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: span 4;

    &:first-child {
      grid-column: span 8;
    }
  }
`;

export const ItemTrio = styled(Item)`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: span 6;
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 4;
  }
`;

export const ItemMasonry = styled(Item)`
  break-inside: avoid;
  margin-bottom: ${spacing.space24};
`;
