import React from 'react';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import {
  Module,
  Logo,
  Title,
  Description,
  List,
} from './TextFeatureServices.styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export interface TextFeatureServicesProps {
  icon?: string | IGatsbyImageData;
  title?: string;
  description?: React.ReactNode;
  listPrimary?: React.ReactNode;
  listSecondary?: React.ReactNode;
  columnSpan?: number;
  rightAlign?: boolean;
  iconAlt?: string;
}

const TextFeatureServices: React.FC<TextFeatureServicesProps> = ({
  icon,
  title,
  description,
  listPrimary,
  listSecondary,
  columnSpan = 6,
  rightAlign = false,
  iconAlt = `logo for ${title}`,
}) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <Module rightAlign={rightAlign} columnSpan={columnSpan}>
            {icon && (
              <Logo
                mobile={icon}
                tablet={icon}
                desktop={icon}
                alt={iconAlt}
                loading="eager"
              />
            )}
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
            {listPrimary && <List>{listPrimary}</List>}
            {listSecondary && <List>{listSecondary}</List>}
          </Module>
        </Grid>
      </Layout>
    </Section>
  );
};

export default TextFeatureServices;
