import styled from 'styled-components';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${spacing.space16};
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${spacing.space16};
  }

  @media ${breakpointUp.desktop} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: ${spacing.space24};
  }
`;

export const TagsList = styled.div`
  display: flex;
  column-gap: ${spacing.space16};
  grid-column: 1 / -1;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;
