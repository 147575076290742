import React from 'react';
import SbEditable from 'storyblok-react';
import {
  BlokComponent,
  StoryblokAsset,
  StoryblokAuthor,
  StoryblokCategories,
  StoryblokTags,
} from '../../../types/blok.model';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import HeaderPillar from '.';
import { useCategories } from '../../../old/storyblok/categories-context';
import { useTags } from '../../../old/storyblok/tags-context';
import { useAuthors } from '../../../old/storyblok/authors-context';

export type HeaderPillarBlokSchema = {
  bgImage: StoryblokAsset;
  tags: StoryblokTags;
  categories: StoryblokCategories;
  author: StoryblokAuthor;
  title: string;
};

const HeaderPillarBlok: BlokComponent<HeaderPillarBlokSchema> = ({ blok }) => {
  const bgImage = useStoryblokImage(blok.bgImage?.filename);
  const categories = useCategories();
  const selectedCategories = categories.filter((category) =>
    blok.categories?.includes(category.uuid)
  );

  const tags = useTags();
  const selectedTags = tags.filter((tag) => blok.tags?.includes(tag.uuid));

  const authors = useAuthors();
  const selectedAuthor = authors.find((author) => author?.uuid === blok.author);

  return (
    <SbEditable content={blok}>
      <HeaderPillar
        title={blok.title}
        tags={selectedTags}
        categories={selectedCategories}
        bgImage={bgImage}
        author={selectedAuthor}
      />
    </SbEditable>
  );
};

export default HeaderPillarBlok;
