import * as React from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';

export type Props = GatsbyImageProps & {
  className?: string;
  removeBottomRoundCornersOnDesktop?: boolean;
  allowOversize?: boolean;
  placeholderColor?: string;
};

const StyledPicture = styled.picture<{
  reveal?: boolean;
  removeBottomRoundCornersOnDesktop?: boolean;
  placeholderColor: string;
}>`
  position: relative;
  width: 100%;
  font-size: 0; /* to prevent font spacing between images and psuedo elements */

  picture {
    border-radius: 0.1875rem;

    @media ${breakpointUp.md} {
      border-radius: ${({ removeBottomRoundCornersOnDesktop }) =>
        removeBottomRoundCornersOnDesktop
          ? '0.1875rem 0.1875rem 0 0'
          : '0.1875rem'};
    }
  }

  &::before {
    /* Image placeholder that will show if image is still being loaded */
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: ${({ placeholderColor }) => placeholderColor};
  }
`;

const PictureFluid: FC<Props> = ({
  className,
  placeholderColor,
  removeBottomRoundCornersOnDesktop,
  allowOversize,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleStartLoad = useCallback(() => {
    window.setTimeout(() => setLoaded(true), 2000);
  }, [setLoaded]);

  return (
    <StyledPicture
      className={className}
      placeholderColor={placeholderColor || 'transparent'}
      reveal={loaded}
      removeBottomRoundCornersOnDesktop={removeBottomRoundCornersOnDesktop}
      style={{ maxWidth: !allowOversize ? props.image.width / 2 : undefined }}
    >
      <GatsbyImage
        {...props}
        backgroundColor={placeholderColor || 'transparent'}
        style={{
          width: '100%',
          ...props.style,
        }}
        onStartLoad={handleStartLoad}
      />
    </StyledPicture>
  );
};

export default React.memo(PictureFluid);
