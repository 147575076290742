import styled from 'styled-components';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import {
  Track,
  Slide,
  CustomDot,
} from '../../blocks/SlideShowBlock/SlideShowBlock.styles';

export const QuoteSlideShow = styled.div`
  text-align: center;
  grid-column: 1 / -1;
  margin: 0 -1rem;

  @media ${breakpointUp.tablet} {
    grid-column: 2 / span 10;
    margin: 0;
  }

  @media ${breakpointUp.desktop} {
    grid-column: 3 / span 8;
  }

  @media ${breakpointUp.desktopLarge} {
    grid-column: 3 / span 8;
  }

  ${Track} {
    margin-bottom: ${spacing.space24};
    min-height: 11rem;
    align-content: center;

    @media ${breakpointUp.tablet} {
      grid-template-columns: repeat(10, 1fr);
      min-height: 13.5rem;
    }

    @media ${breakpointUp.desktop} {
      margin-top: ${spacing.space16};
      column-gap: ${spacing.space24};
      grid-template-columns: repeat(8, 1fr);
      min-height: 12.5rem;
    }

    @media ${breakpointUp.desktopLarge} {
      margin-top: ${spacing.space16};
      column-gap: ${spacing.space24};
      grid-template-columns: repeat(8, 1fr);
      min-height: 13.5rem;
    }
  }

  ${Slide} {
    padding: 0 1rem;

    @media ${breakpointUp.tablet} {
      padding: 0;
      grid-column: span 10;
    }

    @media ${breakpointUp.desktop} {
      grid-column: 2 / span 6;
    }

    @media ${breakpointUp.desktopLarge} {
      grid-column: span 8;
    }
  }

  ${CustomDot} {
    img {
      width: 9.375rem;
    }
  }
`;
