import React, { FC } from 'react';
import { ButtonProps } from '../../components/Button';
import LinkButton from '../../components/Button/LinkButton';

export type CallToACtionProps = ButtonProps & {
  link: string;
  title: string;
  bgColor: string;
  textColor: string;
};

const CallToAction: FC<CallToACtionProps> = ({
  link,
  btnsize,
  variant,
  title,
  bgColor,
  textColor,
}) => {
  return (
    <LinkButton
      btnsize={btnsize}
      variant={variant}
      href={link}
      bgColor={bgColor}
      textColor={textColor}
      target="_blank"
    >
      {title}
    </LinkButton>
  );
};

export default CallToAction;
