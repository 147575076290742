import React from 'react';
import SbEditable from 'storyblok-react';

import QuoteModule, { CaseStudyQuoteProps, SharedProps } from '.';
import { StoryblokColor, BlokComponent } from '../../../types/blok.model';
import { Colors } from '../../components/SectionWrapper';

type CaseStudyQuoteBlokSchema = SharedProps & Colors<StoryblokColor>;

const CaseStudyQuoteBlok: BlokComponent<CaseStudyQuoteBlokSchema> = ({
  blok,
}) => {
  const props = {
    ...blok,
    bg1: blok.bg1?.color,
    fg1: blok.fg1?.color,
    fg2: blok.fg2?.color,
  } as CaseStudyQuoteProps;

  return (
    <SbEditable content={blok}>
      <QuoteModule {...props} />
    </SbEditable>
  );
};

export default CaseStudyQuoteBlok;
