import React, { FC, useCallback, useState } from 'react';
import { CategoryType } from '../../../../old/storyblok/categories-context';
import { TagType } from '../../../../old/storyblok/tags-context';
import FilterDropdown from './FilterDropDown';
import {
  StyledButton,
  StyledResetFilterButton,
  StyledToggleWrapper,
  StyledGrid,
} from './InlineFilterMenu.styles';
import OpenFilterList from './OpenFilterList';
import { Layout } from '../../../layout/Layout';
import { Section } from '../../../layout/Section';
import { LabelButton } from '../../../components/Buttons/Label';

export type InlineFilterProps = {
  categoryList: CategoryType[];
  tagList: TagType[];
  label?: string;
  resetFilterUrl?: string;
};

const InlineFilterMenu: FC<InlineFilterProps> = ({
  categoryList,
  tagList,
  label,
  resetFilterUrl,
}) => {
  const [listOpen, setListOpen] = useState(false);

  const handleToggle = useCallback(
    () => setListOpen((open) => !open),
    [setListOpen]
  );

  return (
    <Section>
      <Layout>
        <StyledGrid>
          <StyledToggleWrapper>
            <StyledButton
              size={'md'}
              type="button"
              disabled={false}
              onClick={handleToggle}
            >
              <FilterDropdown listOpen={listOpen} label={label} />
            </StyledButton>
            {resetFilterUrl && (
              <StyledResetFilterButton to={resetFilterUrl} filterUrl={false}>
                <LabelButton>Show all</LabelButton>
              </StyledResetFilterButton>
            )}
          </StyledToggleWrapper>
          {listOpen && (
            <OpenFilterList
              categories={categoryList}
              tags={tagList}
              toggleList={handleToggle}
            />
          )}
        </StyledGrid>
      </Layout>
    </Section>
  );
};

export default InlineFilterMenu;
