import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent } from '../../../types/blok.model';
import PageHeaderModule, { PageHeaderProps } from '.';

export type PageHeaderBlokSchema = PageHeaderProps;

const PageHeaderBlok: BlokComponent<PageHeaderBlokSchema> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <PageHeaderModule {...blok} />
    </SbEditable>
  );
};

export default PageHeaderBlok;
