import React, { FC, useRef } from 'react';
import { useSlideshow } from '../../hooks';
import { useViewport } from '../../../util/useViewport';
import {
  SlideShow,
  Track,
  Slide,
  Nav,
  NavItem,
  CustomDot,
} from './SlideShowBlock.styles';
import { AnimatePresence } from 'framer-motion';

export interface SlideShowProps {
  children?: React.ReactNode;
  customNav?: React.ReactNode[];
  delay?: number;
  animationDuration?: number;
  animationDurationMobile?: number;
}

const defaultIntervalMilliseconds = 3000;

const SlideShowBlock: FC<SlideShowProps> = ({
  children,
  customNav,
  delay = defaultIntervalMilliseconds,
  animationDuration = 0.2,
  animationDurationMobile = 0.5,
}) => {
  const { isMobile } = useViewport();
  const slideCount = React.Children.count(children);
  const slideShowRef = useRef<HTMLDivElement>(null);
  const { currentSlide, setCurrentSlide } = useSlideshow(
    slideCount,
    delay,
    slideShowRef
  );

  return (
    <SlideShow>
      <Track ref={slideShowRef}>
        <AnimatePresence exitBeforeEnter>
          {React.Children.map(children, (child, index) => {
            if (isMobile) {
              return (
                <Slide
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: index === currentSlide ? 1 : 0 }}
                  transition={{
                    duration: animationDurationMobile,
                    ease: 'easeInOut',
                  }}
                >
                  {child}
                </Slide>
              );
            }

            return (
              index === currentSlide && (
                <Slide
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: index === currentSlide ? 1 : 0 }}
                  exit={{ opacity: index === currentSlide ? 0 : 1 }}
                  transition={{ duration: animationDuration, ease: 'easeOut' }}
                >
                  {child}
                </Slide>
              )
            );
          })}
        </AnimatePresence>
      </Track>

      {slideCount > 1 && (
        <Nav>
          {React.Children.map(children, (_, index) => (
            <NavItem
              key={index}
              active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
              customNav={!!customNav?.[index]}
              tabIndex={0}
              role="button"
            >
              {!!customNav?.[index] && (
                <CustomDot>{customNav[index]}</CustomDot>
              )}
            </NavItem>
          ))}
        </Nav>
      )}
    </SlideShow>
  );
};

export default SlideShowBlock;
