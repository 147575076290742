import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import { Grid } from '../../layout/Grid';

export const StylContentWrapper = styled(Grid)`
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media ${breakpointUp.sm} {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  @media ${breakpointUp.md} {
    align-items: top;
  }

  @media ${breakpointUp.lg} {
    padding-bottom: 7rem;
    padding-top: 7rem;
  }
`;

export const StylTextBox = styled.div`
  grid-column: span 12;
  padding-bottom: 3rem;

  @media ${breakpointUp.sm} {
    grid-column: span 10;
  }

  @media ${breakpointUp.sm} {
    grid-column: span 10;
  }

  @media ${breakpointUp.lg} {
    grid-column: 2 / span 5;
  }

  h3 {
    text-transform: uppercase;
    ${textSizes.xxs};
    font-weight: 700;
    letter-spacing: 0.121875rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.text1};
  }

  h2 {
    color: ${({ theme }) => theme.text1};
    ${textSizes.lg};
    margin-bottom: 1.5rem;

    @media ${breakpointUp.sm} {
      ${textSizes.xl};
    }

    @media ${breakpointUp.lg} {
      ${textSizes.xxl};
      margin-bottom: 2rem;
    }
  }

  p {
    ${textSizes.xs};
    color: ${({ theme }) => theme.text2};
    margin-bottom: 0;

    @media ${breakpointUp.sm} {
      ${textSizes.sm};
    }
  }
`;
