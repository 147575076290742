import React from 'react';
import SbEditable from 'storyblok-react';
import QuoteSlideShowModule, { QuoteSlideShowProps } from '.';
import QuoteSlideBlock, { QuoteSlideProps } from '../../blocks/QuoteSlideBlock';
import { BlokComponent } from '../../../types/blok.model';
import { ResponsiveImage } from '../../components/ResponsiveImage';

export interface SlideProps extends QuoteSlideProps {
  _uid: string;
  component: string;
}

export interface SlideShowModuleBlokProps extends QuoteSlideShowProps {
  slides?: SlideProps[];
  navImages?: {
    title?: string;
    alt: string;
    filename: string;
  }[];
}

const DEFAULT_DELAY = 3000;

const SlideShowModuleBlok: BlokComponent<SlideShowModuleBlokProps> = ({
  blok,
}) => {
  const delayInMilliseconds = blok.delay ? blok.delay * 1000 : DEFAULT_DELAY;

  return (
    <SbEditable content={blok}>
      <QuoteSlideShowModule
        {...blok}
        delay={delayInMilliseconds}
        customNav={blok.navImages?.map((image) => (
          <ResponsiveImage
            key={image.filename}
            {...image}
            desktop={image.filename}
            tablet={image.filename}
            mobile={image.filename}
          />
        ))}
      >
        {blok.slides?.map(
          (slide) =>
            slide.component === 'slideQuoteBlock' && (
              <QuoteSlideBlock {...slide} key={slide._uid} />
            )
        )}
      </QuoteSlideShowModule>
    </SbEditable>
  );
};

export default SlideShowModuleBlok;
