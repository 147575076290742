import React, { FC } from 'react';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { Container, Stat, Value, Name } from './StatsModule.styles';

export interface StatsModuleProps {
  stats: {
    _uid: string;
    name: string;
    value: string;
  }[];
}

const StatsModule: FC<StatsModuleProps> = ({ stats }) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <Container>
            {stats.map(({ name, value, _uid }) => (
              <Stat key={_uid}>
                <Value>{value}</Value>
                <Name>{name}</Name>
              </Stat>
            ))}
          </Container>
        </Grid>
      </Layout>
    </Section>
  );
};

export default StatsModule;
