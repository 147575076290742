import React from 'react';
import SbEditable from 'storyblok-react';
import HeaderModule, { SharedProps } from '.';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import {
  StoryblokColor,
  StoryblokAsset,
  StoryblokCategories,
  StoryblokTags,
  BlokComponent,
} from '../../../types/blok.model';
import { useCategories } from '../../../old/storyblok/categories-context';
import { useTags } from '../../../old/storyblok/tags-context';
import { ColorOverrides } from './CaseStudyHeader.styles';

type HeaderModuleBlokSchema = SharedProps &
  ColorOverrides<StoryblokColor> & {
    bgImage: StoryblokAsset;
    hero_img_lg: StoryblokAsset;
    logoImage: StoryblokAsset;
    categories: StoryblokCategories;
    tags: StoryblokTags;
    dark_theme: boolean;
    client_name: string;
  };

const HeaderModuleBlok: BlokComponent<HeaderModuleBlokSchema> = ({ blok }) => {
  const categories = useCategories();
  const selectedCategories = categories.filter((category) =>
    blok.categories?.includes(category.uuid)
  );
  const tags = useTags();
  const selectedTags = tags.filter((tag) => blok.tags.includes(tag.uuid));
  const bgImage = useStoryblokImage(blok.bgImage.filename);
  const heroImgLg = useStoryblokImage(blok.hero_img_lg?.filename);
  const logoImage = useStoryblokImage(blok.logoImage.filename);
  const props = {
    title: blok.title,
    tags: selectedTags,
    bgImage,
    hero_img_lg: heroImgLg,
    hero_alt: blok.bgImage?.alt || `Hero image for ${blok.title}`,
    logoImage,
    logoSize: blok.logoSize,
    logo_alt: blok.logoImage?.alt || `Logo of ${blok.client_name}`,
    bgGradient: blok.bgGradient?.color,
    categories: selectedCategories,
    hasDarkTheme: blok.dark_theme,
  };

  return (
    <SbEditable content={blok}>
      <HeaderModule {...props} />
    </SbEditable>
  );
};

export default HeaderModuleBlok;
