import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import {
  GallerySectionWrapper,
  GallerySection,
  GalleryWrapper,
  ImageType,
  StyledImage,
} from '../Gallery.styles';
import { Layout } from '../../../layout/Layout';

export type SharedProps = {
  type: ImageType;
  bottomPadding: boolean;
  topPadding: boolean;
  image1Alt: string;
  image2Alt: string;
  image3Alt: string;
};

export type GalleryModuleTrioProps = SharedProps & {
  bg: string;
  image1: string | IGatsbyImageData;
  image2: string | IGatsbyImageData;
  image3: string | IGatsbyImageData;
};

const TrioGallery = styled(GalleryWrapper)`
  @media ${breakpointUp.sm} {
    grid-template-columns: 4fr 4fr 4fr;
  }
`;

const GalleryModuleTrio: FC<GalleryModuleTrioProps> = ({
  bg,
  type,
  bottomPadding,
  image1,
  image2,
  image3,
  image1Alt,
  image2Alt,
  image3Alt,
  topPadding,
}) => {
  return (
    <GallerySectionWrapper bg1={bg}>
      <Layout variant={type !== 'fullBleed' ? 'smallWidth' : undefined}>
        <GallerySection
          type={type}
          bg={bg}
          bottomPadding={bottomPadding}
          topPadding={topPadding}
        >
          <TrioGallery type={type}>
            <StyledImage alt={image1Alt} image={image1} />
            <StyledImage alt={image2Alt} image={image2} />
            <StyledImage alt={image3Alt} image={image3} />
          </TrioGallery>
        </GallerySection>
      </Layout>
    </GallerySectionWrapper>
  );
};

export default GalleryModuleTrio;
