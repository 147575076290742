import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import Stats, { SharedProps, ListComponentProps } from '.';
import { ColorOverrides } from './ListBlock.styles';

export type ListBlockSchema = SharedProps &
  ColorOverrides<StoryblokColor> & {
    list: string;
  };

const ListBlock: BlokComponent<ListBlockSchema> = ({ blok }) => {
  const list = blok.list.split('\n');

  const props = {
    ...blok,
    markerColor: blok.markerColor?.color,
    textColor: blok.textColor?.color,
    titleColor: blok.titleColor?.color,
    list,
  } as ListComponentProps;

  return (
    <SbEditable content={blok}>
      <Stats {...props} />
    </SbEditable>
  );
};

export default ListBlock;
