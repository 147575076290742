import React, { FC } from 'react';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import StoryblokAssetImage from '../StoryblokAssetImage';

const ImageRenderer: FC<{ image: string; alt: string }> = ({ image, alt }) => {
  const src = useStoryblokImage(image);
  return <StoryblokAssetImage image={src} alt={alt} />;
};

export default ImageRenderer;
