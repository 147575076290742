import React from 'react';
import SbEditable from 'storyblok-react';
import NavLink from './NavLink';
import { StoryblokLinkObject } from '../../../old/storyblok/util/resolveInternalLink';
import { BlokComponent } from '../../../types/blok.model';

type StoryblokNavLinkSchema = {
  _uid: string;
  name: string;
  link: StoryblokLinkObject;
};

const NavLinkBlok: BlokComponent<StoryblokNavLinkSchema> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <NavLink {...blok} />
    </SbEditable>
  );
};

export default NavLinkBlok;
