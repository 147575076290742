import React from 'react';
import SbEditable from 'storyblok-react';
import GalleryModuleSingle, { SharedProps } from '.';
import { useStoryblokImage } from '../../../../old/storyblok/asset-context';
import {
  StoryblokAsset,
  BlokComponent,
  StoryblokColor,
} from '../../../../types/blok.model';

type GalleryModuleSingleBlokSchema = SharedProps & {
  bg1: StoryblokColor;
  image: StoryblokAsset;
  mobImage: StoryblokAsset;
};

const GalleryModuleSingleBlok: BlokComponent<GalleryModuleSingleBlokSchema> = ({
  blok,
}) => {
  const image = useStoryblokImage(blok.image.filename);
  const mobImage = useStoryblokImage(blok.mobImage?.filename);

  const props = {
    ...blok,
    bg: blok.bg1?.color || '',
    image,
    mobImage,
  };

  return (
    <SbEditable content={blok}>
      <GalleryModuleSingle {...props} />
    </SbEditable>
  );
};

export default GalleryModuleSingleBlok;
