import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';

export const HideOnDesktop = styled.div`
  display: block;

  @media ${breakpointUp.desktop} {
    display: none;
  }
`;

export const ShowOnDesktop = styled.div`
  display: none;

  @media ${breakpointUp.desktop} {
    display: block;
  }
`;
