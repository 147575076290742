import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { textSizes } from '../../../styles/typography';

export type ComponentProps = {
  text: ReactNode;
};

export const Content = styled.span`
  ${textSizes.xs};
`;

const FootNoteBlock: FC<ComponentProps> = ({ text }) => {
  return <Content>{text}</Content>;
};

export default FootNoteBlock;
