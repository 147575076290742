import React from 'react';
import SbEditable from 'storyblok-react';

import { BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';
import FootNoteBlockComponent from '.';

type StoryblokTextSchema = {
  content: RichTextDocument;
};

const FootNoteBlock: BlokComponent<StoryblokTextSchema> = ({
  blok,
  Dynamic,
}) => {
  return (
    <SbEditable content={blok}>
      <FootNoteBlockComponent
        text={<StoryblokRichText content={blok.content} Dynamic={Dynamic} />}
      />
    </SbEditable>
  );
};

export default FootNoteBlock;
