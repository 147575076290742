import ReactGA from 'react-ga';
import { ContactFormDataType } from './useContactFormData';

export default function postStoryblokForm(
  formMeta: ContactFormDataType,
  endpoint: string,
  body: Record<string, string | number | undefined>
): Promise<void> {
  return new Promise((resolve, reject) => {
    const query = new URLSearchParams({
      hubspotForm: formMeta.formKey,
    });

    fetch(`/api/${endpoint}?${query}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(
      (response) => {
        if (response.status >= 300) {
          throw new Error();
        }
        ReactGA.event({
          category: 'Form',
          action: `${formMeta.name}`,
          transport: 'beacon',
        });
        resolve();
      },
      () => {
        ReactGA.event({
          category: 'Form errors',
          action: `${formMeta.name}`,
          transport: 'beacon',
        });

        alert(
          'There was a problem sending your message. Please try emailing us at hello@elsewhen.com'
        );
        reject();
      }
    );
  });
}
