import React, { FC } from 'react';
import BlogPostThumbnail from '../../blocks/BlogPostThumbnail/BlogPostThumbnail.blok';
import { StyledRow } from './FeaturedBlogRow.styles';
import { BlogThumbnailProps } from '../../blocks/BlogPostThumbnail';

export type FeaturedBlogRowProps = {
  className?: string;
  posts: BlogThumbnailProps[] | undefined;
};

const FeaturedBlogRow: FC<FeaturedBlogRowProps> = ({ posts, className }) => {
  return (
    <StyledRow className={className}>
      {posts?.map((post) => (
        <BlogPostThumbnail
          key={post.title}
          featured={true}
          link={post.link}
          thumbnail_sm={post.thumbnail_sm}
          thumbnail_lg={post.thumbnail_lg}
          thumbnail_alt={post.thumbnail_alt}
          title={post.title}
          description={post.description}
          tags={post.tags}
          categories={post.categories}
        />
      ))}
    </StyledRow>
  );
};

export default FeaturedBlogRow;
