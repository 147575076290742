import styled from 'styled-components';
import { TextFeatureServicesProps } from '.';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { typeScale } from '../../../styles/newTypography';
import { ResponsiveImage } from '../../components/ResponsiveImage';

type ModuleProps = Pick<TextFeatureServicesProps, 'rightAlign' | 'columnSpan'>;

export const Module = styled.div<ModuleProps>`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${({ theme }) => theme.space16};

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
  }

  @media ${breakpointUp.desktop} {
    grid-column-gap: ${({ theme }) => theme.space24};
    grid-template-columns: ${({ columnSpan }) => `repeat(${columnSpan}, 1fr)`};
    grid-column: ${({ rightAlign, columnSpan }) =>
      rightAlign ? `span ${columnSpan} / -1` : `1 / span ${columnSpan}`};
  }
`;

export const Logo = styled(ResponsiveImage)`
  grid-column: 1 / -1;
  margin-bottom: ${spacing.space16};
  height: ${spacing.space24};
  width: ${spacing.space24};

  img {
    width: auto;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xxl};
  margin-bottom: ${spacing.space48};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xxl};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxxl};
    margin-bottom: ${spacing.space48};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxxl};
    margin-bottom: ${spacing.space48};
  }
`;

export const Description = styled.div`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xs};
  margin-bottom: ${spacing.space48};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.md};
    margin-bottom: ${spacing.space40};
    grid-column: 1 / -4;
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md};
    margin-bottom: ${spacing.space56};
    grid-column: 1 / -2;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg};
    margin-bottom: ${spacing.space56};
    grid-column: 1 / -3;
  }
`;

export const List = styled.div`
  grid-column: span 2;
  ${typeScale.mobile.body.xs};
  margin-bottom: ${spacing.space48};
  color: ${({ theme }) => theme.text500};

  ul {
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: ${spacing.space8};
  }

  li {
    display: flex;
    align-items: center;
    column-gap: ${spacing.space18};
  }

  li::marker {
    font-size: 0;
  }

  li::before {
    content: '';
    width: 1.25rem;
    min-width: 1.25rem;
    max-width: 1.25rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.border200};
    display: block;
  }

  li > p {
    margin: 0;
  }

  @media ${breakpointUp.tablet} {
    padding-right: ${spacing.space24};
    ${typeScale.tablet.body.md};
    margin-bottom: ${spacing.space64};
    grid-column: span 6;

    ul {
      row-gap: ${spacing.space12};
    }
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md};
    margin-bottom: ${spacing.space64};
    grid-column: span 3;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.md};
    margin-bottom: ${spacing.space64};
    grid-column: span 3;
  }
`;
