import React, { FC, useCallback, useState } from 'react';

import DropdownButton from './DropdownButton';
import { ArticleLinksSchema } from '../../../../old/storyblok/util/getArticleLinks';
import { ArticleLinks } from '../ArticleLinks';

export interface ArticleLinksMobileProps {
  links: ArticleLinksSchema;
  placeHolder?: string;
}
const ArticleLinksMobile: FC<ArticleLinksMobileProps> = ({
  links,
  placeHolder,
}) => {
  const [listOpen, setListOpen] = useState(false);

  const handleToggle = useCallback(
    () => setListOpen((open) => !open),
    [setListOpen]
  );

  return (
    <>
      <DropdownButton
        listOpen={listOpen}
        toggleList={handleToggle}
        placeHolder={placeHolder}
      />
      {listOpen && <ArticleLinks links={links} toggleList={handleToggle} />}
    </>
  );
};

export default ArticleLinksMobile;
