import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent } from '../../../types/blok.model';
import CollectionHeaderModule, { CollectionHeaderProps } from '.';

export type CollectionHeaderBlokSchema = CollectionHeaderProps;

const CollectionHeaderBlok: BlokComponent<CollectionHeaderBlokSchema> = ({
  blok,
}) => {
  return (
    <SbEditable content={blok}>
      <CollectionHeaderModule
        title={blok.title}
        description={blok.description}
      />
    </SbEditable>
  );
};

export default CollectionHeaderBlok;
