import React, { FC } from 'react';
import {
  FilterWrapper,
  LabelHolder,
  ToggleArrow,
} from './InlineFilterMenu.styles';

export type FilterDropdownProps = {
  listOpen?: boolean;
  label?: string;
  className?: string;
};

const FilterDropdown: FC<FilterDropdownProps> = ({
  listOpen,
  label,
  className,
}) => {
  return (
    <FilterWrapper>
      <LabelHolder className={className}>{label}</LabelHolder>
      <ToggleArrow listOpen={listOpen} />
    </FilterWrapper>
  );
};

export default FilterDropdown;
