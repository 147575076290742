import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import {
  TagsSection,
  Description,
  ImgContainer,
  TextWrapper,
  ThumbnailWrapper,
  Title,
} from './BlogPostThumbnail.styles';

export type BlogThumbnailProps = {
  link: string;
  thumbnail_sm: string | IGatsbyImageData;
  thumbnail_lg: string | IGatsbyImageData;
  thumbnail_alt: string;
  title: string;
  description: string;
  tags: TagType[];
  categories: CategoryType[];
  featured?: boolean;
};

const BlogPostThumbnail: FC<BlogThumbnailProps> = ({
  link,
  thumbnail_sm,
  thumbnail_lg,
  title,
  description,
  tags,
  categories,
  thumbnail_alt,
  featured,
}) => {
  return (
    <ThumbnailWrapper>
      <TextWrapper to={link}>
        <ImgContainer
          alt={thumbnail_alt}
          mobile={thumbnail_sm}
          tablet={thumbnail_lg}
          desktop={thumbnail_lg}
          featured={featured}
        />
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
      </TextWrapper>
      <TagsSection tags={tags} asLinks categories={categories} />
    </ThumbnailWrapper>
  );
};

export default BlogPostThumbnail;
