import styled from 'styled-components';
import { makeArrow, unstyleButton } from '../../../../styles/miscUtils';
import { textSizes } from '../../../../styles/typography';

export const StyledButton = styled.button`
  ${unstyleButton()}
  width: 100%;
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  text-align: left;
  ${textSizes.xs}
  color: var(--fg1, ${({ theme }) => theme.greyMedium});
  background-color: var(--bg1, ${({ theme }) => theme.white});
  padding: 0.75rem 0.5rem 0.75rem 1.5rem;
`;

export const ToggleArrow = styled.span<{
  listOpen?: boolean;
}>`
  padding-right: 1.5rem;
  transform-origin: center;
  transform: rotateX(${({ listOpen }) => (listOpen ? '180deg' : '0deg')});

  &::after {
    ${({ theme }) => makeArrow('up', theme.fg, '0.625rem')};
  }
`;
