import React from 'react';
import SlideShowBlock, { SlideShowProps } from '../../blocks/SlideShowBlock';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import {
  ResponsiveVideo,
  VideoAssetProps,
} from '../../components/ResponsiveVideo';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { VideoSlideShow } from './VideoSlideShowModule.styles';

export interface VideoSlideShowProps extends SlideShowProps {
  items: {
    type: string;
    id?: string;
    sources: {
      mobile: VideoAssetProps;
      tablet: VideoAssetProps;
      desktop: VideoAssetProps;
    };
  }[];
}

const VideoSlideShowModule: React.FC<VideoSlideShowProps> = ({
  items,
  ...rest
}) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <VideoSlideShow>
            <SlideShowBlock
              {...rest}
              animationDuration={0}
              animationDurationMobile={0}
            >
              {items.map(({ type, sources, id }, index) => (
                <React.Fragment key={id ?? index}>
                  {type === 'videoAsset' ? (
                    <ResponsiveVideo
                      mobile={sources.mobile}
                      tablet={sources.tablet}
                      desktop={sources.desktop}
                      loading="eager"
                    />
                  ) : (
                    <ResponsiveImage
                      alt={
                        sources.mobile.alt ??
                        sources.tablet.alt ??
                        sources.desktop.alt ??
                        ''
                      }
                      mobile={sources.mobile.filename}
                      tablet={sources.tablet.filename}
                      desktop={sources.desktop.filename}
                      title={
                        sources.mobile.title ??
                        sources.tablet.title ??
                        sources.desktop.title
                      }
                      objectFit="contain"
                    />
                  )}
                </React.Fragment>
              ))}
            </SlideShowBlock>
          </VideoSlideShow>
        </Grid>
      </Layout>
    </Section>
  );
};

export default VideoSlideShowModule;
