import styled, { css } from 'styled-components';
import { textSizes } from '../../../styles/typography';

export interface ColorOverrides<T> {
  figureColor: T;
  metricColor: T;
}

export interface SharedProps {
  type: 'grid' | 'vertical';
  size: 'sm' | 'md' | 'lg';
}

export const StatFigure = styled.dt`
  ${textSizes.xl}
`;

export const StatDescription = styled.dd`
  ${textSizes.sm}
  margin: 0;
  color: var(--fg3, ${({ theme }) => theme.fgFaded});
`;

export const StatsList = styled.dl<SharedProps & ColorOverrides<string>>`
  ${({ type, size, figureColor, metricColor }) => css`
    margin: 0;
    display: grid;
    row-gap: 2rem;

    ${StatFigure} {
      color: ${figureColor};
    }
    ${StatDescription} {
      color: ${metricColor};
    }

    ${type === 'grid' &&
    css`
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      row-gap: 1.5rem;
      min-width: min(100%, 14.5rem);
    `}

    /* md styles */
  ${size === 'md' &&
    css`
      ${StatFigure} {
        ${textSizes.xxl}
      }

      ${type === 'grid' &&
      css`
        min-width: min(100%, 24rem);
      `}
    `}

  /* lg styles */
  ${size === 'lg' &&
    css`
      ${StatFigure} {
        ${textSizes.xxxxl}
      }

      ${type === 'grid' &&
      css`
        min-width: max(100%, 24rem);
      `}
    `}
  `}
`;
