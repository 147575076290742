import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';

export const StyledRow = styled(Grid)`
  row-gap: 2rem;
  padding-top: 1.5rem;

  > * {
    grid-column: span 12;

    @media ${breakpointUp.md} {
      grid-column: span 6;
    }
  }

  @media ${breakpointUp.sm} {
    row-gap: 3rem;
    padding-top: 2rem;
  }

  @media ${breakpointUp.md} {
    padding-top: 3rem;
  }
`;
