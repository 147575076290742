import React from 'react';
import SbEditable from 'storyblok-react';
import VideoSlideShowModule, { VideoSlideShowProps } from '.';
import {
  BlokComponent,
  ImageAsset,
  VideoAsset,
} from '../../../types/blok.model';

interface VideoSlideShowModuleBlokProps extends VideoSlideShowProps {
  assets: (ImageAsset | VideoAsset)[];
}

const videoAssetSource = (asset: VideoAsset) => ({
  mobile: {
    ...asset.mobile,
    poster: asset.mobilePoster.filename,
  },
  tablet: {
    ...asset.tablet,
    poster: asset.tabletPoster.filename,
  },
  desktop: {
    ...asset.desktop,
    poster: asset.desktopPoster.filename,
  },
});

const imageAssetSource = (asset: ImageAsset) => ({
  mobile: asset.mobile,
  tablet: asset.tablet,
  desktop: asset.desktop,
});

const DEFAULT_DELAY = 3000;

const VideoSlideShowModuleBlok: BlokComponent<
  VideoSlideShowModuleBlokProps
> = ({ blok }) => {
  const delayInMilliseconds = blok.delay ? blok.delay * 1000 : DEFAULT_DELAY;

  const items: VideoSlideShowProps['items'] = blok.assets.map((asset) => {
    const sources =
      asset.component === 'videoAsset'
        ? videoAssetSource(asset)
        : imageAssetSource(asset);

    return {
      id: asset._uid,
      type: asset.component,
      sources,
    };
  });

  return (
    <SbEditable content={blok}>
      <VideoSlideShowModule items={items} delay={delayInMilliseconds} />
    </SbEditable>
  );
};

export default VideoSlideShowModuleBlok;
