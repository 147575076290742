import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { ResponsiveImage } from '../../components/ResponsiveImage';

export const StyledImage = styled(ResponsiveImage)`
  width: 100%;
  height: 23.5rem;

  @media ${breakpointUp.sm} {
    height: 27rem;

    img {
      height: 27rem;
    }
  }

  @media ${breakpointUp.lg} {
    height: 35.5rem;

    img {
      height: 35.5rem;
    }
  }

  & > div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
