import React from 'react';
import SbEditable from 'storyblok-react';
import StatsModule, { StatsModuleProps } from '.';
import { BlokComponent } from '../../../types/blok.model';

interface StatsBlokProps extends StatsModuleProps {}

const StatsModuleBlok: BlokComponent<StatsBlokProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <StatsModule {...blok} />
    </SbEditable>
  );
};

export default StatsModuleBlok;
