import React, { FC } from 'react';
import { StyledLink, StyledLists } from './ArticleLinks.style';
import { ArticleLinksSchema } from '../../../../old/storyblok/util/getArticleLinks';

export interface ArticleListProps {
  links: ArticleLinksSchema;
  toggleList?: () => unknown;
}

export const ArticleLinks: FC<ArticleListProps> = ({ links, toggleList }) => {
  return (
    <StyledLists>
      {links.map(
        (item) =>
          item && (
            <li key={item.link}>
              <StyledLink
                href={item.link}
                onClick={() => {
                  if (toggleList) {
                    toggleList();
                  }
                }}
              >
                <span>{item.title}</span>
              </StyledLink>
            </li>
          )
      )}
    </StyledLists>
  );
};
