import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import { Layout } from '../../../layout/Layout';
import {
  GallerySectionWrapper,
  GallerySection,
  GalleryWrapper,
  ImageType,
  StyledImage,
  StyledMobImage,
} from '../Gallery.styles';

export type SharedProps = {
  type: ImageType;
  bottomPadding: boolean;
  topPadding: boolean;
  imageAlt: string;
  mobImageAlt: string;
  showOnMobile: boolean;
};

const SingleGalleryWrapper = styled(GalleryWrapper)<{ type: ImageType }>`
  height: 100%;
  max-height: 100%;

  @media ${breakpointUp.sm()} {
    height: 100%;
    display: grid;

    div {
      height: 100%;
      position: relative;
    }
  }
`;

export type GalleryModuleSingleProps = SharedProps & {
  bg: string;
  image: string | IGatsbyImageData;
  mobImage?: string | IGatsbyImageData;
};

const GalleryModuleSingle: FC<GalleryModuleSingleProps> = ({
  bg,
  type,
  bottomPadding,
  image,
  imageAlt,
  topPadding,
  mobImageAlt,
  mobImage,
}) => {
  return (
    <GallerySectionWrapper bg1={bg}>
      <Layout variant={type !== 'fullBleed' ? 'smallWidth' : undefined}>
        <GallerySection
          bg={bg}
          bottomPadding={bottomPadding}
          topPadding={topPadding}
          type={type}
        >
          <SingleGalleryWrapper type={type}>
            {mobImage && (
              <StyledMobImage
                alt={mobImageAlt}
                image={mobImage}
                showOnMobile={true}
              />
            )}
            <StyledImage
              alt={imageAlt}
              image={image}
              showOnMobile={!mobImage}
            />
          </SingleGalleryWrapper>
        </GallerySection>
      </Layout>
    </GallerySectionWrapper>
  );
};

export default GalleryModuleSingle;
