import React from 'react';
import SbEditable from 'storyblok-react';

import TextBlockComponent, { SharedProps, ColorOverrides } from '.';
import { StoryblokColor, BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

type StoryblokTextSchema = SharedProps &
  ColorOverrides<StoryblokColor> & {
    text: RichTextDocument;
  };

const TextBlok: BlokComponent<StoryblokTextSchema> = ({ blok, Dynamic }) => {
  return (
    <SbEditable content={blok}>
      <TextBlockComponent
        text={<StoryblokRichText content={blok.text} Dynamic={Dynamic} />}
        type={blok.type}
        textColor={blok.textColor?.color}
      />
    </SbEditable>
  );
};

export default TextBlok;
