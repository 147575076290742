import styled from 'styled-components';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import CMSLink from '../../components/CMSLink';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { ListItem } from '../../modules/CardList/CardList.styles';
import { TagsContainer, TagsList } from '../TagsBlock/TagsBlock.styles';

export const ImageWrapper = styled.div`
  position: relative;
  grid-column: 1 / -1;
`;

export const CoverImage = styled(ResponsiveImage)`
  width: auto;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1.4142;
  height: 73%;
  box-shadow: 0 ${spacing.space8} ${spacing.space64} 0 rgba(0, 0, 0, 0.2);

  @media ${breakpointUp.tablet} {
    height: 80.5%;
  }

  img {
    object-fit: cover;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0 0.40625rem 1.646875rem);
    height: 100%;
  }
`;

export const Image = styled(ResponsiveImage)<{
  $aspectMobile?: string;
  $aspectTablet?: string;
  $aspectDesktop?: string;
  $aspectDesktopLarge?: string;
}>`
  aspect-ratio: ${({ $aspectMobile }) => $aspectMobile ?? '3 / 4'};

  & > div {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpointUp.tablet} {
    aspect-ratio: ${({ $aspectTablet }) => $aspectTablet ?? '3 / 4'};
  }

  @media ${breakpointUp.desktop} {
    aspect-ratio: ${({ $aspectDesktop }) => $aspectDesktop ?? '3 / 4'};
  }

  @media ${breakpointUp.desktopLarge} {
    aspect-ratio: ${({ $aspectDesktopLarge }) =>
      $aspectDesktopLarge ?? '3 / 4'};
  }
`;

export const Link = styled(CMSLink)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${spacing.space16};
  grid-column: 1 / -1;
  cursor: pointer;
  margin-bottom: ${spacing.space32};

  ${Image} {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }

  &:hover {
    ${Image} {
      opacity: 0.85;
    }
  }

  @media ${breakpointUp.tablet} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${spacing.space16};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktop} {
    grid-template-columns: repeat(6, 1fr);
    column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktopLarge} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${spacing.space24};
    margin-bottom: ${spacing.space32};
  }
`;

export const Details = styled(Link)`
  align-content: start;
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    margin-bottom: 0;
  }

  @media ${breakpointUp.desktop} {
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.lg};
  margin-bottom: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.lg};
    grid-column: span 9;
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.lg};
    grid-column: span 5;
    white-space: break-spaces;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xl};
    grid-column: span 5;
    white-space: normal;
  }
`;

export const Description = styled.h3`
  grid-column: 1 / -1;
  ${typeScale.mobile.body.xs};
  margin-bottom: ${spacing.space16};
  color: ${({ theme }) => theme.text400};

  @media ${breakpointUp.tablet} {
    grid-column: span 9;
    ${typeScale.desktop.body.sm};
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 5;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.md};
    grid-column: span 5;
  }
`;

export const Authors = styled.div`
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  column-gap: ${spacing.space16};
  margin-bottom: ${spacing.space16};
  word-break: normal;
`;

export const AuthorDetails = styled.div`
  display: flex;
  column-gap: ${spacing.space8};
  align-items: center;
`;

export const AuthorAvatar = styled(ResponsiveImage)`
  border-radius: 6.25rem;
  max-width: ${spacing.space40};
  max-height: ${spacing.space40};
  overflow: hidden;
`;

export const AuthorAvatars = styled.div`
  display: flex;
  align-items: center;
  word-break: normal;

  ${AuthorAvatar} {
    margin-left: -${spacing.space16};
  }

  ${AuthorAvatar}:first-of-type {
    margin-left: 0;
  }
`;

export const AuthorName = styled.h2`
  ${typeScale.desktop.body.xs};
  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.xs};
  }
`;

export const AuthorDescription = styled.h2`
  ${typeScale.desktop.body.xs};
  color: ${({ theme }) => theme.text400};
  margin-bottom: 0;

  @media ${breakpointUp.tablet} {
    ${typeScale.desktop.body.xs};
  }
`;

export const Card = styled(ListItem)`
  @media ${breakpointUp.tablet} {
    .tablet-one-column & {
      ${Title} {
        ${typeScale.desktop.body.xl};
      }
    }

    .tablet-two-column-highlight & {
      ${Image} {
        aspect-ratio: 4 / 3;
      }

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        margin-bottom: 0;
      }

      ${Authors} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
      }
    }
  }

  @media ${breakpointUp.desktop} {
    .desktop-two-column & {
      ${Title} {
        ${typeScale.desktop.body.xl};
      }
    }

    .desktop-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Link} {
          grid-template-columns: repeat(12, 1fr);
        }

        ${Title} {
          ${typeScale.desktop.body.xxxl};
          grid-column: 1 / span 5;
          grid-row: 1 / span 2;
        }

        ${Authors} {
          grid-column: span 5 / -2;
        }

        ${Description} {
          grid-column: span 5 / -2;
        }

        ${Image} {
          aspect-ratio: 21 / 9;
        }

        ${TagsContainer} {
          grid-template-columns: repeat(12, 1fr);
        }

        ${TagsList} {
          grid-column: span 6 / -1;
        }
      }
    }

    .desktop-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space88});
        margin-bottom: 0;
      }

      ${ImageWrapper} {
        height: 100%;
      }

      ${Authors} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.md};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }

  @media ${breakpointUp.desktopLarge} {
    .desktop-large-two-column & {
      ${Title} {
        ${typeScale.desktop.body.xxl};
      }
    }

    .desktop-large-two-column-highlight & {
      :first-child {
        grid-column: span 2;

        ${Link} {
          grid-template-columns: repeat(12, 1fr);
        }

        ${Title} {
          ${typeScale.desktop.body.xxxl};
          grid-column: 1 / span 5;
          grid-row: 1 / span 2;
        }

        ${Authors} {
          grid-column: span 4 / -3;
        }

        ${Description} {
          grid-column: span 4 / -3;
        }

        ${Image} {
          aspect-ratio: 21 / 9;
        }

        ${TagsContainer} {
          grid-template-columns: repeat(12, 1fr);
        }

        ${TagsList} {
          grid-column: span 6 / -1;
        }
      }
    }

    .desktop-large-four-column-highlight & {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;

      ${Link} {
        margin-bottom: ${spacing.space16};
      }

      ${Details} {
        min-height: calc(${spacing.space56} + ${spacing.space112});
        margin-bottom: 0;
      }

      ${ImageWrapper} {
        height: 100%;
      }

      ${Authors} {
        margin-bottom: 0;
      }

      ${AuthorName} {
        ${typeScale.desktop.body.xs};
      }

      ${Title} {
        ${typeScale.desktop.body.xl};
      }

      :first-child {
        grid-column: span 2;
        grid-row: span 2;

        ${Image} {
          aspect-ratio: auto;
          height: 100%;
        }
      }
    }
  }
`;
