import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { StoryblokReportPage } from '../../types/blok.model';
import { Pagination } from '../../types';

type ReportsContextShape = {
  posts: StoryblokReportPage[] | undefined;
  allPosts: StoryblokReportPage[] | undefined;
  pagination?: Pagination;
};

interface ReportsContextProviderProps {
  children: ReactNode;
  data?: {
    posts: StoryblokReportPage[] | undefined;
    allPosts: StoryblokReportPage[] | undefined;
  };
  pagination?: Pagination;
}

const ReportsContext = createContext<ReportsContextShape | undefined>(
  undefined
);

export const ReportsContextProvider = ({
  children,
  data,
  pagination,
}: ReportsContextProviderProps): JSX.Element => {
  return (
    <ReportsContext.Provider
      value={{
        posts: data?.posts,
        allPosts: data?.allPosts,
        pagination,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReports = () => {
  const context = useContext(ReportsContext);
  if (context === undefined) {
    throw new Error('Missing passed allReports from pageContext');
  }

  return context;
};
