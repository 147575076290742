/** This component is used for links to pages that are generated by CMS */

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';
import { ensureTrailingSlash } from '../../util/ensureTranilingSlash';
import { isExternalLink } from '../../util/isExternalLink';

const getCorrectLink = (link: string) => {
  let newLink = link;

  if (link.includes('categories/')) {
    const unique = Array.from(new Set(link.split('/')));

    if (unique[unique.length - 1] === 'about') {
      newLink = `${unique[unique.length - 2]}/${unique[unique.length - 1]}`;
    } else {
      newLink = `/category/${unique[unique.length - 1]}`;
    }
  }
  if (link.includes('tags/')) {
    const unique = Array.from(new Set(link.split('/')));

    newLink = `/tag/${unique[unique.length - 1]}`;
  }

  return `/${ensureTrailingSlash(newLink)}`.replace(/\/\//g, '/');
};

export default function ArticleLink<TState>({
  children,
  ...props
}: React.PropsWithoutRef<GatsbyLinkProps<TState>>) {
  const { to } = props;

  if (props.target === '_blank') {
    return (
      <a {...props} href={to} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  /* https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#reminder-use-link-only-for-internal-links */
  if (isExternalLink(to)) {
    return (
      <a {...props} href={to}>
        {children}
      </a>
    );
  }

  const correctLink = getCorrectLink(to);

  return (
    <GatsbyLink<TState> {...props} to={correctLink}>
      {children}
    </GatsbyLink>
  );
}
