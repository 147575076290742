import React, { FC } from 'react';

import {
  Description,
  Header,
  StyledGrid,
  Title,
} from './CollectionHeader.styles';

export type CollectionHeaderProps = {
  title: string;
  description: string;
};

const CollectionHeaderModule: FC<CollectionHeaderProps> = ({
  title,
  description,
}) => {
  return (
    <Header>
      <StyledGrid>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </StyledGrid>
    </Header>
  );
};

export default CollectionHeaderModule;
