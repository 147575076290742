import React, { FC } from 'react';
import {
  ColorOverrides,
  Blockquote,
  QuoteText,
  Cite,
  Author,
  Credentials,
} from './QuoteBlock.styles';

export interface SharedProps {
  quote: string;
  byline: boolean;
  author?: string;
  authorCredentials?: string;
}

export type QuoteBlockComponentProps = SharedProps & ColorOverrides<string>;

const Quote: FC<QuoteBlockComponentProps> = ({
  byline,
  quote,
  author,
  authorCredentials,
  ...colorOverrides
}) => {
  return (
    <Blockquote {...colorOverrides}>
      <QuoteText>{quote}</QuoteText>
      {byline && author && (
        <Cite>
          <Author>{author}</Author>
          {authorCredentials && <Credentials>{authorCredentials}</Credentials>}
        </Cite>
      )}
    </Blockquote>
  );
};

export default Quote;
