import React from 'react';

export const ChevronLeft = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8809 13.3334L11.8333 12.4445L7.07139 8.00008L11.8333 3.55564L10.8809 2.66675L5.16663 8.00008L10.8809 13.3334Z"
      fill="currentColor"
    />
  </svg>
);

export const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.11913 13.3334L5.16675 12.4445L9.92865 8.00008L5.16675 3.55564L6.11913 2.66675L11.8334 8.00008L6.11913 13.3334Z"
      fill="currentColor"
    />
  </svg>
);
