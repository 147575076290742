import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, ReactNode } from 'react';
import {
  ImageCaption,
  ImageWrapper,
  StyledImage,
} from './ArticleImageBlock.styles';

export type SharedProps = {
  alt: string;
  caption: ReactNode;
};

export type ArticleImageBlockProps = SharedProps & {
  image: string | IGatsbyImageData;
};

const ArticleImageBlock: FC<ArticleImageBlockProps> = ({
  image,
  caption,
  alt,
}) => {
  return (
    <ImageWrapper>
      <StyledImage alt={alt} image={image} />
      {caption && <ImageCaption>{caption}</ImageCaption>}
    </ImageWrapper>
  );
};

export default ArticleImageBlock;
