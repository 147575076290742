import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

type Props = {
  className?: string;
  image: string | IGatsbyImageData;
  alt: string;
  loading?: 'lazy' | 'eager';
};

const PictureFluid = styled.div<{
  reveal?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0; /* to prevent font spacing between images and psuedo elements */

  .gatsby-image-wrapper {
    height: 100%;
  }

  picture {
    border-radius: 0.1875rem;
  }

  &::before {
    /* Image placeholder that will show if image is still being loaded */
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

const StoryblokAssetImage: FC<Props> = ({ className, image, ...props }) => {
  // const [loaded, setLoaded] = useState(false);
  // const [loadInitiated, setLoadInitiated] = useState(false);

  // useEffect(() => {
  //   if (loadInitiated) {
  //     const loadingTimer = window.setTimeout(() => {
  //       setLoaded(true);
  //     }, 2000);

  //     return () => clearTimeout(loadingTimer);
  //   }
  // }, [loadInitiated]);

  // const handleLoad = () => {
  //   setLoadInitiated(true);
  // };

  return (
    <PictureFluid className={className}>
      {typeof image === 'object' ? (
        <GatsbyImage
          {...props}
          image={image}
          style={{
            width: '100%',
          }}
          // onLoad={handleLoad}
          loading="eager"
        />
      ) : (
        <img
          {...props}
          src={image}
          alt={props.alt}
          style={{
            width: '100%',
          }}
          // onLoad={handleLoad}
        />
      )}
    </PictureFluid>
  );
};

export default StoryblokAssetImage;
