import React from 'react';
import SbEditable from 'storyblok-react';
import FeaturedBlogRow from './FeaturedBlogRow';

import { BlokComponent } from '../../../types/blok.model';
import { useBlogPostThumbnails } from '../../../util/useBlogPostThumbnails';

export type FeaturedBlogRowSchema = {
  posts: string[];
};

const BlogRowModule: BlokComponent<FeaturedBlogRowSchema> = ({ blok }) => {
  const blogPosts = useBlogPostThumbnails(blok.posts);

  return (
    <SbEditable content={blok}>
      <FeaturedBlogRow
        posts={blogPosts?.sort(
          (a, b) => blok.posts.indexOf(a.id) - blok.posts.indexOf(b.id)
        )}
      />
    </SbEditable>
  );
};

export default BlogRowModule;
