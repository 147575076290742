import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import { StoryblokCaseStudy } from '../../types/blok.model';
import { Pagination } from '../../types';

type CaseStudiesContextShape = {
  posts: StoryblokCaseStudy[] | undefined;
  allPosts: StoryblokCaseStudy[] | undefined;
  pagination?: Pagination;
};

interface CategoriesContextProviderProps {
  children: ReactNode;
  data?: {
    posts: StoryblokCaseStudy[] | undefined;
    allPosts: StoryblokCaseStudy[] | undefined;
  };
  pagination?: Pagination;
}

const CaseStudiesContext = createContext<CaseStudiesContextShape | undefined>(
  undefined
);

export const CaseStudiesContextProvider = ({
  children,
  data,
  pagination,
}: CategoriesContextProviderProps): JSX.Element => {
  return (
    <CaseStudiesContext.Provider
      value={{
        posts: data?.posts,
        allPosts: data?.allPosts,
        pagination,
      }}
    >
      {children}
    </CaseStudiesContext.Provider>
  );
};

export const useCaseStudies = () => {
  const context = useContext(CaseStudiesContext);
  if (context === undefined) {
    throw new Error('Missing passed allCaseStudies from pageContext');
  }

  return context;
};
