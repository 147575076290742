import { ContentBit } from '../../../new/components/RichText/BlockRenderer';

export type ArticleLinksSchema = { title: string; link: string }[];

export const convertTextToId = (text: string) => {
  return text.replaceAll(' ', '-');
};

export const getArticleLinks = (
  articleContent: ContentBit[]
): ArticleLinksSchema => {
  return articleContent
    .filter(
      (element) => element.type === 'heading' && element.attrs.level === 2
    )
    .map((element) => {
      if (
        element.type === 'heading' &&
        element.content &&
        element.content[0].type === 'text'
      ) {
        return {
          link: `#${convertTextToId(element.content[0].text)}`,
          title: element.content[0].text,
        };
      } else {
        return null;
      }
    })
    .flatMap((f) => (f ? [f] : []));
};
