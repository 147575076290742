import Link from 'gatsby-link';
import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const Chevron = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: baseline;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledChevronLeft = styled(Chevron)``;
export const StyledChevronRight = styled(Chevron)``;

export const StyledLink = styled(Link)<{ $isActive?: boolean }>`
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.textPrimary : theme.text500};
  ${typeScale.mobile.mono.sm}

  &:hover {
    color: ${({ theme }) => theme.textPrimary};
  }

  &:focus-visible {
    color: ${({ theme }) => theme.textPrimary};
    outline: solid 0.0625rem;
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.sm}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.sm}
  }
`;

export const StyledSpan = styled.span``;

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  column-gap: ${spacing.space24};
  margin: 0;
  padding: 0;
`;

export const StyledNavListItem = styled.li`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

export const StyledNavListItemPrev = styled(StyledNavListItem)`
  justify-content: end;
`;

export const StyledNavListItemNext = styled(StyledNavListItem)`
  justify-content: start;
`;

export const StyledSpacer = styled.div`
  max-width: ${spacing.space40};
  width: 100%;
`;

export const StyledListItem = styled.li`
  min-width: 0.8125rem;
`;

export const StyledNavigationText = styled.span`
  color: ${({ theme }) => theme.text500};
  display: flex;
  align-items: baseline;
  column-gap: ${spacing.space8};
  white-space: nowrap;
`;

export const StyledNavigationLink = styled(Link)`
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: baseline;
  column-gap: ${spacing.space8};
  white-space: nowrap;
`;

export const StyledNav = styled.nav`
  border-top: 0.0625rem solid ${({ theme }) => theme.border200};
  padding-top: ${spacing.space40};
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space40};
  grid-column: 1 / -1;
  ${typeScale.mobile.mono.md}

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
    padding-top: ${spacing.space24};
    margin-top: ${spacing.space0};
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
    padding-top: ${spacing.space24};
    margin-top: ${spacing.space0};
    margin-bottom: ${spacing.space36};
  }
`;
