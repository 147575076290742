import styled, { css } from 'styled-components';
import { textSizes } from '../../../styles/typography';
import { breakpointUp } from '../../../styles/mediaUtils';

export interface ColorOverrides<T> {
  markerColor: T;
  textColor: T;
  titleColor: T;
}

export type MarkerType = 'dash' | 'bullet' | 'check';

export const Title = styled.h6`
  ${textSizes.xs}
  color: var(--fg2, ${({ theme }) => theme.fg});
  margin-bottom: 1.5rem;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li<{ tall: boolean }>`
  ${textSizes.xs}
  color: var(--fg1, ${({ theme }) => theme.fgFaded});
  list-style: none;
  min-height: 1.5rem;
  margin-bottom: ${({ tall }) => (tall ? '1.5rem' : '0.5rem')};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ListMarker = styled.span<{ marker: MarkerType }>`
  ${({ marker }) =>
    css`
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
      display: inline-flex;
      align-items: center;
      color: var(--fg3, ${({ theme }) => theme.cloud});
      overflow: hidden;
      flex-shrink: 0;

      &::before {
        ${marker === 'bullet' &&
        css`
          content: '\\25C6';
        `}

        ${marker === 'dash' &&
        css`
          content: '—';
        `}

              position: relative;
        bottom: 0.125rem;
        display: 'block';
        font-size: 1rem;
        margin-left: 0.3125rem;

        @media ${breakpointUp.sm} {
          font-size: 1.25rem;
        }
      }
    `}
`;

export const ListWrapper = styled.div<ColorOverrides<string>>`
  ${({ markerColor, titleColor, textColor }) => css`
    ${Title} {
      color: ${titleColor};
    }

    ${ListMarker} {
      color: ${markerColor};
    }

    ${ListItem} {
      color: ${textColor};
    }
  `}
`;
