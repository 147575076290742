import React, { FC } from 'react';
import {
  StatsList,
  StatFigure,
  StatDescription,
  ColorOverrides,
  SharedProps,
} from './StatsBlock.styles';

export type StatsComponentProps = SharedProps &
  ColorOverrides<string> & {
    stats: { figure: string; description: string }[];
  };

const Stats: FC<StatsComponentProps> = ({
  type,
  size,
  stats,
  figureColor,
  metricColor,
}) => {
  return (
    <StatsList
      type={type}
      size={size}
      figureColor={figureColor}
      metricColor={metricColor}
    >
      {stats.map((stat) => (
        <div key={stat?.description}>
          <StatFigure>{stat.figure}</StatFigure>
          <StatDescription>{stat.description}</StatDescription>
        </div>
      ))}
    </StatsList>
  );
};

export default Stats;
