import React from 'react';
import SbEditable from 'storyblok-react';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import { StoryblokAsset, BlokComponent } from '../../../types/blok.model';
import BlogHeroImageModule, { SharedProps } from '.';

type BlogHeroImageSchema = SharedProps & {
  image_sm: StoryblokAsset;
  image_lg: StoryblokAsset;
};

const BlogHeroImageModuleBlok: BlokComponent<BlogHeroImageSchema> = ({
  blok,
}) => {
  const imageSm = useStoryblokImage(blok.image_sm.filename);
  const imageLg = useStoryblokImage(blok.image_lg.filename);

  const props = {
    ...blok,
    imageSm,
    imageLg,
  };

  return (
    <SbEditable content={blok}>
      <BlogHeroImageModule {...props} />
    </SbEditable>
  );
};

export default BlogHeroImageModuleBlok;
