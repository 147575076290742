import React from 'react';
import SbEditable from 'storyblok-react';
import {
  BlokComponent,
  StoryblokAuthor,
  StoryblokCategories,
  StoryblokTags,
} from '../../../types/blok.model';
import { useCategories } from '../../../old/storyblok/categories-context';
import { useTags } from '../../../old/storyblok/tags-context';
import { useAuthors } from '../../../old/storyblok/authors-context';
import BlogHeader from '.';

export type BlogHeaderBlokSchema = {
  tags?: StoryblokTags;
  categories?: StoryblokCategories;
  author: StoryblokAuthor;
  title: string;
};

const BlogHeaderBlok: BlokComponent<BlogHeaderBlokSchema> = ({ blok }) => {
  const categories = useCategories();
  const selectedCategories = categories.filter((category) =>
    blok.categories?.includes(category.uuid)
  );

  const tags = useTags();
  const selectedTags = tags?.filter((tag) => blok.tags?.includes(tag.uuid));

  const authors = useAuthors();
  const selectedAuthor = authors.find((author) => author.uuid === blok.author);

  return (
    <SbEditable content={blok}>
      <BlogHeader
        title={blok.title}
        tags={selectedTags}
        categories={selectedCategories}
        author={selectedAuthor}
      />
    </SbEditable>
  );
};

export default BlogHeaderBlok;
