import { useStoryblokImageGetter } from '../old/storyblok/asset-context';
import { useBlogPosts } from '../old/storyblok/blogPosts-context';
import { useCategories } from '../old/storyblok/categories-context';
import { useTags } from '../old/storyblok/tags-context';

export const useBlogPostThumbnails = (filterPosts?: string[]) => {
  const getStoryblokImage = useStoryblokImageGetter();

  const blogPostData = useBlogPosts();
  const allTags = useTags();
  const allCategories = useCategories();

  return blogPostData.allPosts
    ?.filter((post) => {
      if (filterPosts) {
        return filterPosts.includes(post.uuid);
      }
      return true;
    })
    ?.map((post) => {
      const tags = allTags.filter((tag) => post.tags?.includes(tag.uuid));
      const categories = allCategories.filter((category) =>
        post.categories.includes(category.uuid)
      );

      return {
        description: post.description,
        author: post.author,
        thumbnail_sm: getStoryblokImage(post.thumbnail_sm?.filename),
        thumbnail_lg: getStoryblokImage(post.thumbnail_lg?.filename),
        thumbnail_3x4: getStoryblokImage(post.thumbnail_sm?.filename),
        thumbnail_4x3: getStoryblokImage(post.thumbnail_lg?.filename),
        thumbnail_alt:
          post.thumbnail_sm?.alt || `thumbnail image for ${post.title}`,
        categories,
        tags,
        link: post.full_slug,
        title: post.title,
        id: post.uuid,
      };
    });
};
