import React, { useCallback, useEffect, useState } from 'react';
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
  withArtDirection,
} from 'gatsby-plugin-image';
import styled from 'styled-components';
import { useViewport, MediaQueries } from '../../../util/useViewport';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import { breakpointUp } from '../../../styles/mediaUtils';

export type ResponsiveImageProps = {
  mobile: IGatsbyImageData | string;
  tablet: IGatsbyImageData | string;
  desktop: IGatsbyImageData | string;
  alt: string;
  className?: string;
  title?: string;
  objectFit?: GatsbyImageProps['objectFit'];
  loading?: 'lazy' | 'eager';
  is_private?: boolean;
};

const PictureFluid = styled.div<{
  reveal?: boolean;
}>`
  position: relative;
  width: 100%;
  font-size: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    width: 100%;
  }

  picture {
    border-radius: 0.1875rem;
    height: 100%;
    width: 100%;
  }
`;

const ResponsiveImageEditMode = ({
  mobile,
  tablet,
  desktop,
  className,
  is_private,
  ...props
}: ResponsiveImageProps) => {
  const { isMobile, isTablet } = useViewport();
  const src = isMobile ? mobile : isTablet ? tablet : desktop;

  return (
    <PictureFluid className={className}>
      <img
        src={typeof src === 'string' ? src : ''}
        {...props}
        alt={props.alt}
      />
    </PictureFluid>
  );
};

const ContainableImage = styled(GatsbyImage)<{
  $aspectMobile?: { ratio: string; padding: string };
  $aspectTablet?: { ratio: string; padding: string };
  $aspectDesktop?: { ratio: string; padding: string };
}>`
  aspect-ratio: ${({ $aspectMobile }) => $aspectMobile?.ratio};

  > div:first-child {
    padding-top: ${({ $aspectMobile }) => $aspectMobile?.padding};
  }

  @media (${breakpointUp.tablet}) {
    aspect-ratio: ${({ $aspectTablet }) => $aspectTablet?.ratio};

    > div:first-child {
      padding-top: ${({ $aspectTablet }) => $aspectTablet?.padding};
    }
  }

  @media (${breakpointUp.desktop}) {
    aspect-ratio: ${({ $aspectDesktop }) => $aspectDesktop?.ratio};

    > div:first-child {
      padding-top: ${({ $aspectDesktop }) => $aspectDesktop?.padding};
    }
  }
`;

export const ResponsiveImage = ({
  mobile,
  tablet,
  desktop,
  className,
  objectFit,
  is_private,
  ...props
}: ResponsiveImageProps) => {
  const mobileGatsbyImage = useStoryblokImage(mobile as string);
  const tabletGatsbyImage = useStoryblokImage(tablet as string);
  const desktopGatsbyImage = useStoryblokImage(desktop as string);
  const mobileImage = mobileGatsbyImage ?? mobile;
  const tabletImage = tabletGatsbyImage ?? tablet;
  const desktopImage = desktopGatsbyImage ?? desktop;
  const { isMobile, isTablet } = useViewport();
  const sources = isMobile
    ? mobileImage
    : isTablet
    ? tabletImage
    : desktopImage;

  const getAspectRatio = useCallback(
    (image: IGatsbyImageData) => {
      if (objectFit === 'contain') {
        return {
          ratio: `${image.width} / ${image.height}`,
          padding: `${100 / (image.width / image.height)}% !important`,
        };
      }
    },
    [objectFit]
  );

  const [sourceImage, setSourceImage] = useState(sources);

  useEffect(() => {
    setSourceImage(sources);
  }, [sources]);

  if (!mobile && !tablet && !desktop) {
    return null;
  }

  if (
    typeof mobileImage === 'string' &&
    typeof tabletImage === 'string' &&
    typeof desktopImage === 'string'
  ) {
    return (
      <ResponsiveImageEditMode
        {...props}
        alt={props.alt}
        desktop={desktop}
        mobile={mobile}
        tablet={tablet}
        className={className}
      />
    );
  }

  if (
    typeof mobileImage !== 'string' &&
    typeof tabletImage !== 'string' &&
    typeof desktopImage !== 'string' &&
    typeof sourceImage !== 'string'
  ) {
    const images = withArtDirection(sourceImage, [
      {
        media: MediaQueries.isMobile,
        image: mobileImage,
      },
      {
        media: MediaQueries.isTablet,
        image: tabletImage,
      },
      {
        media: MediaQueries.isDesktop,
        image: desktopImage,
      },
    ]);

    return (
      <PictureFluid className={className}>
        <ContainableImage
          {...props}
          image={images}
          $aspectMobile={getAspectRatio(mobileImage)}
          $aspectTablet={getAspectRatio(tabletImage)}
          $aspectDesktop={getAspectRatio(desktopImage)}
          loading="eager"
        />
      </PictureFluid>
    );
  }

  return null;
};

export default ResponsiveImage;
