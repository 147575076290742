import React from 'react';
import SbEditable from 'storyblok-react';
import SummaryModule, { SharedProps } from '.';
import { Colors } from '../../components/SectionWrapper';
import type { StoryblokComponentType } from 'gatsby-source-storyblok';
import { StoryblokColor, BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';

type CaseStudyQuoteBlokSchema = SharedProps &
  Colors<StoryblokColor> & {
    text_content: RichTextDocument;
    block_content: [StoryblokComponentType<string>];
  };

const CaseStudyQuoteBlok: BlokComponent<CaseStudyQuoteBlokSchema> = ({
  blok,
  Dynamic,
}) => {
  const textContent = (
    <StoryblokRichText content={blok.text_content} Dynamic={Dynamic} />
  );
  const blockContent = blok.block_content[0] && (
    <Dynamic blok={blok.block_content[0]} key={blok._uid} />
  );

  const props = {
    title: blok.title,
    textContent,
    blockContent,
    bg1: blok.bg1?.color,
    fg1: blok.fg1?.color,
    fg2: blok.fg2?.color,
    fg3: blok.fg3?.color,
  };

  return (
    <SbEditable content={blok}>
      <SummaryModule {...props} />
    </SbEditable>
  );
};

export default CaseStudyQuoteBlok;
