import React, { FC } from 'react';
import componentMap from '../../storyblok/componentMap';
import type {
  StoryblokComponentType,
  StoryData,
} from 'gatsby-source-storyblok';

const Component: FC<{
  blok: StoryblokComponentType<string>;
  story?: StoryData;
}> = ({ blok, story }) => {
  if (typeof componentMap[blok.component] !== 'undefined') {
    const RenderComponent = componentMap[blok.component];
    return <RenderComponent blok={blok} story={story} Dynamic={Component} />;
  }
  return blok.component ? <span>{blok.component}</span> : null;
};

export default React.memo(Component);
