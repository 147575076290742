import React from 'react';
import {
  BlokComponent,
  StoryblokAsset,
  StoryblokLink,
} from '../../../types/blok.model';
import SbEditable from 'storyblok-react';
import { BrandModule } from './index';
import { useStoryblokImageGetter } from '../../../old/storyblok/asset-context';

type BrandModuleBlokSchema = {
  background_image: StoryblokAsset;
  button_link: StoryblokLink;
  button_text: string;
  light_theme: boolean;
  tagline: string;
  title: string;
};

export const BrandModuleBlok: BlokComponent<BrandModuleBlokSchema> = ({
  blok,
}) => {
  const getStoryblokImage = useStoryblokImageGetter();

  return (
    <SbEditable content={blok}>
      <BrandModule
        background_image={getStoryblokImage(blok.background_image?.filename)}
        background_alt={
          blok.background_image?.alt || `Elsewhen brand background image`
        }
        title={blok.title}
        tagline={blok.tagline}
        button_link={blok.button_link.url}
        button_text={blok.button_text}
        light_theme={blok.light_theme}
      />
    </SbEditable>
  );
};

export default BrandModuleBlok;
