import React, { FC } from 'react';

import {
  BackgroundImage,
  Header,
  HeaderTitle,
  StyledFooter,
  StyledTags,
} from './SharedHeader.styles';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import withRouter from '../../../util/withRouter';
import AuthorByline from '../../components/AuthorByline';
import { AuthorType } from '../../../old/storyblok/authors-context';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';

export type HeaderLayout = 'centered' | 'left-aligned';

export type SharedHeaderProps = {
  tags: TagType[];
  categories: CategoryType[];
  title: string;
  bgImage?: string | IGatsbyImageData;
  author?: AuthorType;
  layout: HeaderLayout;
  light?: boolean;
  bgColor?: string;
  fontColor?: string;
  underlineColor?: string;
};

const SharedHeaderModule: FC<SharedHeaderProps> = ({
  tags,
  categories,
  title,
  bgImage,
  author,
  layout,
  light,
  bgColor,
  fontColor,
  underlineColor,
}) => {
  return (
    <ThemeProvider theme={light ? defaultTheme : darkTheme}>
      <Header bgColor={bgColor}>
        {bgImage && <BackgroundImage alt="" image={bgImage} loading="eager" />}
        <Layout variant="smallWidth">
          <Grid>
            <StyledTags
              layout={layout}
              tags={tags}
              categories={categories}
              fontColor={fontColor}
              underlineColor={underlineColor}
              asLinks
            />
            <HeaderTitle layout={layout}>{title}</HeaderTitle>
            {author && (
              <StyledFooter layout={layout}>
                <AuthorByline data={author} light={light} />
              </StyledFooter>
            )}
          </Grid>
        </Layout>
      </Header>
    </ThemeProvider>
  );
};

export default withRouter(SharedHeaderModule);
