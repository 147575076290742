import React from 'react';
import { usePagination } from './use-pagination';
import { ensureTrailingSlash } from '../../../util/ensureTranilingSlash';
import {
  StyledChevronLeft,
  StyledChevronRight,
  StyledLink,
  StyledSpan,
  StyledList,
  StyledNavigationText,
  StyledNavigationLink,
  StyledNavListItemPrev,
  StyledNavListItemNext,
  StyledListItem,
  StyledSpacer,
  StyledNav,
} from './Pagination.styles';
import { ChevronLeft, ChevronRight } from './icons';

export type PaginationProps = {
  currentPage: number;
  totalNumberOfPages: number;
  /**
   * The number of pages to show on the left and right side of the current page.
   */
  siblings?: {
    left: number;
    right: number;
  };
  /**
   * Manage how page numbers map to paths.
   * @example
   * hrefBuilder={(page) => {
   *  if (page === 1) {
   *   return '/blog';
   * }
   *
   * return `/blog/${page}`;
   * }}
   */
  hrefBuilder?: (page: number) => string;
  /**
   * When the current page matches this index, this is when the "Go to start" link will be displayed.
   */
  indexToDisplayGoToStart?: number;
};

const defaultHrefBuilder = (page: number) => {
  if (page === 1) {
    return '/';
  }

  return `/${page}`;
};

export const Pagination = ({
  currentPage,
  totalNumberOfPages,
  hrefBuilder = defaultHrefBuilder,
  siblings = { left: 2, right: 1 },
  indexToDisplayGoToStart = 6,
}: PaginationProps) => {
  const { nextPage, pages, prevPage } = usePagination({
    currentPage,
    totalNumberOfPages,
    siblings,
  });

  if (pages === null) {
    return null;
  }

  const prevPageHref = hrefBuilder(prevPage);
  const nextPageHref = hrefBuilder(nextPage);
  const lastPageHref = hrefBuilder(totalNumberOfPages);
  const firstPageHref = hrefBuilder(1);
  const showPrevious = totalNumberOfPages > 1;
  const showNext = totalNumberOfPages > 1;

  return (
    <StyledNav role="navigation" aria-label="Pagination">
      <StyledList>
        {showPrevious && (
          <StyledNavListItemPrev>
            {currentPage === 1 ? (
              <StyledNavigationText>
                <StyledChevronLeft>
                  <ChevronLeft />
                </StyledChevronLeft>
                <span className="prev-text">Prev</span>
              </StyledNavigationText>
            ) : (
              <StyledNavigationLink
                to={ensureTrailingSlash(prevPageHref)}
                aria-label={`Go to page ${
                  currentPage - 1 === 0 ? 1 : prevPage
                }`}
              >
                <StyledChevronLeft>
                  <ChevronLeft />
                </StyledChevronLeft>
                <span className="prev-text">Prev</span>
              </StyledNavigationLink>
            )}
            <StyledSpacer />
          </StyledNavListItemPrev>
        )}

        {/* On page 6 and above, show the link to the first page */}
        {currentPage >= indexToDisplayGoToStart && (
          <StyledListItem>
            <StyledLink
              $isActive={currentPage === 1}
              to={ensureTrailingSlash(firstPageHref)}
              aria-label={`Go to the first page`} // screen readers will announce this as opposed to the just the page number
            >
              1 ...
            </StyledLink>
          </StyledListItem>
        )}

        {pages?.map((pageIndex) => {
          const generatedHref = hrefBuilder(pageIndex);
          return (
            <StyledListItem key={pageIndex}>
              <StyledLink
                $isActive={pageIndex === currentPage}
                to={ensureTrailingSlash(generatedHref)}
                aria-label={`Go to Page ${pageIndex}`} // screen readers will announce this as opposed to the just the page number
                aria-current={pageIndex === currentPage ? 'page' : undefined} // screen reader users will know which page they are on
              >
                {pageIndex}
              </StyledLink>
            </StyledListItem>
          );
        })}

        {!pages?.includes(totalNumberOfPages) && (
          <StyledListItem>
            <StyledLink
              $isActive={currentPage === totalNumberOfPages}
              to={`${lastPageHref}/`}
              aria-label={`Go to last page`} // screen readers will announce this as opposed to the just the page number
            >
              ... <StyledSpan>{totalNumberOfPages}</StyledSpan>
            </StyledLink>
          </StyledListItem>
        )}

        {showNext && (
          <StyledNavListItemNext>
            <StyledSpacer />
            {currentPage === totalNumberOfPages ? (
              <StyledNavigationText>
                <span className="next-text">Next</span>
                <StyledChevronRight>
                  <ChevronRight />
                </StyledChevronRight>
              </StyledNavigationText>
            ) : (
              <StyledNavigationLink
                to={`${nextPageHref}/`}
                aria-label={`Go to page ${
                  currentPage + 1 === totalNumberOfPages
                    ? totalNumberOfPages
                    : currentPage + 1
                }`}
              >
                <span className="next-text">Next</span>
                <StyledChevronRight>
                  <ChevronRight />
                </StyledChevronRight>
              </StyledNavigationLink>
            )}
          </StyledNavListItemNext>
        )}
      </StyledList>
    </StyledNav>
  );
};
