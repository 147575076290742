import React, { FC } from 'react';
import BlogPostThumbnail, {
  BlogThumbnailProps,
} from '../../blocks/BlogPostThumbnail';
import { StyledRow } from './CollectionBlogRow.styles';
import { StyledGridItem } from '../../blocks/BlogPostThumbnail/BlogPostThumbnail.styles';

export type BlogRowProps = {
  className?: string;
  posts: BlogThumbnailProps[] | undefined;
};

const BlogRowThumbnails: FC<BlogRowProps> = ({ posts, className }) => {
  return (
    <StyledRow className={className}>
      {posts?.map((post) => (
        <StyledGridItem key={post.title}>
          <BlogPostThumbnail
            link={post.link}
            thumbnail_sm={post.thumbnail_sm}
            thumbnail_lg={post.thumbnail_lg}
            thumbnail_alt={post.thumbnail_alt}
            title={post.title}
            description={post.description}
            tags={post.tags}
            categories={post.categories}
          />
        </StyledGridItem>
      ))}
    </StyledRow>
  );
};

export default BlogRowThumbnails;
