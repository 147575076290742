import styled from 'styled-components';
import { textSizes } from '../../../styles/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Caption = styled.span`
  ${textSizes.xs};
  margin-top: 1rem;
`;
