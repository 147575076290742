import React from 'react';
import { useLocation, WindowLocation } from '@gatsbyjs/reach-router';

const withRouter = <T,>(
  Component: React.ComponentType<T & { location: WindowLocation }>
): React.ComponentType<Omit<T, 'location'>> => {
  return (props: Omit<T, 'location'>) => {
    const location = useLocation();

    return <Component {...(props as T)} location={location} />;
  };
};

export default withRouter;
