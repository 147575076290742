import React, { FC } from 'react';
import {
  Card,
  Title,
  Subtitle,
  Logo,
  Description,
  Link,
} from './CardService.styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export type CardServiceProps = {
  title?: string;
  ctaText?: string;
  ctaLink?: string;
  subtitle?: string;
  description?: React.ReactNode;
  serviceIcon?: string | IGatsbyImageData;
  serviceIconAlt?: string;
};

const CardService: FC<CardServiceProps> = ({
  title,
  ctaText,
  ctaLink,
  subtitle,
  description,
  serviceIcon,
  serviceIconAlt = `logo for ${title}`,
}) => {
  return (
    <Card>
      {title && (
        <Title>
          {serviceIcon && (
            <Logo
              mobile={serviceIcon}
              tablet={serviceIcon}
              desktop={serviceIcon}
              alt={serviceIconAlt}
              loading="eager"
            />
          )}
          {title}
        </Title>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {description && <Description>{description}</Description>}
      {ctaText && (
        <Link size="md" href={ctaLink ?? ''}>
          {ctaText}
        </Link>
      )}
    </Card>
  );
};

export default CardService;
