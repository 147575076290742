import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { typeScale } from '../../styles/newTypography';
import { breakpointUp } from '../../styles/mediaUtils';
import { spacing } from '../../styles/newThemeVariables.styles';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  errorMessage?: string;
}

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input<{ error?: boolean }>`
  ${typeScale.mobile.mono.md}
  color: ${({ theme, error }) => (error ? theme.textError : theme.textPrimary)};
  border: none;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.border300};
  display: block;
  width: 100%;
  padding: 0 0 ${spacing.space8};
  min-width: 100%;
  cursor: text;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: ${spacing.space24};
  border-radius: 0;

  &::placeholder {
    color: ${({ theme, error }) => (error ? theme.textError : theme.text400)};
  }

  &:hover {
    border-color: ${({ theme }) => theme.border400};
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.sm}

    &::placeholder {
      color: ${({ theme, error }) => (error ? theme.textError : theme.text300)};
    }
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.textError};
  ${typeScale.mobile.mono.md};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.mono.md}
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.mono.md}
  }
`;

export const FormInput = (props: FormInputProps) => {
  return (
    <Wrapper>
      {props.isError && props.errorMessage && (
        <Error>{props.errorMessage}</Error>
      )}
      <Input
        id={props.placeholder}
        aria-label={props.placeholder}
        {...props}
        error={props.isError}
      />
    </Wrapper>
  );
};
