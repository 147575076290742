import styled, { css } from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import Sectors from '../../components/Sectors';
import { textSizes } from '../../../styles/typography';
import CMSLink from '../../components/CMSLink';
import { ResponsiveImage } from '../../components/ResponsiveImage';

export const ThumbnailWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  grid-column: span 6;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImgContainer = styled(ResponsiveImage)<{ featured?: boolean }>`
  margin-bottom: 1.5rem;
  aspect-ratio: 3 / 4;

  & > div {
    height: 100%;
  }

  @media ${breakpointUp.sm} {
    /** Featured thumbnail  */
    ${({ featured }) =>
      featured &&
      css`
        aspect-ratio: 4/3;
      `}
  }
`;

export const TextWrapper = styled(CMSLink)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h3`
  ${textSizes.sm};
  color: ${({ theme }) => theme.fg};
  margin-bottom: 0.5rem;

  @media ${breakpointUp.sm} {
    ${textSizes.md}
  }

  @media ${breakpointUp.md} {
    ${textSizes.lg}
  }
`;

export const Description = styled.p`
  ${textSizes.xs}
  color: ${({ theme }) => theme.greyMedium};
  margin-bottom: 1rem;

  @media ${breakpointUp.sm} {
    ${textSizes.sm}
  }

  @media ${breakpointUp.md} {
    ${textSizes.md}
  }
`;

export const TagsSection = styled(Sectors)`
  color: ${({ theme }) => theme.greyMedium};

  li {
    ${textSizes.xxs}
    border-bottom: 0.125rem solid rgba(83, 88, 95, 0.15);

    :hover {
      border-bottom: 0.125rem solid ${({ theme }) => theme.fgFaded};
    }
  }
`;

export const StyledGridItem = styled.div`
  grid-column: span 6;

  @media ${breakpointUp.md} {
    grid-column: span 4;
  }
`;
