import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokAsset,
  StoryblokAuthor,
  StoryblokCategories,
  StoryblokTags,
} from '../../../types/blok.model';
import { useNavTheme } from '../../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import RenderAfterPageTransition from '../../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../../util/useThemeMode';

import { spacing } from '../../../styles/newThemeVariables.styles';
import { useStoryblokImageGetter } from '../../../old/storyblok/asset-context';
import { useCategories } from '../../../old/storyblok/categories-context';
import { useTags } from '../../../old/storyblok/tags-context';
import { AuthorType, useAuthors } from '../../../old/storyblok/authors-context';
import { useReportsThumbnails } from '../../../util/useReportsThumbnails';
import { transformCategorySlugs } from '../../../util/transformCollection';
import isEmpty from '../../../util/isEmpty';

import CardList from '../../modules/CardList';
import CardReportContent from '../../blocks/CardReportContent';
import ContactFormModule from '../../modules/ContactFormModule';
import AuthorByline from '../../components/AuthorByline';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import Spacer from '../../components/Spacer';
import {
  StylHeroWrapper,
  StylHeroImage,
  StyledRow,
  StylHeroTags,
  Title,
  HeaderFooter,
} from './ReportPage.styles';

interface PageSchema extends NewStoryblokMetaData {
  title: string;
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  bodySpacing?: string;
  bodyLowerSpacing?: string;
  background_image: StoryblokAsset;
  hero_img_sm: StoryblokAsset;
  hero_img_lg: StoryblokAsset;
  categories: StoryblokCategories;
  tags: StoryblokTags;
  light_navigation: boolean;
  light_hero: boolean;
  authors: StoryblokAuthor[];
  related_reports: string[];
}

const ListContainer = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
  padding-top: ${spacing.space72};
  padding-bottom: ${spacing.space72};

  @media ${breakpointUp.tablet} {
    padding-top: ${spacing.space64};
    padding-bottom: ${spacing.space64};
  }

  @media ${breakpointUp.desktop} {
    padding-top: ${spacing.space112};
    padding-bottom: ${spacing.space112};
  }
`;

const ReportPage: BlokComponent<PageSchema> = ({ blok, Dynamic }) => {
  useNavTheme(blok.light_navigation ? darkTheme(defaultTheme) : defaultTheme);

  const getStoryblokImage = useStoryblokImageGetter();
  const categories = useCategories();
  const selectedCategories = categories.filter((category) =>
    blok.categories?.includes(category.uuid)
  );

  const relatedReports = transformCategorySlugs(
    useReportsThumbnails(blok?.related_reports ?? [])
  );

  const tags = useTags();
  const selectedTags = tags.filter((tag) => blok.tags?.includes(tag.uuid));

  const authors = useAuthors();

  const selectedAuthors = blok.authors?.map(
    (uuid) => authors?.find((a) => a?.uuid === uuid) as AuthorType
  );

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={blok.light_hero ? darkTheme : defaultTheme}>
        <StylHeroWrapper>
          {blok.hero_img_sm?.filename && (
            <StylHeroImage
              alt={blok.hero_img_sm?.alt || `Hero image for ${blok.title}`}
              mobile={getStoryblokImage(blok.hero_img_sm?.filename)}
              tablet={
                blok.hero_img_lg?.filename
                  ? getStoryblokImage(blok.hero_img_lg?.filename)
                  : getStoryblokImage(blok.hero_img_sm?.filename)
              }
              desktop={
                blok.hero_img_lg?.filename
                  ? getStoryblokImage(blok.hero_img_lg?.filename)
                  : getStoryblokImage(blok.hero_img_sm?.filename)
              }
            />
          )}
          <Layout variant="smallWidth">
            <Grid>
              <StyledRow>
                <StylHeroTags
                  tags={selectedTags}
                  categories={selectedCategories}
                  asLinks
                />
                <Title>{blok.title}</Title>
              </StyledRow>
              <HeaderFooter>
                {selectedAuthors?.length > 0 && (
                  <AuthorByline data={selectedAuthors} size="md" />
                )}
              </HeaderFooter>
            </Grid>
          </Layout>
        </StylHeroWrapper>
      </ThemeProvider>

      <ThemeModeContextProvider themeMode={'light'}>
        <ThemeProvider theme={defaultTheme}>
          <RenderAfterPageTransition>
            {blok.body?.map((insideBlok, index) => (
              <Spacer
                key={insideBlok._uid}
                className={`item-${index} ${
                  insideBlok.spacing ?? blok.bodySpacing ?? 'none'
                } `}
              >
                <Dynamic blok={insideBlok} />
              </Spacer>
            ))}
          </RenderAfterPageTransition>
        </ThemeProvider>
      </ThemeModeContextProvider>

      <ThemeModeContextProvider themeMode={'dark'}>
        <ThemeProvider theme={darkTheme(defaultTheme)}>
          <RenderAfterPageTransition>
            {blok.bodyLower?.map((insideBlok, index) => (
              <Spacer
                key={insideBlok._uid}
                className={`item-${index} ${
                  insideBlok.spacing ?? blok.bodyLowerSpacing ?? 'none'
                } `}
              >
                <Dynamic blok={insideBlok} />
              </Spacer>
            ))}
            {relatedReports && !isEmpty(relatedReports) && (
              <ListContainer>
                <CardList
                  scrollMobile={false}
                  title="Related Reports"
                  ctaText="See All"
                  ctaLink="/reports/"
                  mobile={{
                    rowGap: spacing.space64,
                    variant: 'one-column',
                  }}
                  tablet={{
                    rowGap: spacing.space64,
                    variant: 'one-column',
                  }}
                  desktop={{
                    rowGap: spacing.space112,
                    variant: 'two-column',
                  }}
                  desktopLarge={{
                    rowGap: spacing.space112,
                    variant: 'two-column',
                  }}
                >
                  {relatedReports.map((report) => (
                    <CardReportContent
                      key={report.id}
                      title={report.title}
                      link={report.link}
                      coverImage={report.cover_image}
                      coverImageAlt={report.cover_alt}
                      mobile={{
                        aspect: '3 / 4',
                        thumbnail: report.thumbnail_3x4,
                      }}
                      tablet={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      desktop={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      desktopLarge={{
                        aspect: '4 / 3',
                        thumbnail: report.thumbnail_4x3,
                      }}
                      authors={report.authors}
                      thumbnailAlt={report.thumbnail_alt}
                      description={report.description}
                      tags={[...report.tags, ...report.categories]}
                    />
                  ))}
                </CardList>
              </ListContainer>
            )}
            <ContactFormModule
              form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
              tagline={'READY TO GET STARTED?'}
              title={'Transform how your most important work is delivered'}
              subtitle={
                'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
              }
            />
          </RenderAfterPageTransition>
        </ThemeProvider>
      </ThemeModeContextProvider>
    </SbEditable>
  );
};

export default React.memo(ReportPage);
