import React from 'react';
import Reel from '../../components/Reel';
import { ReelModuleWrapper, Title, Item } from './ClientReelModule.styles';
import { AnimatePresence } from 'framer-motion';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { useItemGroups } from '../../hooks';

export type ReelModuleProps = {
  logos: (string | IGatsbyImageData)[];
  metadata: { alt: string; title?: string }[];
  delay: number;
  title?: string;
};
const ClientReelModule = ({
  logos,
  metadata,
  title,
  delay,
}: ReelModuleProps) => {
  const itemsToShow = useItemGroups(logos, delay);

  return (
    <Section>
      <Layout>
        <Grid>
          {title && <Title>{title}</Title>}
          <ReelModuleWrapper>
            <AnimatePresence exitBeforeEnter>
              {itemsToShow.map((logo, index) => (
                <Item
                  key={logo.id}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { delay: index * 0.1, duration: 0.3 },
                  }}
                  exit={{
                    opacity: 0,
                    transition: { delay: index * 0.15, duration: 0.3 },
                  }}
                >
                  <Reel
                    item={logo.src}
                    alt={metadata[logo.id].alt}
                    title={metadata[logo.id].title}
                  />
                </Item>
              ))}
            </AnimatePresence>
          </ReelModuleWrapper>
        </Grid>
      </Layout>
    </Section>
  );
};

export default ClientReelModule;
