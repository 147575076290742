import React, { FC } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../util/useViewport';
import themeVariables from '../../../styles/themeVariables';
import { useLazyLoad } from '../../../util/useLazyLoad';

export type VideoAssetProps = {
  filename: string;
  poster?: string;
  alt?: string;
  title?: string;
};

export type ResponsiveVideoProps = {
  mobile?: VideoAssetProps;
  tablet?: VideoAssetProps;
  desktop?: VideoAssetProps;
  title?: string;
  poster?: string;
  loading?: 'lazy' | 'eager';
};

export const StyledBgVideo = styled.video<{ reveal: boolean }>`
  will-change: opacity;
  transition: opacity ${themeVariables.revealTransition};
  opacity: ${({ reveal }) => (reveal ? 1 : 0)};
  grid-column: 1 / -1;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

export const ResponsiveVideo: FC<ResponsiveVideoProps> = ({
  loading = 'lazy',
  mobile,
  tablet,
  desktop,
  title,
  poster,
}) => {
  const { isMobile, isTablet } = useViewport();
  const { ref, readyToLoad } = useLazyLoad(true);

  const videoSrc = () => {
    if (isMobile) {
      return mobile;
    }
    if (isTablet) {
      return tablet;
    }
    return desktop;
  };

  return (
    <StyledBgVideo
      ref={ref}
      src={videoSrc()?.filename}
      poster={videoSrc()?.poster ?? poster}
      title={videoSrc()?.title ?? title}
      autoPlay
      loop
      muted
      playsInline
      reveal={loading === 'lazy' ? readyToLoad : true}
    />
  );
};

export default ResponsiveVideo;
