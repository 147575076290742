import styled from 'styled-components';
import { breakpointUp } from '../../../../styles/mediaUtils';
import { unstyleList } from '../../../../styles/miscUtils';
import { typeScale } from '../../../../styles/newTypography';
import { spacing } from '../../../../styles/newThemeVariables.styles';
import CMSLink from '../../../components/CMSLink';
import { Grid } from '../../../layout/Grid';

export const StyledWrapper = styled(Grid)`
  grid-column: 1 / -1;
  margin-top: ${spacing.space64};
  margin-bottom: ${spacing.space16};
  row-gap: ${spacing.space64};

  @media ${breakpointUp.tablet} {
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space24};
  }
`;

export const SectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Section = styled.span`
  ${typeScale.desktop.mono.sm}
  color: ${({ theme }) => theme.textPrimary};
`;

export const SectorsList = styled.ul`
  ${unstyleList()}
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.space8};
`;

export const Item = styled(CMSLink)<{ isActive: boolean }>`
  display: inline-block;
  position: relative;
  color: var(--fg3, ${({ theme }) => theme.textPrimary});
  ${typeScale.mobile.body.lg}
  padding-bottom: ${spacing.space4};

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.125rem;
    bottom: 0;
    left: 0;
    transform: ${({ isActive }) => (isActive ? `scaleX(1)` : `scaleX(0)`)};
    pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};
    cursor: ${({ isActive }) => (isActive ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme }) => theme.grey};
    transition: transform 0.35s ease-out;
    transform-origin: bottom left;
  }

  &:hover::after,
  &:active::after {
    transform: scaleX(1);
  }

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xl}
  }
`;

export const CategoriesWrapper = styled(SectorsWrapper)`
  grid-column: 1 / -1;
  row-gap: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    grid-column: 1 / span 6;
  }
`;

export const IndustriesWrapper = styled(SectorsWrapper)`
  grid-column: 1 / -1;
  row-gap: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    grid-column: span 6 / -1;
  }
`;
