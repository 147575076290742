import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent } from '../../../types/blok.model';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';
import { StyledWrapper } from './ArticleContentModule.styles';

export interface ArticleContentModuleSchema {
  content: RichTextDocument;
}

const ArticleContentModule: BlokComponent<ArticleContentModuleSchema> = ({
  blok,
  Dynamic,
}) => {
  return (
    <SbEditable content={blok}>
      <StyledWrapper>
        <StoryblokRichText content={blok.content} Dynamic={Dynamic} />
      </StyledWrapper>
    </SbEditable>
  );
};

export default ArticleContentModule;
