import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import SectionWrapper from '../../components/SectionWrapper';

export const StyledSectionWrapper = styled(SectionWrapper)`
  padding: 1.5rem 0 2rem 0;

  @media ${breakpointUp.sm} {
    padding: 6rem 0;
  }

  @media ${breakpointUp.lg} {
    padding: 9.375rem 0 10rem 0;
  }
`;

export const Title = styled.h2`
  ${textSizes.xs}
  color: var(--fg2, ${({ theme }) => theme.fgFaded});
  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  ${textSizes.xs}
  color: var(--fg1, ${({ theme }) => theme.fg});
  margin-bottom: 2rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media ${breakpointUp.sm} {
    margin-bottom: 0;
  }

  @media ${breakpointUp.md} {
    ${textSizes.md}
  }
`;
