import styled from 'styled-components';
import StoryblokAssetImage from '../../components/AssetImage';

import { textSizes } from '../../../styles/typography';

export const ImageWrapper = styled.figure`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ImageCaption = styled.figcaption`
  ${textSizes.xs};
  margin-top: 1rem;
`;

export const StyledImage = styled(StoryblokAssetImage)`
  width: 100%;

  img {
    width: 100%;
  }
`;
