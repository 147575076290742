import React, { useMemo } from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { BlokComponent, NewStoryblokMetaData } from '../../types/blok.model';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';

import InlineFilterModule from '../modules/InlineFilterModule';
import CardList from '../modules/CardList';
import CardCaseStudy from '../blocks/CardCaseStudy';
import CardBlogContent from '../blocks/CardBlogContent';
import CardReportContent from '../blocks/CardReportContent';
import Spacer from '../components/Spacer';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useTags } from '../../old/storyblok/tags-context';
import { useCategories } from '../../old/storyblok/categories-context';
import { useLocation } from '@gatsbyjs/reach-router';
import { useCaseStudyThumbnails } from '../../util/useCaseStudyThumbnails';
import { useBlogPostThumbnails } from '../../util/useBlogPostThumbnails';
import { useReportsThumbnails } from '../../util/useReportsThumbnails';
import transformCollection from '../../util/transformCollection';

interface TagCollectionsPageSchema extends NewStoryblokMetaData {
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  bodySpacing?: string;
  bodyLowerSpacing?: string;
  display_name: string;
}

const ListContainer = styled.div`
  margin-bottom: ${spacing.space128};
`;

const CollectionPage: BlokComponent<TagCollectionsPageSchema> = ({
  blok,
  Dynamic,
}) => {
  const allTags = useTags();
  const allCategories = useCategories();
  const location = useLocation();

  const convertedCaseStudies = useCaseStudyThumbnails();
  const convertedBlogPosts = useBlogPostThumbnails();
  const convertedReports = useReportsThumbnails();

  const transformedCaseStudies =
    convertedCaseStudies && transformCollection(convertedCaseStudies);
  const transformedBlogPosts =
    convertedBlogPosts && transformCollection(convertedBlogPosts);
  const transformedReports =
    convertedReports && transformCollection(convertedReports);

  const trimmedCasestudies = transformedCaseStudies?.slice(0, 3);
  const trimmedBlogposts = transformedBlogPosts?.slice(0, 3);
  const trimmedReports = transformedReports?.slice(0, 3);

  const selectedCollectionLink = useMemo(() => {
    if (location.pathname.includes('/category/')) {
      return `category/${
        allCategories.find((cat) => blok.display_name === cat.title)?.slug
      }`;
    }

    if (location.pathname.includes('/tag/')) {
      return `tag/${
        allTags.find((tag) => blok.display_name === tag.title)?.slug
      }`;
    }
  }, [allCategories, allTags, blok.display_name, location.pathname]);

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={defaultTheme}>
        <RenderAfterPageTransition>
          <InlineFilterModule headerTitle={blok.display_name} />

          {trimmedCasestudies && trimmedCasestudies.length > 0 && (
            <ListContainer>
              <CardList
                title="case studies"
                ctaText={
                  transformedCaseStudies && transformedCaseStudies.length > 3
                    ? 'show all'
                    : undefined
                }
                ctaLink={`/work/${selectedCollectionLink}/`}
                mobile={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                tablet={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                desktop={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
                desktopLarge={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
              >
                {trimmedCasestudies.map((caseStudy) => (
                  <CardCaseStudy
                    key={caseStudy.id}
                    title={caseStudy.title}
                    link={caseStudy.link}
                    clientLogo={caseStudy.logo}
                    clientLogoDark={caseStudy.logoDark}
                    clientName={caseStudy.client}
                    clientLogoAlt={caseStudy.client}
                    thumbnailAlt={caseStudy.thumbnail_alt}
                    mobile={{
                      aspect: '3 / 4',
                      thumbnail: caseStudy.thumbnail_3x4,
                    }}
                    tablet={{
                      aspect: '3 / 4',
                      thumbnail: caseStudy.thumbnail_3x4,
                    }}
                    desktop={{
                      aspect: '3 / 4',
                      thumbnail: caseStudy.thumbnail_3x4,
                    }}
                    desktopLarge={{
                      aspect: '4 / 3',
                      thumbnail: caseStudy.thumbnail_4x3,
                    }}
                    tags={[
                      ...caseStudy.tags.map((tag) => ({
                        ...tag,
                        full_slug: `work/${tag.full_slug}`,
                      })),
                      ...caseStudy.categories.map((category) => ({
                        ...category,
                        full_slug: `work/${category.full_slug}`,
                      })),
                    ]}
                  />
                ))}
              </CardList>
            </ListContainer>
          )}

          {trimmedBlogposts && trimmedBlogposts.length > 0 && (
            <ListContainer>
              <CardList
                title="blog posts"
                ctaText={
                  transformedBlogPosts && transformedBlogPosts.length > 3
                    ? 'show all'
                    : undefined
                }
                ctaLink={`/blog/${selectedCollectionLink}/`}
                mobile={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                tablet={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                desktop={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
                desktopLarge={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
              >
                {trimmedBlogposts.map((blogPost) => {
                  return (
                    <CardBlogContent
                      key={blogPost.id}
                      title={blogPost.title}
                      link={blogPost.link}
                      thumbnailAlt={blogPost.thumbnail_alt}
                      mobile={{
                        aspect: '3 / 4',
                        thumbnail: blogPost.thumbnail_3x4,
                      }}
                      tablet={{
                        aspect: '3 / 4',
                        thumbnail: blogPost.thumbnail_3x4,
                      }}
                      desktop={{
                        aspect: '3 / 4',
                        thumbnail: blogPost.thumbnail_3x4,
                      }}
                      desktopLarge={{
                        aspect: '3 / 4',
                        thumbnail: blogPost.thumbnail_3x4,
                      }}
                      description={blogPost.description}
                      tags={[
                        ...blogPost.tags.map((tag) => ({
                          ...tag,
                          full_slug: `blog/${tag.full_slug}`,
                        })),
                        ...blogPost.categories.map((category) => ({
                          ...category,
                          full_slug: `blog/${category.full_slug}`,
                        })),
                      ]}
                    />
                  );
                })}
              </CardList>
            </ListContainer>
          )}
          {trimmedReports && trimmedReports.length > 0 && (
            <ListContainer>
              <CardList
                title="reports"
                ctaText={
                  transformedReports && transformedReports.length > 3
                    ? 'show all'
                    : undefined
                }
                ctaLink={`/reports/${selectedCollectionLink}/`}
                mobile={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                tablet={{
                  rowGap: spacing.space48,
                  variant: 'one-column',
                }}
                desktop={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
                desktopLarge={{
                  rowGap: spacing.space56,
                  variant: 'three-column',
                }}
              >
                {trimmedReports.map((report) => (
                  <CardReportContent
                    key={report.id}
                    title={report.title}
                    link={report.link}
                    authors={report.authors}
                    thumbnailAlt={report.thumbnail_alt}
                    mobile={{
                      aspect: '3 / 4',
                      thumbnail: report.thumbnail_3x4,
                    }}
                    tablet={{
                      aspect: '3 / 4',
                      thumbnail: report.thumbnail_3x4,
                    }}
                    desktop={{
                      aspect: '3 / 4',
                      thumbnail: report.thumbnail_3x4,
                    }}
                    desktopLarge={{
                      aspect: '3 / 4',
                      thumbnail: report.thumbnail_3x4,
                    }}
                    coverImage={report.cover_image}
                    coverImageAlt={report.cover_alt}
                    description={report.description}
                    tags={[
                      ...report.tags.map((tag) => ({
                        ...tag,
                        full_slug: `reports/${tag.full_slug}`,
                      })),
                      ...report.categories.map((category) => ({
                        ...category,
                        full_slug: `reports/${category.full_slug}`,
                      })),
                    ]}
                  />
                ))}
              </CardList>
            </ListContainer>
          )}

          {blok.body?.map((insideBlok, index) => (
            <Spacer
              key={insideBlok._uid}
              className={`item-${index} ${
                insideBlok.spacing ?? blok.bodySpacing ?? 'default'
              } `}
            >
              <Dynamic blok={insideBlok} />
            </Spacer>
          ))}
        </RenderAfterPageTransition>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme(defaultTheme)}>
        <RenderAfterPageTransition>
          {blok.bodyLower?.map((insideBlok, index) => (
            <Spacer
              key={insideBlok._uid}
              className={`item-${index} ${
                insideBlok.spacing ?? blok.bodyLowerSpacing ?? 'default'
              } `}
            >
              <Dynamic blok={insideBlok} />
            </Spacer>
          ))}
        </RenderAfterPageTransition>
      </ThemeProvider>
    </SbEditable>
  );
};

export default React.memo(CollectionPage);
