import React from 'react';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import {
  ResponsiveVideo,
  VideoAssetProps,
} from '../../components/ResponsiveVideo';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import {
  Container,
  ItemSingle,
  ItemDuo,
  ItemDuoOffsetRight,
  ItemDuoOffsetLeft,
  ItemTrio,
  ItemMasonry,
} from './VideoGalleryModule.styles';

const variantType = {
  single: ItemSingle,
  duoEqual: ItemDuo,
  duoOffsetRight: ItemDuoOffsetRight,
  duoOffsetLeft: ItemDuoOffsetLeft,
  trioEqual: ItemTrio,
  masonry: ItemMasonry,
} as const;

export interface VideoGalleryProps {
  variant: keyof typeof variantType;
  items: {
    type: string;
    id?: string;
    sources: {
      mobile: VideoAssetProps;
      tablet: VideoAssetProps;
      desktop: VideoAssetProps;
    };
  }[];
}

const VideoGallery: React.FC<VideoGalleryProps> = ({ variant, items }) => {
  const Item = variantType[variant];

  return (
    <Section>
      <Layout>
        <Grid>
          <Container variant={variant}>
            {items.map(({ type, sources, id }, index) => (
              <Item key={id ?? index}>
                {type === 'videoAsset' ? (
                  <ResponsiveVideo
                    mobile={sources.mobile}
                    tablet={sources.tablet}
                    desktop={sources.desktop}
                  />
                ) : (
                  <ResponsiveImage
                    alt={
                      sources.mobile.alt ??
                      sources.tablet.alt ??
                      sources.desktop.alt ??
                      ''
                    }
                    mobile={sources.mobile.filename}
                    tablet={sources.tablet.filename}
                    desktop={sources.desktop.filename}
                    title={
                      sources.mobile.title ??
                      sources.tablet.title ??
                      sources.desktop.title
                    }
                    objectFit="contain"
                  />
                )}
              </Item>
            ))}
          </Container>
        </Grid>
      </Layout>
    </Section>
  );
};

export default VideoGallery;
