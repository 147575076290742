import React from 'react';
import SbEditable from 'storyblok-react';
import Spacer, { SpacerProps } from '.';
import { BlokComponent } from '../../../types/blok.model';

const SpacerBlok: BlokComponent<SpacerProps> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <Spacer {...blok} />
    </SbEditable>
  );
};

export default SpacerBlok;
