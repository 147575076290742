import React, { FC, ReactNode } from 'react';
import { TextBlockWrapper } from './TextBlock.styles';

export interface ColorOverrides<T> {
  textColor?: T;
}
export interface SharedProps {
  text: ReactNode;
  type?: 'xs' | 'sm' | 'md' | 'lg';
}

export type ComponentProps = SharedProps & ColorOverrides<string>;

const TextBlock: FC<ComponentProps> = ({ text, type, textColor }) => {
  return (
    <TextBlockWrapper type={type ?? 'sm'} textColor={textColor}>
      {text}
    </TextBlockWrapper>
  );
};

export default TextBlock;
