import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import {
  ImageStyleProps,
  ImageWrapper,
  StyledImage,
} from './ImageBlock.styles';

export type SharedProps = ImageStyleProps & {
  alt: string;
};

export type ImageBlockProps = SharedProps & {
  image: string | IGatsbyImageData;
};

const ImageBlock: FC<ImageBlockProps> = ({ image, alt, type }) => {
  return (
    <ImageWrapper>
      <StyledImage alt={alt} image={image} type={type} />
    </ImageWrapper>
  );
};

export default ImageBlock;
