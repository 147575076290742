import React, { FC } from 'react';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { AuthorType } from '../../../old/storyblok/authors-context';
import BlogHeader from '../SharedHeaderModule';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import colors from '../../../styles/themeVariables';
import { rgba } from 'polished';

export type BlogHeaderProps = {
  tags: TagType[];
  categories: CategoryType[];
  title: string;
  bgImage?: string | IGatsbyImageData;
  author?: AuthorType;
};

const bgCol = colors.snow;
const fontCol = colors.greyMedium;
const underlineCol = rgba(83, 88, 95, 0.15);

const BlogHeaderModule: FC<BlogHeaderProps> = ({
  tags,
  categories,
  title,
  author,
}) => {
  return (
    <BlogHeader
      tags={tags}
      categories={categories}
      title={title}
      author={author}
      layout={'centered'}
      light
      bgColor={bgCol}
      fontColor={fontCol}
      underlineColor={underlineCol}
    />
  );
};

export default BlogHeaderModule;
