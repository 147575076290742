import React, { FC } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import PictureFluid from '../../old/components/Picture/PictureFluid';
import Picture from '../../old/components/Picture';

type Props = {
  className?: string;
  image: string | undefined | IGatsbyImageData;
  alt: string;
  loading?: 'lazy' | 'eager';
};

const StoryblokAssetImage: FC<Props> = ({ className, image, alt, loading }) => {
  return typeof image === 'object' ? (
    <PictureFluid
      className={className}
      image={image}
      alt={alt}
      allowOversize
      loading="eager"
    />
  ) : (
    <Picture
      lazyLoad={loading !== 'eager'}
      className={className}
      fallbackSrc={image || ''}
      alt={alt}
    />
  );
};

export default StoryblokAssetImage;
