import styled, { css } from 'styled-components';
import theme, { Breakpoints } from '../../../styles/themeVariables';
import {
  indentCol,
  makeCol,
  makeRow,
  updateColSpan,
} from '../../../styles/gridUtils';

const Row = styled.div`
  ${makeRow()};
`;

export type ColSpanProps = {
  [key in Breakpoints]: number;
};

function colSpanBreakpoints(props: Partial<ColSpanProps>) {
  // build col-span css style for each media breakpoint given...
  // turn object keys to an array then .map each key to a media breakpoint:
  return Object.keys(theme.breakpoints).map((bp) => {
    const key = bp as Breakpoints;
    const { [key]: propBp } = props;
    return propBp
      ? css`
          @media (min-width: ${theme.breakpoints[key]}) {
            ${updateColSpan(propBp)};
          }
        `
      : '';
  });
}

export type IndentProps = {
  [key in `${Breakpoints}Indent`]: number;
};

// build indentation style for each media breakpoint
function indentBreakpoints(props: Partial<IndentProps>) {
  // turn the keys of theme.breakpoints into an array using Object.keys,
  // then map over each key in that array:
  return Object.keys(theme.breakpoints).map((breakpointKey) => {
    // check for an indent prop that is prependended with a breakpoint key
    // eg mdIndent=, lgIndent= ect:
    const key = breakpointKey as Breakpoints;
    const { [`${key}Indent` as `${Breakpoints}Indent`]: indentProp } = props;

    // if such an indent prop is found, return the appropriate media queried style:
    return indentProp
      ? css`
          @media (min-width: ${theme.breakpoints[key]}) {
            ${indentCol(indentProp)};
          }
        `
      : '';
  });
}

export const Col = styled.div<
  { base?: number } & Partial<ColSpanProps> & Partial<IndentProps>
>`
  ${({ base }) => makeCol(base)};

  /* media breakpoints */
  ${(props) => colSpanBreakpoints(props)}

  /* indendation */
  ${(props) => indentBreakpoints(props)}
`;

export default Row;
