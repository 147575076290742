import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent } from '../../../types/blok.model';
import CallToActionLink, { CommonProps } from '.';

export type CTAButtonSchema = CommonProps;

const CTAButtonBlock: BlokComponent<CTAButtonSchema> = ({ blok }) => {
  const props = {
    ...blok,
  };

  return (
    <SbEditable content={blok}>
      <CallToActionLink {...props} />
    </SbEditable>
  );
};

export default CTAButtonBlock;
