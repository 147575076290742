import { useState, useEffect } from 'react';
import { PaginationProps } from './Pagination';

export const usePagination = ({
  currentPage,
  totalNumberOfPages,
  siblings = { left: 2, right: 1 },
}: PaginationProps) => {
  const [pages, setPages] = useState<number[]>([]);

  const numberOfPagesToShow = siblings.left + siblings.right + 1;
  const prevPage = currentPage - 1 === 0 ? 1 : currentPage - 1;
  const nextPage =
    currentPage === totalNumberOfPages ? totalNumberOfPages : currentPage + 1;

  useEffect(() => {
    if (totalNumberOfPages <= numberOfPagesToShow) {
      setPages(Array.from({ length: totalNumberOfPages }, (_, i) => i + 1));
    } else {
      let startPage = currentPage - siblings.left;
      let endPage = currentPage + siblings.right;

      if (startPage <= 1) {
        startPage = 1;
        endPage = Math.max(currentPage + siblings.right, numberOfPagesToShow);
      }

      if (endPage > totalNumberOfPages) {
        endPage = totalNumberOfPages;
        startPage = totalNumberOfPages - numberOfPagesToShow + 1;
      }

      setPages(
        Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
      );
    }
  }, [
    currentPage,
    numberOfPagesToShow,
    siblings.left,
    siblings.right,
    totalNumberOfPages,
  ]);

  return {
    prevPage,
    nextPage,
    pages,
  };
};
