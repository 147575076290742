import { css } from 'styled-components';
import theme from './themeVariables';
import { CSSReturnType } from '../util/type-helper';

export function makeRow(): CSSReturnType {
  return css`
    display: flex;
    margin-left: -${theme.remGutterWidth / 2}rem;
    margin-right: -${theme.remGutterWidth / 2}rem;
    flex-wrap: wrap;
  `;
}

export function spanCols(numCols: number): number {
  return 100 * (numCols / theme.gridSize);
}

export function makeCol(colSpan?: number): CSSReturnType {
  return colSpan
    ? css`
        max-width: ${spanCols(colSpan)}%;
        padding: 0 ${theme.remGutterWidth / 2}rem;
        flex: 0 1 ${spanCols(colSpan)}%;
        -ms-flex: 1;
      `
    : css`
        /* No colSpan given - fill remaining space */
        max-width: 100%;
        padding: 0 ${theme.remGutterWidth / 2}rem;
        flex: 1 1 0;
        -ms-flex: 1;
      `;
}

export function indentCol(numCols: number): CSSReturnType {
  return css`
    margin-left: ${spanCols(numCols)}%;
  `;
}

export function updateColSpan(numCols: number): CSSReturnType {
  return css`
    max-width: ${spanCols(numCols)}%;
    flex-basis: ${spanCols(numCols)}%;
  `;
}
