import styled from 'styled-components';
import { ResponsiveImage } from '../../components/ResponsiveImage';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';
import CallToActionLink from '../../components/CallToActionLink';

export const Logo = styled(ResponsiveImage)`
  grid-column: 1 / -1;
  height: ${spacing.space24};
  width: ${spacing.space24};

  img {
    width: auto;
  }

  @media ${breakpointUp.tablet} {
    padding-bottom: ${spacing.space8};
    grid-column: 1 / -1;
  }

  @media ${breakpointUp.desktop} {
    padding-bottom: ${spacing.space24};
    grid-column: 1 / -1;
  }
`;

export const Title = styled.h2`
  ${typeScale.mobile.body.xl};
  margin-bottom: ${spacing.space24};
  grid-column: 1 / -1;
  display: flex;
  column-gap: ${spacing.space16};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xl};
    margin-bottom: ${spacing.space16};
    padding-right: ${spacing.space24};
    grid-column: 1 / span 6;
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xl};
    margin-bottom: ${spacing.space24};
    grid-column: 1 / -1;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xl};
    margin-bottom: ${spacing.space24};
    grid-column: 1 / -1;
  }
`;

export const Subtitle = styled.h3`
  ${typeScale.mobile.body.xs};
  margin-bottom: ${spacing.space16};
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.md};
    margin-bottom: ${spacing.space24};
    padding-right: ${spacing.space24};
    grid-column: 1 / span 6;
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.sm};
    margin-bottom: ${spacing.space24};
    grid-column: 1 / -1;
  }
`;

export const Description = styled.div`
  ${typeScale.mobile.body.md};
  color: ${({ theme }) => theme.text500};
  margin-bottom: ${spacing.space24};
  grid-column: 1 / -1;
  grid-row: auto;

  ul {
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  li {
    display: flex;
    align-items: center;
    column-gap: ${spacing.space12};
  }

  @media ${breakpointUp.tablet} {
    li {
      column-gap: ${spacing.space18};
    }
  }

  li::marker {
    font-size: 0;
  }

  li::before {
    content: '';
    width: 1.25rem;
    min-width: 1.25rem;
    max-width: 1.25rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.border200};
    display: block;
  }

  li > p {
    margin: 0;
  }

  @media ${breakpointUp.tablet} {
    padding-right: ${spacing.space24};
    ${typeScale.tablet.body.lg};
    grid-column: -7 / span 6;
    grid-row: 1 / span 2;

    ul {
      row-gap: ${spacing.space10};
    }
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.lg};
    grid-column: 1 / -1;
    grid-row: auto;
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg};
    grid-column: 1 / -1;
    grid-row: auto;
  }
`;

export const Link = styled(CallToActionLink)`
  grid-column: 1 / -1;
  grid-row: auto;
  margin: auto ${spacing.space24};

  @media ${breakpointUp.tablet} {
    grid-row: 4;
    margin: auto ${spacing.space36};
    grid-column: 7 / -1;
  }

  @media ${breakpointUp.desktop} {
    grid-row: auto;
    grid-column: 1 / -1;
  }
`;

export const Card = styled(Grid)`
  @media ${breakpointUp.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
