import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { colors, darkTheme } from '../../../styles/themeVariables';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { AuthorType } from '../../../old/storyblok/authors-context';
import SharedHeaderModule from '../SharedHeaderModule';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import { rgba } from 'polished';

export type HeaderPillarProps = {
  tags: TagType[];
  categories: CategoryType[];
  title: string;
  bgImage: string | IGatsbyImageData;
  author?: AuthorType;
};

const fontCol = colors.white;
const underlineCol = rgba(255, 255, 255, 0.15);

const HeaderPillarModule: FC<HeaderPillarProps> = ({
  tags,
  categories,
  title,
  bgImage,
  author,
}) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <SharedHeaderModule
        tags={tags}
        categories={categories}
        title={title}
        bgImage={bgImage}
        author={author}
        layout={'left-aligned'}
        fontColor={fontCol}
        underlineColor={underlineCol}
      />
    </ThemeProvider>
  );
};

export default HeaderPillarModule;
