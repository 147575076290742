import styled, { css, DefaultTheme } from 'styled-components';
import { unstyleButton } from '../../../styles/miscUtils';
import { textSizes } from '../../../styles/typography';
import { ButtonSize, ButtonType } from './index';

const variantStyles = (
  theme: DefaultTheme,
  variant: ButtonType,
  bgColor?: string,
  textColor?: string,
  border?: string
) =>
  ({
    block: css`
      color: ${textColor || theme.white};
      background: ${bgColor || theme.blue};

      &:hover {
        background: ${bgColor || theme.blue};
      }

      &:disabled {
        background: ${bgColor || theme.blue};
      }
    `,
    ghost: css`
      color: ${textColor || theme.fg};
      border: 0.09375rem solid ${theme.ghostButton || border};

      &:disabled {
        background: transparent;
      }

      &:hover {
        background: rgba(240, 240, 240, 0.25);
      }
    `,
    icon: css`
      color: ${textColor || theme.black};
      background: ${theme.white};

      &:hover {
        background: ${theme.snow};
      }

      &:disabled {
        background: ${theme.white};
      }
    `,
  }[variant]);

export const sizesStyles = (theme: DefaultTheme, size: ButtonSize) =>
  ({
    sm: css`
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1rem;
    `,
    md: css`
      padding: 1rem 1.25rem 1rem 1.25rem;
      ${textSizes.xs};
    `,
    lg: css`
      padding: 1rem 1.25rem 1rem 1.25rem;
      ${textSizes.md};

      svg {
        min-width: 1.5rem;
        min-height: 1.5rem;
      }
    `,
  }[size]);

export const StyledButton = styled.button<{
  variant: ButtonType;
  btnsize: ButtonSize;
  href?: string;
  target?: string;
  bgColor?: string;
  textColor?: string;
  border?: string;
}>`
  ${unstyleButton()};
  ${({ theme, btnsize }) => sizesStyles(theme, btnsize)};
  ${({ theme, variant, bgColor, textColor }) =>
    variantStyles(theme, variant, bgColor, textColor)};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:disabled {
    cursor: not-allowed;
  }
`;
