import React from 'react';
import SbEditable from 'storyblok-react';
import Stats, { StatsComponentProps } from '.';
import {
  StoryblokColor,
  StoryblokTable,
  BlokComponent,
} from '../../../types/blok.model';
import { ColorOverrides, SharedProps } from './StatsBlock.styles';

export type StatsBlokSchema = SharedProps &
  ColorOverrides<StoryblokColor> & {
    stats: StoryblokTable;
  };

const StatsBlok: BlokComponent<StatsBlokSchema> = ({ blok }) => {
  const statsList = blok.stats.tbody?.map((row) => ({
    figure: row.body[1].value,
    description: row.body[0].value,
  }));

  const props = {
    ...blok,
    figureColor: blok.figureColor?.color,
    metricColor: blok.metricColor?.color,
    stats: statsList || [],
  } as StatsComponentProps;

  return (
    <SbEditable content={blok}>
      <Stats {...props} />
    </SbEditable>
  );
};

export default StatsBlok;
