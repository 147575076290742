import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { StyledButton } from './Button.styles';

export type ButtonType = 'block' | 'ghost' | 'icon';
export type ButtonSize = 'sm' | 'md' | 'lg';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  btnsize: ButtonSize;
  variant: ButtonType;
  dark?: boolean;
  dataTestId?: string;
  bgColor?: string;
  textColor?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  btnsize,
  variant,
  dark,
  dataTestId,
  bgColor,
  textColor,
  ...rest
}) => {
  return (
    <ThemeProvider theme={dark ? darkTheme : defaultTheme}>
      <StyledButton
        btnsize={btnsize}
        variant={variant}
        data-testid={dataTestId}
        bgColor={bgColor}
        textColor={textColor}
        {...rest}
      >
        {children}
      </StyledButton>
    </ThemeProvider>
  );
};

export default Button;
