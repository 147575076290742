import styled, { css } from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';

// TODO: COPY THIS INTO THE NEW/ FOLDER THEN turn into a react component with props for the content

const Blockquote = styled.blockquote`
  ${({ theme }) => css`
    margin: 0;
    text-align: center;
    background-color: var(--bg1, transparent);

    p {
      color: var(--fg1, ${theme.fg});
      ${textSizes.lg}

      &::before {
        content: '\\201C';
      }
      &::after {
        content: '\\201D';
      }
      &::before,
      &::after {
        color: var(--fg2, ${theme.greyFg});
      }
    }

    cite {
      color: var(--fg2, ${theme.greyFg});
      font-style: normal;
      ${textSizes.xs}
    }

    @media ${breakpointUp.sm} {
      p {
        ${textSizes.xl}
      }
    }

    @media ${breakpointUp.lg} {
      p {
        ${textSizes.xxl}
      }
    }
  `}
`;

export default Blockquote;
