import React, { FC, useCallback } from 'react';
import { Blockquote, QuoteText, Author } from './QuoteSlideBlock.styles';

export interface QuoteSlideProps {
  quote: string;
  author?: string;
  credentials?: string;
}

const QuoteSlide: FC<QuoteSlideProps> = ({ quote, author, credentials }) => {
  /**
   * concatenate author and credentials, handling comma logic.
   */
  const createCitation = useCallback((strings: (string | undefined)[]) => {
    return strings.filter((element) => element).join(', ');
  }, []);

  return (
    <Blockquote>
      <QuoteText>{quote}</QuoteText>
      <Author>{createCitation([author, credentials])}</Author>
    </Blockquote>
  );
};

export default QuoteSlide;
