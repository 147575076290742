import {
  WhitePaperFormDataType,
  useWhitePaperFormData,
} from '../../../old/storyblok/hubspotFormsData-context';
import { FormEvent, useState } from 'react';
import trackHubspot from '../../../old/components/Forms/trackHubspot';
import ReactGA from 'react-ga';
import * as z from 'zod';

type WhitePaperFormBlockHookProps = {
  formKey: string;
};

type FormState = {
  fullName: string;
  jobTitle: string;
  companyName: string;
  email: string;
};

const formSchema = z.object({
  fullName: z.string().min(1),
  jobTitle: z.string().min(1),
  companyName: z.string().min(1),
  email: z.string().email(),
});

export const useWhitePaperForm = ({
  formKey,
}: WhitePaperFormBlockHookProps) => {
  const hubposFormData = useWhitePaperFormData(
    formKey
  ) as WhitePaperFormDataType;

  const [formData, setFormData] = useState<FormState>({
    fullName: '',
    jobTitle: '',
    companyName: '',
    email: '',
  });

  const [requestStatus, setRequestStatus] = useState({
    isSuccess: false,
    isError: false,
  });

  const [formErrors, setFormErrors] = useState<FormState>({
    fullName: '',
    jobTitle: '',
    companyName: '',
    email: '',
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Handle saving validation
    const isValid = formSchema.safeParse(formData);

    if (!isValid.success) {
      const errors: FormState = {
        fullName: '',
        jobTitle: '',
        companyName: '',
        email: '',
      };

      if (!!isValid.error?.formErrors?.fieldErrors?.email) {
        errors.email = 'Please enter a valid email address';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.jobTitle) {
        errors.jobTitle = 'This field cannot be empty';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.fullName) {
        errors.fullName = 'This field cannot be empty';
      }

      if (!!isValid.error?.formErrors?.fieldErrors?.companyName) {
        errors.companyName = 'This field cannot be empty';
      }

      setFormErrors(errors);
      return;
    }

    if (isValid.success) {
      setFormErrors({
        fullName: '',
        jobTitle: '',
        companyName: '',
        email: '',
      });
      try {
        const query = new URLSearchParams({
          hubspotForm: hubposFormData.formKey,
          reportName: hubposFormData.reportName,
          template: hubposFormData.template || '',
          deckLink: hubposFormData.deckLink || '',
        });

        await fetch(`/api/white-paper-download?${query}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formData.email,
            companyName: formData.companyName,
            jobTitle: formData.jobTitle,
            name: formData.fullName,
          }),
        }).then((response) => {
          if (response.status >= 300) {
            throw new Error();
          }

          setRequestStatus({
            isSuccess: true,
            isError: false,
          });
        });

        trackHubspot(formData.email);
        ReactGA.event({
          category: 'Form',
          action: `${hubposFormData.name}`,
          transport: 'beacon',
        });
      } catch (error) {
        if (formData.email) {
          setFormErrors((prevState) => {
            return {
              ...prevState,
              email:
                "Looks like you've already received the deck. Try a different address.",
            };
          });
        }

        ReactGA.event({
          category: 'Form errors',
          action: `${hubposFormData.name}`,
          transport: 'beacon',
        });
      }
    }
  };

  return {
    requestStatus,
    formData,
    updateFormData: setFormData,
    handleSubmit,
    formErrors,
  };
};
