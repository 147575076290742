import React, { FC, useMemo } from 'react';
import Avatar from './Avatar';
import withRouter from '../../../util/withRouter';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import {
  BylineWrapper,
  StylDescription,
  StyledMultipleAuthorWrap,
  StyledSingleAuthorWrap,
  StylName,
  StylNameWrapper,
} from './AuthorByline.styles';
import { ThemeProvider } from 'styled-components';
import { AuthorType } from '../../../old/storyblok/authors-context';
import { WindowLocation } from '@gatsbyjs/reach-router';

export type AuthorBylineProps = {
  className?: string;
  location: WindowLocation;
  light?: boolean;
  data: AuthorType[] | AuthorType;
  size?: 'sm' | 'md';
};

export const Byline: FC<AuthorBylineProps> = ({
  data,
  className,
  light,
  size = 'md',
}) => {
  const content = useMemo(() => {
    if (!Array.isArray(data)) {
      return (
        <StyledSingleAuthorWrap>
          <Avatar author={data} />
          <StylNameWrapper>
            <StylName size="md">{data?.name}</StylName>
            {data?.description && (
              <StylDescription>{data.description}</StylDescription>
            )}
          </StylNameWrapper>
        </StyledSingleAuthorWrap>
      );
    }

    return (
      <StyledMultipleAuthorWrap>
        {data.map((author, index) => {
          return <Avatar key={index} author={author} />;
        })}

        <div>
          {data.map((author, index) => {
            if (index === 0) {
              return (
                <StylName key={index} size={size}>
                  {author.name}
                </StylName>
              );
            }
            return (
              <StylName key={index} size={size}>
                &nbsp; & {author.name}
              </StylName>
            );
          })}
        </div>
      </StyledMultipleAuthorWrap>
    );
  }, [data, size]);

  return (
    <ThemeProvider theme={light ? defaultTheme : darkTheme}>
      <BylineWrapper className={className}>{content}</BylineWrapper>
    </ThemeProvider>
  );
};

export default withRouter(Byline);
