import styled from 'styled-components';

export type Colors<T> = {
  bg1?: T;
  bg2?: T;
  fg1?: T;
  fg2?: T;
  fg3?: T;
};

const SectionWrapper = styled.div<Colors<string>>`
  --bg1: ${({ bg1 }) => bg1};
  --bg2: ${({ bg2 }) => bg2};
  --fg1: ${({ fg1 }) => fg1};
  --fg2: ${({ fg2 }) => fg2};
  --fg3: ${({ fg3 }) => fg3};

  color: var(--fg1);
  background-color: var(--bg1);
  width: 100vw;
  padding-top: 7rem;
  padding-bottom: 7rem;
`;

export default SectionWrapper;
