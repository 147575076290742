import React, { FC } from 'react';
import { StyledButton } from './Button.styles';
import { ButtonProps } from '.';
import styled from 'styled-components';

export type LinkButtonProps = ButtonProps & {
  href: string;
  target?: string;
  onClick?: React.MouseEventHandler;
  dataTestId?: string;
  bgColor?: string;
  textColor?: string;
  border?: string;
};

export const StyledLinkBtn = styled(StyledButton)`
  display: inline-block;
`;

const LinkButton: FC<LinkButtonProps> = ({
  href,
  target,
  onClick,
  btnsize,
  variant,
  children,
  dataTestId,
  bgColor,
  textColor,
  border,
}) => {
  return (
    <StyledLinkBtn
      as="a"
      href={href}
      target={target}
      onClick={onClick}
      btnsize={btnsize}
      variant={variant}
      children={children}
      data-testid={dataTestId}
      bgColor={bgColor}
      textColor={textColor}
      border={border}
    />
  );
};

export { LinkButton as default, LinkButton };
