import React, { useContext } from 'react';
import { StoryData } from 'gatsby-source-storyblok';

const StoryblokMetaContext = React.createContext<StoryData<unknown> | unknown>(
  undefined
);

export const StoryblokMetaProvider = StoryblokMetaContext.Provider;

export const useStoryblokMeta = (): StoryData<unknown> => {
  return useContext(StoryblokMetaContext) as StoryData<unknown>;
};
