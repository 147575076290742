import React from 'react';
import SbEditable from 'storyblok-react';
import { StoryblokColor, BlokComponent } from '../../../types/blok.model';
import Quote, { SharedProps, QuoteBlockComponentProps } from '.';
import { ColorOverrides } from './QuoteBlock.styles';

export type StatsBlokSchema = SharedProps & ColorOverrides<StoryblokColor>;

const QuoteBlockBlok: BlokComponent<StatsBlokSchema> = ({ blok }) => {
  const props = {
    ...blok,
    quoteColor: blok.quoteColor?.color,
    authorColor: blok.authorColor?.color,
    credentialsColor: blok.credentialsColor?.color,
    sidebarColor: blok.sidebarColor?.color,
  } as QuoteBlockComponentProps;

  return (
    <SbEditable content={blok}>
      <Quote {...props} />
    </SbEditable>
  );
};

export default QuoteBlockBlok;
