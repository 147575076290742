import React from 'react';
import { ReelContainer } from './Reel.styles';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ResponsiveImage } from '../ResponsiveImage';

export type ReelProps = {
  item: string | IGatsbyImageData;
  alt: string;
  title?: string;
  className?: string;
};

export const Reel = ({ item, alt, title, className }: ReelProps) => {
  return (
    <ReelContainer className={className}>
      <ResponsiveImage
        desktop={item}
        tablet={item}
        mobile={item}
        title={title}
        alt={alt}
      />
    </ReelContainer>
  );
};

export default Reel;
