import { css, FlattenSimpleInterpolation } from 'styled-components';
import { CSSReturnType } from '../util/type-helper';
import { breakpointUp } from './mediaUtils';

export function headerType(): CSSReturnType {
  return css`
    font-size: 3rem;
    letter-spacing: -0.04rem;
    line-height: 3.5rem;

    @media ${breakpointUp.sm} {
      line-height: 4rem;
    }

    @media ${breakpointUp.lg} {
      font-size: 5.75rem;
      letter-spacing: -0.09rem;
      line-height: 7.25rem;
    }
  `;
}

export function headingStyle(
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
): CSSReturnType {
  switch (level) {
    case 'h1':
      return css`
        font-size: 3rem;
        line-height: 4rem;

        @media ${breakpointUp.md} {
          font-size: 5rem;
          letter-spacing: -0.06rem;
          line-height: 6.5rem;
        }
      `;

    case 'h2':
      return css`
        font-size: 2.5rem;
        letter-spacing: -0.03rem;
        line-height: 3.5rem;

        @media ${breakpointUp.md} {
          font-size: 3.5rem;
          letter-spacing: -0.04rem;
          line-height: 4.5rem;
        }
      `;

    case 'h3':
      return css`
        font-size: 2rem;
        letter-spacing: -0.02rem;
        line-height: 2.75rem;

        @media ${breakpointUp.md} {
          font-size: 3rem;
          letter-spacing: -0.04rem;
          line-height: 4rem;
        }
      `;

    case 'h4':
      return css`
        font-size: 1.63rem;
        letter-spacing: -0.02rem;
        line-height: 2.5rem;

        @media ${breakpointUp.md} {
          font-size: 2rem;
          letter-spacing: -0.02rem;
          line-height: 3rem;
        }
      `;

    case 'h5':
      return css`
        font-size: 1.5rem;
        letter-spacing: -0.02rem;
        line-height: 2rem;

        @media ${breakpointUp.md} {
          font-size: 1.5rem;
          letter-spacing: -0.02rem;
          line-height: 2.5rem;
        }
      `;

    case 'h6':
      return css`
        font-size: 1.25rem;
        letter-spacing: -0.01rem;
        line-height: 2rem;
      `;

    default:
      return css`
        font-size: 2.5rem;
        letter-spacing: -0.03rem;
        line-height: 3.5rem;

        @media ${breakpointUp.md} {
          font-size: 3.5rem;
          letter-spacing: -0.04rem;
          line-height: 5rem;
        }
      `;
  }
}

export type TextSizes =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl';

export const textSizes: Record<TextSizes, FlattenSimpleInterpolation> = {
  xxs: css`
    font-size: 0.8125rem;
    line-height: 1.25rem;
  `,
  xs: css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  sm: css`
    font-size: 1.125rem;
    line-height: 1.75rem;
  `,
  md: css`
    font-size: 1.25rem;
    line-height: 1.75rem;
  `,
  lg: css`
    font-size: 1.5rem;
    line-height: 2.25rem;
  `,
  xl: css`
    font-size: 2rem;
    line-height: 2.5rem;
  `,
  xxl: css`
    font-size: 3rem;
    line-height: 3.5rem;
  `,
  xxxl: css`
    font-size: 3.5rem;
    line-height: 4rem;
  `,
  xxxxl: css`
    font-size: 4rem;
    line-height: 4.5rem;
  `,
};

export function resetHeadings(): CSSReturnType {
  return css`
    h1 {
      ${headingStyle('h1')};
    }

    h2 {
      ${headingStyle('h2')};
    }

    h3 {
      ${headingStyle('h3')};
    }

    h4 {
      ${headingStyle('h4')};
    }

    h5 {
      ${headingStyle('h5')};
    }

    h6 {
      ${headingStyle('h6')};
    }
  `;
}

export const textAlt = css`
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;
