import styled from 'styled-components';
import { textSizes } from '../../../styles/typography';

export const TextBlockWrapper = styled.div<{
  type: 'xs' | 'sm' | 'md' | 'lg';
  textColor?: string;
}>`
  color: ${({ textColor }) => textColor};

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  h3,
  h4,
  h5 {
    ${({ type }) => {
      switch (type) {
        case 'xs':
          return textSizes.sm;
        case 'sm':
          return textSizes.md;
        case 'md':
          return textSizes.lg;
        case 'lg':
          return textSizes.xl;
      }
    }}
  }

  ${({ type }) => textSizes[type]}
`;
