import React from 'react';
import SbEditable from 'storyblok-react';
import CardService, { CardServiceProps } from '.';
import {
  BlokComponent,
  StoryblokAsset,
  StoryblokLink,
} from '../../../types/blok.model';
import { useStoryblokImage } from '../../../old/storyblok/asset-context';
import StoryblokRichText, {
  RichTextDocument,
} from '../../components/RichText/StoryblokRichText';
import { ensureTrailingSlash } from '../../../util/ensureTranilingSlash';

export interface CardServiceBlokProps
  extends Omit<CardServiceProps, 'ctaLink'> {
  contentId: string;
  icon?: StoryblokAsset;
  description: RichTextDocument;
  ctaLink: StoryblokLink;
}

const CardServiceBlok: BlokComponent<CardServiceBlokProps> = ({
  blok,
  Dynamic,
}) => {
  const icon = useStoryblokImage(blok.icon?.filename ?? '');
  const link = ensureTrailingSlash(
    blok.ctaLink?.cached_url ?? blok.ctaLink?.url
  );
  const description = (
    <StoryblokRichText content={blok.description} Dynamic={Dynamic} />
  );

  return (
    <SbEditable content={blok}>
      <CardService
        {...blok}
        serviceIcon={icon}
        description={description}
        ctaLink={link}
      />
    </SbEditable>
  );
};

export default CardServiceBlok;
